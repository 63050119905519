enum ImgStatusEnum {
  // 队列满了,等待加入队列
  Waiting = -2,
  // 已经提交,等待回调
  WaitingBack = -1,
  // 任务正在执行
  Pending = 0,
  Success = 1,
  Fail = 2
}

enum ImgShowStateEnum {
  SHOW_LOADING = 0, // 显示Loading
  SHOW_PROGRESS = 2, // 显示进度条
  SHOW_FAIL = 3, // 显示加载失败或者违规
  SHOW_SUCCESS = 1 // 显示加载成功
}
/**图片处理类型 */
enum ImgProcessType {
  ImgRepair = "ImgRepair",
  ImgCutOutNormal = "ImgCutOutNormal",
  ImgCutOutFace = "ImgCutOutFace",
  ImgBeautify = "ImgBeautify",
  ImgFaceBecomesClear = "ImgFaceBecomesClear",
  ImgPhotoColoring = "ImgPhotoColoring",
  ImgCartoonAvatar = "ImgCartoonAvatar"
}

enum UserTypeEnum {
  USER = 1,
  API = 5
}

enum RouterNameEnum {
  ImgCleanUp = "ImgCleanUp",
  TextToAudio = "TextToAudio",
  ImgDetail = "ImgDetail",
  ImgRepair = "ImgRepair",
  ImgCutOutNormal = "ImgCutOutNormal",
  ImgCutOutFace = "ImgCutOutFace",
  ImgBeautify = "ImgBeautify",
  ImgFaceBecomesClear = "ImgFaceBecomesClear",
  ImgPhotoColoring = "ImgPhotoColoring",
  ImgCartoonAvatar = "ImgCartoonAvatar",
  PartnerPlan = "PartnerPlan",
  DigitHuman = "DigitHuman",
  AICategory = "AICategory",
  ImageSquare = "ImageSquare",
  ImageAI = "ImageAI",
  ChatAI = "ChatAI",
  Chat = "Chat",
  ChatAICategory = "ChatAI-Category",
  VideoTranslation = "VideoTranslation",
  AIPoster = "AIPoster",
  TextExtract = "TextExtract",
  AudioToText = "AudioToText",
  AudioClone = "AudioClone",
  InteractiveDigital = "InteractiveDigital",
  MenuDigitalAssets = "MenuDigitalAssets",
  CustomizedModel = "CustomizedModel",
  OutstandingCase = "OutstandingCase",
  IntelligenceTraining = "IntelligenceTraining",
  UserCenter = "UserCenter",
  AboutFutureAI = "AboutFutureAI",
  AboutChatAI = "AboutChatAI",
  TechSupport = "TechSupport",
  ImgUncropPhotos = "ImgUncropPhotos",
  ImgPartialRedraw = "ImgPartialRedraw",
  CreateWork = "CreateWork",
  AudioUpload = "AudioUpload",
  DigitTwins = "DigitTwins",
  Empty = "Empty",
  FrameCapcut = "FrameCapcut",
  Home = "Home",
  Agreement = "Agreement",
  WebsiteConfig = "WebsiteConfig",
  Login = "Login",
  PayH5 = "PayH5",
  News = "News",
  Welcome = "Welcome",
  MenuWorkManagement = "MenuWorkManagement",
  DigitalCertificate = "DigitalCertificate",
  DigitalWorkbench = "DigitalWorkbench",
  MenuMyCustom = "MenuMyCustom",
  MenuMyRights = "MenuMyRights",
  AccountManager = "AccountManager",
  DigitalOrder = "DigitalOrder",
  ScoreManage = "ScoreManage",
  ProductManagement = "ProductManagement",
  DigitalAcountManage = "DigitalAcountManage",
  AgentConfig = "AgentConfig",
  SystemConfig = "SystemConfig",
  NewsManager = "NewsManager",
  OnlinePayManage = "OnlinePayManage",
  DrawManage = "DrawManage",
  VideoTranslate = "VideoTranslate",
  PermissionManage = "PermissionManage"
}

enum CommonDialogActionEnum {
  UploadTrainingVideo = 1
}
//  图片显示状态控制所属范围
enum CompareType {
  // LESS_THAN = -1,
  // BIG_THAN = 1,
  SCOPE = 1,
  EQUAL = 0
}

enum VoiceTypeEnum {
  MIC = 1, // 微软
  ALI_PUB = 2, //
  ALI_PRI = 3,
  ELEVEN_LABS = 4, // eleven labs 克隆
  GUIJI_PRI = 5, // eleven labs 克隆
  GUIJI_PUB = 6, // eleven labs 克隆,
  FY_BASIC = 9, // 飞影基础
  FY_PRO = 20, // 飞影基础
  HS = 21 // 火山
}

/**
 * 合成类型
 */
enum MicOrderType {
  TEXT_TO_AUDIO = 1, // 文本转语音
  AUDIO_TO_TEXT = 2, // 语音转文本
  TEXT_TO_VODIO = 3 // 文本转视频
}

enum DirectionEnum {
  LEFT = 1,
  RIGHT = 2
}


enum DrawerDirectionEnum {
  LEFT = "ltr",
  RIGHT = "rtl"
}
enum SDSampler {
  DDIM = "DDIM",
  DDPM = "DDPM",
  K_DPMPP_2M = "K_DPMPP_2M",
  K_DPMPP_2S_ANCESTRAL = "K_DPMPP_2S_ANCESTRAL",
  K_DPM_2 = "K_DPM_2",
  K_DPM_2_ANCESTRAL = "K_DPM_2_ANCESTRAL",
  K_EULER = "K_EULER",
  K_EULER_ANCESTRAL = "K_EULER_ANCESTRAL",
  K_HEUN = "K_HEUN",
  K_LMS = "K_LMS"
}

enum QrType {
  LOGIN = "10",
  APPLY = "11"
}

enum BtnColor {
  PRIMARY = "primary",
  WARNING = "warning",
  DEFAULT = "default",
  INFO = "info",
  TEXT = "text",
  SUCCESS = "success",
  DANGER = "danger"
}
enum DrawerDataEnum {
  Upload = "Upload",
  Midjourney = "Midjourney",
  StableDiffusion = "StableDiffusion",
  PSImage = "PSImage",
  Bg = "Bg",
  Img = "Img"
}

enum Lang {
  LAN_CN = "zh",
  LAN_EN = "en",
  LAN_TW = "zh-tw"
}

enum OnLineServiceStatusEnum {
  OPEN = 1,
  CLOSE = 2
}

enum ReviewRecordType {
  OTHER = -1, // 其他邮件
  REVIEW = 1, // 审核记录
  FOLLOW_UP = 2, // 跟进记录
  FOLLOW_XB_UP = 3, // 小冰企业的跟新信息
  DISCOUND_CODE = 4 // 折扣码
}

enum OrientationEnum {
  // 纵向
  Vertical = 1,
  // 横向
  Horizontal = 2
}

enum ResourceDrawerTypeEnum {
  // 纵向
  Asset = 1,
  // 横向
  Bg = 2,
  Img = 3
}

//
enum GiftTypeEnum {
  NO_GIFT = -1, // 没有奖励
  SCORE = 2 // 赠送积分
}

enum LangTypeEnum {
  ZH = "zh",
  EN = "en",
  ZH_TW = "zh-tw"
}
enum NewsGroupEnum {
  NEWS_ALL = -1, // 首页banner
  NEWS_BANNER = 1, // 首页banner
  NEWS_GROUP_FIRST_PAGE = 5, // 弹出框广告内容
  ABOUT_ME = 6, // 关于我们
  NEWS_VIDEO_DEMO = 7, //demo视频
  DEMO_ADVERTISE = 8 //数字人商品文字说明
}

enum VerifyModel {
  PHONE = 1, // 首页banner
  EMALI = 2 // 首页banner
}

// // 用于判断新增的视频案例类别
// enum DemoGroupEnum {
//   DEMO_XBDIGIT = 2,
//   DEMO_AI_AUTOPLAY = 4,
//   DEMO_BUSSINESSCARD = 5,
//   DEMO_DIGIT_LIVE = 6,
//   DEMO_AUDIO = 7
// }

enum PublicDigitsTypeEnum {
  ALL = -1,
  GuiJi = 1
}
// 保留状态
enum CutoutTypeEnum {
  KEEP_BACKGROUND = 1, // 保留拍摄背景
  TRANSPARENT_BACKGROUND = 2, // 仅抠人像
  GREEN_SCREEN = 3 //（保留桌椅）
}

enum VideoTypeEnum {
  VOICE = 1,
  TEXT = 2
}
enum CloneTypeEnum {
  VOICE = 1,
  DIGITAL = 2
}

enum PayButtonTypeEnum { // 支付按钮的类型
  // 联系
  CONTACT = 1,
  // 购买
  BUY = 2,
  // 0元体验数字人
  ZERO_HUMAN = 3
}

enum PayBusinessTypeEnum {
  // 合并
  ALL = 4,
  // 充值积分
  BUY_SCORE = 2,
  // 推广官
  Dealer = 100,
  // 数字人
  DIGITAL_SHORT = 101, // 短视频数字人
  DIGITAL_LIVE = 102, // 直播数字人
  DIGITAL_INTERACTIVE = 103, // 交互数字人
  SOURCE_PACKAGE = 104, // 资源包(之前是数字声音)
  FAST_DIGITAL_SHORT = 106 // 极速数字人
}

// 用于判断新增的视频案例类别
enum DemoGroupEnum {
  DIGITAL_SHORT = 101, // 短视频数字人
  DIGITAL_LIVE = 102, // 直播数字人
  DIGITAL_INTERACTIVE = 103, // 交互数字人
  SOURCE_PACKAGE = 104, // 资源包(之前是数字声音)
  FAST_DIGITAL_SHORT = 106 // 极速定制
}

enum PlatformEnum {
  MOBILE = "mobile",
  PC = "pc"
}
enum EmitterTypeEnum {
  RefreshPayBusinessTypeDialog = "refreshPayBusinessTypeDialog",
  ClearUserInfo = "clearUserInfo",
  // 刷新数字人列表信息
  RefreshDigitalHumanList = "refreshDigitalHumanList",
  // 刷新克隆声音列表信息
  RefreshVoliceCloneList = "refreshVoliceCloneList",
  Goto404 = "goto404",
  ShowPCAndMobilePayDialog = "showPCAndMobilePayDialog",
  ShowLoadingDialog = "showLoadingDialog"
}
enum AgentRoleEnum {
  MAIN = 1, // 普通用户,主站用户,无任何推广返佣
  // AGENT = 2, // 代理商
  DEALER = 3 // 经销商
}
// 经销商级别
enum DealerTypeEnum {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6
}

enum DialogAddOrEditEnum {
  Create = 0,
  Edit = 1
}
enum PayChannelEnum {
  WX = 2,
  ALIPAY = 7,
  PAYPAL = 8,
  CREDIT_CARD = 9
}

// 任务状态
enum GlobalStateEnum {
  // 队列满了,等待加入队列
  Waiting = -2,
  // 已经提交,等待回调
  WaitingBack = -1,
  // 任务正在执行
  Pending = 0,
  Success = 1,
  Fail = 2
}
// 任务状态
enum FileDownloadFail {
  Fail = -1
}
enum LanguageVideoEnum {
  ZH = "zh", // 中文
  EN = "en", // 英文
  JA = "ja", // 日语
  KO = "ko", // 韩语
  DE = "de", // 德语
  FR = "fr", // 法语
  RU = "ru", // 俄语
  IT = "it", // 意大利语
  ES = "es", // 西班牙语
  AR = "ar", // 阿拉伯语
  HI = "hi", // 印地语
  PT = "pt", // 葡萄牙语
  ID = "id", // 印度尼西亚语
  NL = "nl", // 荷兰语
  TR = "tr", // 土耳其语
  PL = "pl", // 波兰语
  SV = "sv", // 瑞典语
  FIL = "fil", // 菲律宾语
  MS = "ms", // 马来语
  RO = "ro", // 罗马尼亚语
  UK = "uk", // 乌克兰语
  EL = "el", // 希腊语
  CS = "cs", // 捷克语
  DA = "da", // 丹麦语
  BG = "bg", // 保加利亚语
  HR = "hr", // 克罗地亚语
  TA = "ta" // 泰米尔语
}
enum RoleEnum {
  SuperAdmin = "superAdmin",
  Admin = "admin",
  Partner = "partner",
  Staff = "staff",
  User = "user"
}
// 过滤模特 二进制位
enum TrainingFlagEnum {
  MAN_OR_WOMAN = 1,
  BG_OR_NO_BG = 2,
  ASIA_OR_AMERICA = 3
}
enum TrainingFlagStatusEnum {
  ALL = -1,
  MALE = 1,
  FEMALE = 2,
  BG = 4,
  NOBG = 8,
  ASIA = 16,
  AMERICA = 32
}
enum ModelFlagStatusEnum {
  ALL = 0,
  MAN = 1,
  WOMAN = 2,
  BG = 3,
  NO_BG = 4,
  ASIA = 5,
  AMERICA = 6
}
enum LoginPageEum {
  login = 0, //邮箱登陆
  verifyCode = 1, //手机验证码登陆
  qrCode = 2, //微信二维码登陆
  register = 3, //注册
  forget = 4, //忘记密码
  wxPhoneLogin = 5 //手机微信密码
}

enum TrainingCutoutType {
  KEEP_BACKGROUND = 1,
  TRANSPARENT_BACKGROUND = 2,
  GREEN_SCREEN = 3
}

// 声音克隆枚举
enum VoliceTypeEnum {
  // 微软 小兵数字人
  MIC = 1,
  ALI_PUB = 2,
  ALI_PRI = 3,
  // eleven labs 克隆
  ELEEVEN_LABS = 4
}

// 用户上传视频授权书枚举
enum DigitStatusResult {
  ALL = -3, // 所有
  ADD_INFO = -2, // 补充基本信息
  ADD_VIDEO_AUTH = -1, // 补充视频和认证信息
  PENDING = 0, // 等待审核
  SUCCESS = 1, // 订单完成
  PENDING_UOLOAD = 2, // 审核通过,等待上传
  UPLOAD_SUCCESS = 3, // 上传成功,等待确认
  WAITING_CREATE = 4 // 等待创建
}

enum statusEnum {
  PENDING = -1, // 回退到上一个状态
  SUCCESS = 1, // 审核通过
  FAIL = 2 // 审核失败
}

enum IsThirdServiceEnum {
  ThirdService = 2, // 三方服务
  PLATFORM = 1, //平台
  ALL = 3 // 所有
}

enum AgentRateMainPartTypeEnum {
  DISCOUNT_RATE = 1, // 所有者折扣
  DISCOUNT_USER_RATE = 2, // 用户折扣
  MAIN_AGENT_RATE = 3, // 主站返佣
  PARTNER_RATE = 4 // 合伙人返佣
}
//返佣形式
enum AgentRateTypeEnum {
  RATE_REWARD = 1, // 比例返佣
  AMOUNT_REWARD = 2, // 金额返佣
  AMOUNT_AND_RATE_REWARD = 3 // 金额返佣
}
//折扣码申请表折扣码状态枚举
enum DiscountStatusEnum {
  DISCOUNT_AWAIT = 0, //待处理
  DISCOUNT_CANCEL = 2, //拒绝
  DISCOUNT_ACTIVE = 1, //已激活
  DISCOUNT_ALL = -1 //所有
}
//个人中心提交声音还是提交视频emun
enum GoodCloneTypeEnum {
  AUDIO_VIDEO = 1, //数字人
  AUDIO_VOICE = 2, //声音
  NO_VIDEO_AND_AUDIO = 3 //视频和音频都不用上传
}

//首页优秀案例的视频类别
enum VideoListTypeEnum {
  VIDEO_XBHUMAN = 2, //小冰数字人
  VIDEO_BUSINESSCARD = 5, //互动数字人
  VIDEO_LIVEHUMAN = 6, //直播数字人
  VIDEO_XBSOUND = 7 //声音定制
}

enum OperateEnum {
  UPDATE = 1, // 更新
  CREATE = 2, // 创建
  SELECT = 3, // 选择
  LOOK = 4 // 查看
}

enum StatusTypeEnum {
  STATUS_USE = 1, //绑定
  STATUS_NO = 0 //不绑定
}
enum CategoryEnum {
  UNIVERSAL_VERIFICATION_CODE = "universal_verification_code", // 万能验证码
  REQUIRED_INVITE_CODE = "required_invite_code", // 邀请码要求
  VERIFICATION_CODE_EXPIRATION_TIME = "verification_code_expiration_time", // 验证码过期时间
  CREATE_VIDEO_TEXT_MIN = "create_video_text_min", // 合成视频文字最低长度
  CREATE_VIDEO_TEXT_MAX = "create_video_text_max", // 合成视频文字最高长度
  BACKGROUND_GROUP = "background_group", // 背景图
  GPT_INIT_COUNT = "gpt_init_count", // 未付费用户初始次数
  OPEN_INVITE = "open_invite", // 开通邀请机制
  INVITE_CHATGPT = "invite_chatgpt", // 开通邀请机制
  INVITE_CHATGPT_REGISTER = "invite_chatgpt_register", // 注册奖励
  IMG_GROUP = "img_group", // 系统图片
  VIDEO_GROUP = "video_group", // 系统视频
  WX_GZ = "wx_gz", // 公众号AppId和AppSecret
  WX_PAY = "wx_pay" // 公众号AppId和AppSecret
}
enum FromType {
  USER = 0,
  SYSTEM = 1
}
//权限管理Enum
enum PermissionsEnum {
  MENU_USER = "menuUser", // -用户列表
  MENU_PUBLIC_DIGITAL = "menuPublicDigital", // -公模数字人
  MENU_TRANSLATE_VIDEO_ORDER = "menuTranslateVideoOrder", // 视频翻译的列表
  MENU_PUBLIC_DIGITAL_CREATE_VIDEO_ORDER = "menuPublicDigitalCreateVideoOrder", // -公模数字人创建的数字人列表
  MENU_LIST_MICROSOFT_ORDER = "menuListMicrosoftOrder", // 声音合成列表
  MENU_LIST_VOICE_CLONE = "menuListVoiceClone", // 获取数字人声音
  MENU_DIGITAL_ORDER = "menuDigitalOrder", // -数字人订单
  MENU_COMPANY_DIGITAL_ORDER = "menuCompanyDigitalOrder", // -企业数字人订单
  MENU_SCORE = "menuScore", // -积分列表
  MENU_SCORE_DETAIL = "menuScoreDetail", // -积分详情
  MENU_SCORE_RECHARGE = "menuScoreDetailRecharge", // -充值列表详情
  MENU_PRODECT_DETAIL = "menuProdectDetail", // -产品详情
  MENU_AGENT_SCORE = "menuAgentScore", //后台经销商积分列表
  MENU_AGENT_SCORE_DETAIL = "menuAgentScoreDetail", //后台经销商积分消费记录
  MENU_PARTNE_RREBATE = "menuPartnerRebate", // 合伙人返佣配置
  MENUM_MJ_DRAW = "menuMjDraw", //  -绘画mj
  MENUM_SD_DRAW = "menuSdDraw", //  -绘画sd
  MENU_PAY = "menuPay", // -支付列表
  MENU_SYS = "menuSys", // -系统管理
  MENU_SYS_SOURCE = "menuSysSource", // -系统素材
  MENU_USER_SOURCE = "menuUserSource", // -用户素材
  MENU_NEWS = "menuNews", // -新闻列表
  MENU_DEMO_NEWS = "menuDemoNews", // -demo新闻
  MENU_DISCOUNT = "menuDiscount", // -折扣码菜单
  MENU_AGENT = "menuAgent", //-经销商菜单(无数据)
  MENU_AGENT_LIST = "menuAgentList", //-经销商菜单
  MENU_GET_WX_MENU = "menuGetWxMenu", //获取微信菜单
  MENU_GET_WX_QA = "menuGetWxQa", //获取微信问答,
  MENU_LIST_DIGITAL_ACCOUNT = "menuListDigitalAccount", // 获取数字人账户集合
  MENU_LIST_DIGITAL_ACCOUNT_DETAIL = "menuListDigitalAccountDetail", // 获取数字人账户详情集合
  MENU_LIST_ROUTER = "menuListRouter", // 路由列表
  BTN_REBACK_MICROSOFT_ORDER = "btnRebackMicrosoftOrder", // 重新制作
  BTN_ROUTER_CREATE = "btnRouteCreate", //创建路由
  BTN_ROUTER_UPDATE = "btnRouteUpdate", //更新路由
  BTN_ROUTER_UP_OR_DOWN = "btnRouterUpOrDown", //向上向下路由
  BTN_EDIT_PUBLIC_DIGITAL = "btnEditPublicDigital", //-编辑共有模特
  BTN_UPDATE_ORDER_VIDEO = "btnUpdateOrderVideo", //-更新共有模特创建的视频状态-
  BTN_RETRY_ORDER_VIDEO = "btnRetryOrderVideo", //视频重试
  BTN_UPDATE_ORDER_VIDEO_INFO = "btnUpdateOrderVideoInfo", //更新视频状态
  BTN_UPDATE_DIGITAL_ORDER = "btnUpdateDigitalOrder", //-更新数字人订单状态
  BTN_UPDATE_SYS_PUB_DIGITAL = "btnUpdateSysPubDigital", //-更新系统共有模特-
  BTN_HS_VOICE_RETRY = "btnHsVoiceRetry", //后台声音重新训练
  BTN_ITEM_TRANSLATE_VIDEO_INFO = "btnItemTranslateVideoInfo", // 更新翻译视频列表信息
  BTN_HS_VOICE_DESTORY = "btnHsVoiceDestory", //后台回收声音
  BTN_HS_REFRESH = "btnHsRefresh", //刷新声音
  BTN_SEND_EMAIL = "btnSendEmail", //-发送邮件
  BTN_ADD_ORDER_FOLLOW_UP = "btnAddOrderFollowUp", //-添加订单跟进或审核记录-
  BTN_SEARCH_IS_ACTIVITY = "btnSearchIsActivity", //-查看是否激活
  BTN_EDIT_COMPANY_DIGITAL_STATE = "btnEditCompanyDigitalState", // -编辑企业数字人开通状态-
  BTN_ADD_XB_DIGITAL = "btnAddXbDigital", //-增加小冰数字人
  BTN_ADD_PLANT_DIGITAL = "btnAddPlantDigital", //-增加平台数字人
  BTN_OPEN_CHAT_AI = "btnOpenChatAi", //-开通chatAI
  BTN_OPERATE_SCORE = "btnOperateScore", //-操作积分,增加或者减少
  BTN_EDIT_SCORE_RECHARGE = "btnEditScoreRecharge", //-会员充值编辑按钮
  BTN_CREAT_SCORE_RECHARGE = "btnCreateScoreRecharge", //-会员充值编创建按钮
  BTN_GIFT_SEND_SCORE = "btnGiftSendScore", // 积分赠送权限
  BTN_UPDATA_OR_UPDATE_SYS_CONFIG = "btnUpdataOrUpdateSysConfig", //-编辑或者新增系统配置-
  BTN_ADD_SOURCE = "btnAddSource", //-增加资源
  BTN_DEL_SOURCE = "btnDelSource", //-删除资源
  BTN_ADD_OR_UPDATE_NEWS = "btnAddOrUpdateNews", //-增加或者编辑新闻-
  BTN_UPDATE_ALL_NEWS_DEMO = "btnUpdateAllNewsDemo", //-更新新闻到所有-
  BTN_UPDATE_ALL_SCOR_ERECHARGE = "btnUpdateAllScoreRecharge", //更新充值信息到所有经销商
  BTN_AGENT_CREATE_OR_UPDATE = "btnAgentCreateOrUpdate", //-创建或者编辑经销商-
  BTN_AGENT_WEB_CONFIG = "btnAgentWebConfig", //-经销商网站配置
  BTN_EDIT_AGENT_STATE = "btnEditAgentState", //-同意或者拒绝经销商-
  BTN_EDIT_DISCOUNT_CODE = "btnEditDiscountCode", // 编辑用户折扣码
  BTN_EDIT_AGENT_OR_DISCOUNT_RATE = "btnEditAgentOrDiscountRate", //-更新经销商折扣码比例-
  BTN_ADD_DISCOUNT = "btnAddDiscount", //-新增折扣码-
  NO_PERMISSIONS = "noPermissions", //-只要是超级管理员都可以访问
  BTN_CHANGE_FOOTER_RECORD = "btnChangeFooterRecord", //修改网站底部备案
  BTN_UPDATE_ALL_AGENT_CONFIG = "btnUpdateAllAgentConfig", //更新代理商配置到所有
  BTN_AGENT_OPERATE_SCORE = "btnAgentOperateScore", //后台操作积分-
  BTN_AGENT_CHANGE_SCORE = "btnAgentChangeScore", //后台操作积分兑换
  BTN_UPDATE_PRODECT_DETAIL = "btnUpdateProdectDetail", // -产品详情
  BTN_CREATE_PRODECT_DETAIL = "btnCreateProdectDetail", // -产品详情

  BTN_CREATE_WX_QA = "btnCreateWxQa", //创建微信文案
  BTN_DELETE_WX_QA = "btnDeleteWxQa", //删除微信文案
  BTN_UPDATE_WX_QA = "btnUpdateWxQa", //更新微信文案
  BTN_UPLOAD_WX_IMG = "btnUploadWxImg", //创建上传微信图片资源
  BTN_GET_WX_IMG = "btnGetWxImg", //获取微信图片资源
  BTN_CREATE_WX_MENU = "btnCreateWxMenu", //创建微信menu
  BTN_DELETE_WX_MENU = "btnDeleteWxMenu", //删除微信菜单

  BTN_OPERATE_DIGITAL_ADD_ACCOUNT = "btnOperateAddDigitalAccount", //增加数字人飞影账户
  BTN_OPERATE_DIGITAL_SUB_ACCOUNT = "btnOperateSubDigitalAccount" //扣减数字人飞影账户
}
// 判断这个权限是菜单权限还是按钮权限
enum PermissionsTypeEnum {
  MENU = 1,
  BUTTON = 2
}
//主页面排序
enum MainRouterEnum {
  AI_TOOL = 1, // ai工具  1
  INTELLIGENCETRAINING = MainRouterEnum.AI_TOOL + 1, // 训练营2
  DIGITAL_HUMAN = MainRouterEnum.INTELLIGENCETRAINING + 1, // 数字人购买3
  INTERACTIVE_DIGITALS = MainRouterEnum.DIGITAL_HUMAN + 1, // 交互数字人4
  PARTNER_PLAN = MainRouterEnum.INTERACTIVE_DIGITALS + 1, // 合伙人计划5
  CUSTOMIZE_DMODEL = MainRouterEnum.PARTNER_PLAN + 1, // 自定义模型6
  OUT_STANDING_CASE = MainRouterEnum.CUSTOMIZE_DMODEL + 1, // 优秀案例7
  HUMAN_OPERATION = MainRouterEnum.OUT_STANDING_CASE + 1 // 数字人操作8
}

// 支持的支付通道
enum PayChannel {
  WX = 1, // 微信
  ALIPAY = 2, // 支付宝
  PAYPAL = 3 // paypal
}

// 支持的支付通道
enum PayChannelStr {
  WX = "wxpay", // 微信
  ALIPAY = "alipay", // 支付宝
  PAYPAL = "paypal" // paypal
}

enum WxCallbackType {
  JUMP = 1, // 跳转
  ALERY_JUMP = 2 // 2 已经跳转过了
}

// 首页小bannner
enum SmallBannerEnumOptionsEnum {
  AI_CERTIFICATION = 0, // 人工智能实训
  INTERACTIVE_DIGITAL_PERSON = 1, // 交互式数字人
  DIGITAL_PERSON_LIVE = 2, // 数字人直播
  DIGITAL_PERSON_CUSTOMIZATION = 3, // 数字人定制
  PARTNER_PROGRAM = 4, // 合伙人计划
  AI_PAINTING = 5, // AI 作画
  EXCELLENT_CASE = 6, // 优秀案例
  CUSTOMIZED_MODEL = 7, // 定制化模型
  DIGITAL_WORKBENCH = 8 //工作台
}
// 首页大table排序
enum SwiperSortingOptionsEnum {
  MIIT_AI_OPERATION_CERT = 0, // 工信部人工智能运营师认证
  AI_OPERATOR_GROUP = 1, // 人工智能运营师适用人群
  SHORT_VIDEO_MARKETING = 2, // 短视频营销时代
  INTERACTIVE_DIGITAL_PERSON = 3 // 交互式数字人
}
// 支付货币类型
enum CurrencysEnum {
  USD = "USD", // 美元
  CNY = "CNY", // 人民币
  TWD = "TWD" // 新台币
}
//公众号管理回复类型的枚举
enum PublicEnum {
  TEXT = 1, // 文本
  IMG = 2, // 图文
  URL = 3 // 超链接
}
//用于微信菜单的配置
enum WxMenuTypeEnum {
  VIEW = "view", // 网址
  MEDIA_ID = "media_id" // 下发图片
}
enum WxXmlTypeEnum {
  TEXT = "text", // 文字
  IMG = "image" // 图片
}

enum BrandEnum {
  ALL = 4, // 所有
  MINE = 0, // 默认,自己
  GUI_JI = 1, // 硅基
  XIAO_BING = 2, // 小冰
  TENG_XUN = 3, // 腾讯
  PLANT = 5 // 平台数字人
}

enum FirstLoginEnum {
  EMAIL = 0, //邮箱登陆
  PHONE = 1, //手机验证码登陆
  WX = 2 //微信登陆
}
enum userCenterTabEnum {
  DIGITAL_HUMAN = "person",
  VOLICE = "volice"
}
enum certificateRegistEnum {
  SPOKEN = 1, // 口述
  MUSIC = 2, // 音乐
  DRAMA = 3, // 戏剧
  QUYI = 4, // 曲艺
  DANCE = 5, // 舞蹈
  ACROBATICS = 6, // 杂技
  ART = 7, // 美术
  ARCHITECTURE = 8, // 建筑
  PHOTOGRAPHY = 9, // 摄影
  FILM = 10, // 电影
  FILM_LIKE_CREATION = 11, // 类似摄制电影方法创作作品
  ENGINEERING_DESIGN = 12, // 工程设计图、产品设计图
  MAPS = 13, // 地图、示意图
  MODEL = 14, // 模型
  OTHER = 15 // 其他作品
}
enum audioModelEnum {
  BASIC = "basic",
  HIGH = "high",
  DIGITAL = "digital"
}
enum DialogTypeEnum {
  add = "add",
  sub = "sub"
}

enum UniLockEnum {
  NEWS_LOCK = "newsLock",
  DELETE = "delete" //删除锁
}

enum CountryLanguageCodeEnum {
  zhCN = "zh-CN", // 中国简体中文
  zhHK = "zh-HK", // 中文繁体
  enUS = "en-US", // 英语
  jaJP = "ja-JP", // 日本语
  esES = "es-ES", // 西班牙语
  idID = "id-ID", // 印尼语
  ptPT = "pt-PT", // 葡萄牙语
  ruRU = "ru-RU", // 俄语
  thTH = "th-TH", // 泰语
  deCH = "de-CH", // 瑞士德语
  koKR = "ko-KR", // 韩语
  frFR = "fr-FR" // 法语
}
enum TextActionTag {
  TEXT_EXTRACT = "text_extract",
  PLAY = "play",
  SPEED = "speed",
  STOP = "stop"
}
enum VoicesEnum {
  MY_VOICES = "my",
  SYS_VOICES = "sys"
}
//  全部 1 男 ,2  女
enum SexEnum {
  ALL = -1,
  MALE = 1,
  FEMALE = 2
}
//  PinnedFixEnum存储临时盯住数据
enum PinnedFixKeyEnum {
  VOICE = "voice",
  VIDEO = "video"
}

enum LocalStorageKeyEnum {
  PINNED_FIX_MAP = "pinnedFixMap", // 存储声音fix数据,
  AGENT_CHOOSE_MODEL = "agentChooseModel", //存储选择的模型
  CHAT_CHOOSE_MODEL = "chatChooseModel", //存储选择的模型
  CHAT_AGENT_TYPE = "chatAgentType", //当前是chat还是agent
  ROUTER_KEY = "router_key" //ai工具导航栏
}

enum SkipRandomEnum {
  RANDOM = 0,
  CLONE_RANDOM = 1
}
enum CreateModeEnum {
  LITE = 0, //  基础模式
  PRO = 1 // 专业模式
}
enum ChatModelEnum {
  BAIDU_WENXIN = "ERNIE-4.0-8K", // 文心一言
  ALI_QWEN = "qwen-turbo", // 通义千问
  XUNFEI_SPARK = "SparkDesk-v3.1", //讯飞星火
  CHATGLM_PRO = "glm-3-turbo", // chat ai
  //BAI_CHUAN = "Baichuan2-Turbo-192k", // 百川
  HUNYUAN = "hunyuan-pro", // 腾讯的模型
  GPT360 = "360gpt-pro", // 360
  COZE = "coze" // 360
}

enum AiTypeModelEnum {
  CHAT = "chat",
  AGENT = "agent"
}
/**上升或者下降排序,通用 */
enum RankTypeEnum {
  UP = 1,
  DOWN = 2
}
/**后端路由/前端路由 */
enum RouterTypeEnum {
  ADMIN = 1, // 后端路由
  USER = 2 // 前端路由
}
enum MenuVisibleTypeEnum {
  MENU_VISIBLE = 1, // 有菜单可见
  MENU_INVISIBLE = 2 // 无菜单页面
}
enum submissionTypeEnum {
  VIDEO = 1,
  GIF = 2
}
enum CommonDialogTypeEnum {
  RECHARGE_TITLE = 1, //
  RECHARGE_SUB_TITLE = 2, //
  RECHARGE_DESC = 3 //
}

enum TranslateTypeEnum {
  ROUTER_TITLE = 1, //
  ROUTER_DESC = 2, //
  RECHARGE_TITLE = 3, //
  RECHARGE_SUB_TITLE = 4, //
  RECHARGE_DESC = 5, //
  SLOGAN = 6 //
}

enum isFirstParentIdEnum {
  FIRST_PARENTID = "firstParentId",
  NOT_FIRST_PARENTID = "notFirstParentId"
}

enum withdrawalTypeEnum {
  //支付宝方式提现
  ALIPAY = 1,
  // 银行卡方式提现
  CARD = 2
}
enum TransactionDirectionEnum {
  INCOME = 1, // 入账
  EXPENSE = 2 // 出账
}
export {
  LanguageVideoEnum,
  TransactionDirectionEnum,
  CreateModeEnum,
  withdrawalTypeEnum,
  isFirstParentIdEnum,
  CommonDialogTypeEnum,
  RouterTypeEnum,
  MenuVisibleTypeEnum,
  submissionTypeEnum,
  RankTypeEnum,
  AiTypeModelEnum,
  ChatModelEnum,
  LocalStorageKeyEnum,
  SkipRandomEnum,
  CountryLanguageCodeEnum,
  VoicesEnum,
  SexEnum,
  TextActionTag,
  FromType,
  DialogTypeEnum,
  audioModelEnum,
  certificateRegistEnum,
  userCenterTabEnum,
  FirstLoginEnum,
  CurrencysEnum,
  SmallBannerEnumOptionsEnum,
  SwiperSortingOptionsEnum,
  DemoGroupEnum,
  RouterNameEnum,
  ImgProcessType,
  ImgStatusEnum,
  ImgShowStateEnum,
  DirectionEnum,
  DrawerDataEnum,
  VideoTypeEnum,
  DrawerDirectionEnum,
  OrientationEnum,
  SDSampler,
  BtnColor,
  ResourceDrawerTypeEnum,
  PublicDigitsTypeEnum,
  CutoutTypeEnum,
  PlatformEnum,
  PayBusinessTypeEnum,
  EmitterTypeEnum,
  AgentRoleEnum,
  DialogAddOrEditEnum,
  DealerTypeEnum,
  CompareType,
  PayChannelEnum,
  GlobalStateEnum,
  RoleEnum,
  TrainingFlagEnum,
  TrainingFlagStatusEnum,
  ModelFlagStatusEnum,
  LoginPageEum,
  TrainingCutoutType,
  VoliceTypeEnum,
  DigitStatusResult,
  PayButtonTypeEnum,
  Lang,
  CommonDialogActionEnum,
  AgentRateMainPartTypeEnum,
  AgentRateTypeEnum,
  DiscountStatusEnum,
  GoodCloneTypeEnum,
  TranslateTypeEnum,
  OnLineServiceStatusEnum,
  NewsGroupEnum,
  MicOrderType,
  ReviewRecordType,
  IsThirdServiceEnum,
  VideoListTypeEnum,
  StatusTypeEnum,
  GiftTypeEnum,
  LangTypeEnum,
  OperateEnum,
  CategoryEnum,
  PermissionsEnum,
  PermissionsTypeEnum,
  MainRouterEnum,
  PayChannel,
  QrType,
  VerifyModel,
  FileDownloadFail,
  BrandEnum,
  statusEnum,
  WxCallbackType,
  UniLockEnum,
  PinnedFixKeyEnum,
  PublicEnum,
  WxMenuTypeEnum,
  WxXmlTypeEnum,
  CloneTypeEnum,
  VoiceTypeEnum,
  PayChannelStr,
  UserTypeEnum
};

import { defineStore } from "pinia";
import { store } from "@/store";
import { DigitHumanAccount, ScoreType, userType } from "./types";
import { routerArrays } from "@/layout/types";
import { router } from "@/router";
import { storageLocal } from "@pureadmin/utils";
import { userApi, userRolePermissionsType } from "@/api";
import { UserResult } from "@/api";
import { useMultiTagsStoreHook } from "@/store/modules/multiTags";
import { type DataInfo, setToken, removeToken, sessionKey } from "@/utils";
import { Code, UserTypeEnum } from "@/config";
import { CommonUtils } from "@/utils/commonUtils";
import { useSystemConfigStoreHook } from "./systemConfig";

export const useUserStore = defineStore({
  id: "pure-user",
  state: (): userType => ({
    // 用户id
    id: CommonUtils.getUserInfo()?.id ?? 0,
    // 头像
    avatar: CommonUtils.getUserInfo()?.avatar ?? "",
    // 昵称
    nickname: CommonUtils.getUserInfo()?.nickname ?? null,
    extra: CommonUtils.getUserInfo()?.extra ?? "",
    // 邀请码
    inviteCode: CommonUtils.getUserInfo()?.inviteCode ?? "",
    // 用户名
    username: CommonUtils.getUserInfo()?.username ?? null,
    // 页面级别权限
    roles: CommonUtils.getUserInfo()?.roles ?? [],
    role: CommonUtils.getUserInfo()?.roles[0] ?? null,
    type: CommonUtils.getUserInfo()?.type ?? 1,
    // 前端生成的验证码（按实际需求替换）
    verifyCode: "",
    // 判断登录页面显示哪个组件（0：登录（默认）、1：验证码登录、2：二维码登录、3：注册、4：忘记密码）
    currentPage: useSystemConfigStoreHook().config.sysConfig.firstLoginType,
    // 用户积分
    scoreDetail: CommonUtils.getUserInfo()?.scoreDetail ?? {
      totalScore: 0,
      freezeScore: 0,
      score: 0
    },
    isAgent: CommonUtils.getUserInfo()?.isAgent ?? false,
    digitHumanAccount: {
      times: 0,
      totalTimes: 0,
      models: 0,
      totalModels: 0
    },
    agentPermissions: CommonUtils.getUserInfo()?.agentPermissions ?? 0,
    // 姓名
    realName: CommonUtils.getUserInfo()?.realName ?? null,
    payAccount: CommonUtils.getUserInfo()?.payAccount ?? null,
    // 上级用户名
    parentUsername: CommonUtils.getUserInfo()?.parentUsername ?? null,
    // 代理商ID
    agentId: CommonUtils.getUserInfo()?.agentId ?? -1,
    changeAgentInfo: CommonUtils.getUserInfo()?.changeAgentInfo ?? false,
    // 用户的菜单权限和按钮权限
    rolePermissions: CommonUtils.getUserInfo()?.rolePermissions ?? [],
    // 代理商角色
    agentRole: CommonUtils.getUserInfo()?.agentRole ?? 0,
    // 是否登录
    isLogin: CommonUtils.getUserInfo()?.id > 0
  }),
  actions: {
    /** 存储ID */
    SET_ID(id: number) {
      this.id = id;
    },
    /** 存储头像 */
    SET_AVATAR(avatar: string) {
      this.avatar = avatar;
    },
    /** 存储昵称 */
    SET_NICKNAME(nickname: string) {
      this.nickname = nickname;
    },
    /** 存储回调 */
    SET_EXTRA(extra: string) {
      this.extra = extra;
    },
    /** 存储用户名 */
    SET_USERNAME(username: string) {
      this.username = username;
    },
    /** 存储邀请码 */
    SET_INVITE_CODE(inviteCode: string) {
      this.inviteCode = inviteCode;
    },
    /** 存储角色 */
    SET_ROLES(roles: Array<string>) {
      this.roles = roles;
    },
    /** 存储角色 */
    SET_ROLE(role: string) {
      this.role = role;
    },
    /** 存储前端生成的验证码 */
    SET_VERIFYCODE(verifyCode: string) {
      this.verifyCode = verifyCode;
    },
    /** 存储登录页面显示哪个组件 */
    SET_CURRENTPAGE(value: number) {
      this.currentPage = value;
    },
    SET_SCORE_DETAIL(scoreDetail: ScoreType) {
      this.scoreDetail = scoreDetail;
    },
    SET_IS_AGENT(isAgent: boolean) {
      this.isAgent = isAgent;
    },
    SET_DIGIT_HUMAN_ACCOUNT(digitHumanAccount: DigitHumanAccount) {
      this.digitHumanAccount = digitHumanAccount;
    },
    SET_PERMISSIONS(permission: number) {
      this.permission = permission;
    },
    /** 存储角色 */
    SET_AGENT_PERMISSIONS(agentPermissions: number) {
      this.agentPermissions = agentPermissions;
    },
    SET_REALNAME(realName: string) {
      this.realName = realName;
    },
    SET_PAYACCOUNT(payAccount: string) {
      this.payAccount = payAccount;
    },
    SET_PARENT_USERNAME(parentUsername: string) {
      this.parentUsername = parentUsername;
    },
    /** 存储ID */
    SET_AGENT_ID(agentId: number) {
      this.agentId = agentId;
    },
    SET_CHANGE_AGENT_INFO(changeAgentInfo: boolean) {
      this.changeAgentInfo = changeAgentInfo;
    },
    // 存储用户的菜单权限和按钮权限
    SET_ROLE_PERMISSIONS(rolePermissions: Array<userRolePermissionsType>) {
      this.rolePermissions = rolePermissions;
    },
    // 存储用户和api用户
    SET_TYPE(type: UserTypeEnum) {
      this.type = type;
    },
    SET_AGENT_INFO(agentRole: number) {
      this.agentRole = agentRole;
    },

    SET_IS_LOGIN(isLogin: boolean) {
      this.isLogin = isLogin;
    },
    getNickname() {
      return this.nickname ?? this.username;
    },
    getExtra() {
      return this.extra ?? this.extra;
    },
    /** 注册 */
    async registByUsername(data) {
      return new Promise<UserResult>((resolve, reject) => {
        userApi
          .postRegist(data)
          .then(res => {
            if (res) {
              resolve(res);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 账号密码登入 */
    async loginByUsername(data) {
      return new Promise<UserResult>((resolve, reject) => {
        userApi
          .getLogin(data)
          .then(res => {
            if (res.statusCode === Code.SUCCESS.statusCode) {
              setToken(res.data);
            }
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 验证码登陆 */
    async getLoginByPhone(data) {
      return new Promise<UserResult>((resolve, reject) => {
        userApi
          .getLoginByPhone(data)
          .then(res => {
            if (res.statusCode === Code.SUCCESS.statusCode) {
              setToken(res.data);
            }
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 微信登陆 */
    async getLoginByWX(data) {
      return new Promise<UserResult>((resolve, reject) => {
        userApi
          .postWxLoginAfter(data)
          .then(res => {
            if (res.statusCode === Code.SUCCESS.statusCode) {
              setToken(res.data);
            }
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 刷新用户信息 */
    async refreshUserJWT() {
      return new Promise<UserResult>((resolve, reject) => {
        userApi
          .refreshToken()
          .then(res => {
            if (res.statusCode === Code.SUCCESS.statusCode) {
              console.log("刷新用户信息", res.data);
              setToken(res.data);
            }
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /** 获取用户积分 */
    async getUserScore() {
      const res = await userApi.getScoreAccount();
      if (res.statusCode === Code.SUCCESS.statusCode) {
        const { totalScore, freezeScore, score } = res.data;
        await useUserStoreHook().updateScoreDetail({
          totalScore,
          freezeScore,
          score
        });
      }
    },
    /**登陆失效 清除用户数据 */
    clearUserInfo() {
      this.username = "";
      this.roles = [];
      this.isLogin = false;
      removeToken();
      useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
      //resetRouter();
      localStorage.removeItem("show-image-ai-alert");
      router.push("/login");
    },
    /** 前端登出（需要调用接口） */
    async logOut() {
      userApi.logout();
      setTimeout(() => {
        this.clearUserInfo();
      }, 1000);
    },
    updateAvatar(avatar: string) {
      const userInfo = storageLocal().getItem<DataInfo<string>>(sessionKey);
      userInfo.avatar = avatar;
      this.SET_AVATAR(avatar);
      storageLocal().setItem(sessionKey, userInfo);
    },
    updateNickname(nickname: string) {
      const userInfo = storageLocal().getItem<DataInfo<string>>(sessionKey);
      userInfo.nickname = nickname;
      this.SET_NICKNAME(nickname);
      storageLocal().setItem(sessionKey, userInfo);
    },
    updateExtra(extra: string) {
      const userInfo = storageLocal().getItem<DataInfo<string>>(sessionKey);
      userInfo.extra = extra;
      this.SET_EXTRA(extra);
      storageLocal().setItem(sessionKey, userInfo);
    },
    updateScoreDetail(scoreDetail: ScoreType) {
      const userInfo = storageLocal().getItem<DataInfo<string>>(sessionKey);
      userInfo.scoreDetail = scoreDetail;
      this.SET_SCORE_DETAIL(scoreDetail);
      storageLocal().setItem(sessionKey, userInfo);
    },
    updateRealNameAndPayAccount(realName: string, payAccount: string) {
      const userInfo = storageLocal().getItem<DataInfo<string>>(sessionKey);
      userInfo.realName = realName;
      this.SET_REALNAME(realName);
      userInfo.payAccount = payAccount;
      this.SET_PAYACCOUNT(payAccount);
      storageLocal().setItem(sessionKey, userInfo);
    }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}

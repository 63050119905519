<script setup lang="ts">
import { ref } from "vue";
import UserList from "@/views/components/aitools/UserList.vue";
import { userType } from "@/store/modules/types";
import { AdminSearchType } from "@/api";
const dialogVisible = ref(false);
const userListRef = ref();

const showDialog = () => {
  dialogVisible.value = true;
};

const closeDialog = () => {
  dialogVisible.value = false;
};
const selected = (mUser: userType) => {
  emit("selected", mUser);
  closeDialog();
};
const serarchSubmit = (mAdminSearchType: AdminSearchType) => {
  userListRef.value.serarchSubmit(mAdminSearchType);
};

const emit = defineEmits(["selected"]);
defineExpose({ showDialog });
</script>

<template>
  <div>
    <el-dialog
      v-if="dialogVisible"
      v-model="dialogVisible"
      :align-center="true"
      width="60vw"
      class="el-button-ml-reset"
      @close="closeDialog"
    >
      <!--isHaveSelectUser 是UserSearch有用户有选择的功能 -->
      <div class="p-5 flex flex-col">
        <CommonAdminSearch :emailShow="true" @serarchSubmit="serarchSubmit" />
        <UserList
          ref="userListRef"
          :isHaveSelectUser="true"
          @mySelected="selected"
        />
      </div>
    </el-dialog>
  </div>
</template>

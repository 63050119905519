import {
  AgentRateTypeEnum,
  BrandEnum,
  CountryLanguageCodeEnum,
  DemoGroupEnum,
  DigitStatusResult,
  FirstLoginEnum,
  FromType,
  GiftTypeEnum,
  LangTypeEnum,
  NewsGroupEnum,
  OperateEnum,
  PayBusinessTypeEnum,
  RankTypeEnum,
  RoleEnum,
  StatusTypeEnum,
  SwiperSortingOptionsEnum,
  RouterNameEnum,
  isFirstParentIdEnum,
  PayChannel,
  PayChannelStr,
  SkipRandomEnum,
  CreateModeEnum,
  GlobalStateEnum,
  UserTypeEnum,
  TransactionDirectionEnum,
  MenuVisibleTypeEnum,
  RouterTypeEnum
} from "./constantsEnum";
import OneScreen from "@/views/welcome/OneScreen.vue";
import TwoScreen from "@/views/welcome/TwoScreen.vue";
import ThreeScreen from "@/views/welcome/ThreeScreen.vue";
import FourScreen from "@/views/welcome/FourScreen.vue";

const totalManageSourceArr = [
  {
    label: "首页banner",
    value: NewsGroupEnum.NEWS_BANNER
  },
  {
    label: "弹出广告窗内容",
    value: NewsGroupEnum.NEWS_GROUP_FIRST_PAGE
  },
  {
    label: "关于我们",
    value: NewsGroupEnum.ABOUT_ME
  },
  {
    label: "数字人商品文字说明",
    value: NewsGroupEnum.DEMO_ADVERTISE
  }
];

const demoManageSourceArr = [
  {
    label: "首页demo视频",
    value: NewsGroupEnum.NEWS_VIDEO_DEMO
  }
];

// 文件类型
const operateOptionArr = [
  {
    label: "更新",
    value: OperateEnum.UPDATE
  },
  {
    label: "创建",
    value: OperateEnum.CREATE
  }
];

// 文件类型
const agentRateTypeArr = [
  {
    label: "比例",
    value: AgentRateTypeEnum.RATE_REWARD
  },
  {
    label: "底价",
    value: AgentRateTypeEnum.AMOUNT_REWARD
  },
  {
    label: "底比",
    value: AgentRateTypeEnum.AMOUNT_AND_RATE_REWARD
  }
];

// 文件类型
const skipRandomArr = [
  {
    label: "随机模式",
    value: SkipRandomEnum.RANDOM
  },
  {
    label: "顺序模式",
    value: SkipRandomEnum.CLONE_RANDOM
  }
];

// 视频版本模式
const createVideoModeArr = [
  {
    label: "高清版本",
    value: CreateModeEnum.LITE
  },
  {
    label: "超清版本",
    value: CreateModeEnum.PRO
  }
];

const OperateArr = [
  {
    label: "更新",
    value: OperateEnum.UPDATE
  },
  {
    label: "创建",
    value: OperateEnum.CREATE
  },
  {
    label: "选择",
    value: OperateEnum.SELECT
  },
  {
    label: "查看",
    value: OperateEnum.LOOK
  }
];

// 是否可以展示
const statusOptionsArr = [
  {
    label: "是",
    value: StatusTypeEnum.STATUS_USE
  },
  {
    label: "否",
    value: StatusTypeEnum.STATUS_NO
  }
];

const PayBusinessTypeOptions = [
  {
    label: "积分",
    value: PayBusinessTypeEnum.BUY_SCORE
  },
  {
    label: "推广官",
    value: PayBusinessTypeEnum.Dealer
  },
  {
    label: "短视频数字人",
    value: PayBusinessTypeEnum.DIGITAL_SHORT
  },
  {
    label: "极速数字人",
    value: PayBusinessTypeEnum.FAST_DIGITAL_SHORT
  },
  {
    label: "直播数字人",
    value: PayBusinessTypeEnum.DIGITAL_LIVE
  },
  {
    label: "交互数字人",
    value: PayBusinessTypeEnum.DIGITAL_INTERACTIVE
  },
  {
    label: "资源包",
    value: PayBusinessTypeEnum.SOURCE_PACKAGE
  }
];
const SearchPayBusinessTypeOptions = [
  {
    label: "所有",
    value: PayBusinessTypeEnum.ALL
  }
].concat(PayBusinessTypeOptions);

// 是否强制升级
const forceUpdateArr = [
  {
    label: "是",
    value: StatusTypeEnum.STATUS_USE
  },
  {
    label: "否",
    value: StatusTypeEnum.STATUS_NO
  }
];
const screenComponentsArr = [
  {
    label: SwiperSortingOptionsEnum.MIIT_AI_OPERATION_CERT,
    value: OneScreen
  },
  {
    label: SwiperSortingOptionsEnum.AI_OPERATOR_GROUP,
    value: TwoScreen
  },
  {
    label: SwiperSortingOptionsEnum.SHORT_VIDEO_MARKETING,
    value: ThreeScreen
  },
  {
    label: SwiperSortingOptionsEnum.INTERACTIVE_DIGITAL_PERSON,
    value: FourScreen
  }
];

const typeOptions = [
  {
    label: "短视频",
    value: DemoGroupEnum.DIGITAL_SHORT
  },
  {
    label: "交互数字人",
    value: DemoGroupEnum.DIGITAL_INTERACTIVE
  },
  {
    label: "直播数字人",
    value: DemoGroupEnum.DIGITAL_LIVE
  },
  {
    label: "资源包",
    value: DemoGroupEnum.SOURCE_PACKAGE
  },
  {
    label: "极速数字人",
    value: DemoGroupEnum.FAST_DIGITAL_SHORT
  }
];

const giftTypeOptionsArr = [
  {
    label: "无赠送",
    value: GiftTypeEnum.NO_GIFT
  },
  {
    label: "平台积分",
    value: GiftTypeEnum.SCORE
  }
];
const showCloseDigitalDocOptions = [
  {
    label: "是",
    value: true
  },
  {
    label: "否",
    value: false
  }
];
const langOptionsArr = [
  {
    label: "中文",
    value: LangTypeEnum.ZH
  },
  {
    label: "英文",
    value: LangTypeEnum.EN
  },
  {
    label: "繁体",
    value: LangTypeEnum.ZH_TW
  }
];
const useStatusTypeOptions = [
  {
    label: "是",
    value: true
  },
  {
    label: "否",
    value: false
  }
];
const digitStatusResultOptions = [
  // {
  //   label: "所有",
  //   value: DigitStatusResult.ALL
  // },
  {
    label: "补充基本信息",
    value: DigitStatusResult.ADD_INFO
  },
  {
    label: "补充视频和认证信息",
    value: DigitStatusResult.ADD_VIDEO_AUTH
  },
  {
    label: "等待审核",
    value: DigitStatusResult.PENDING
  },
  {
    label: "审核通过,等待上传",
    value: DigitStatusResult.PENDING_UOLOAD
  },
  {
    label: "上传成功,等待确认",
    value: DigitStatusResult.UPLOAD_SUCCESS
  },
  {
    label: "等待创建",
    value: DigitStatusResult.WAITING_CREATE
  },
  {
    label: "订单完成",
    value: DigitStatusResult.SUCCESS
  }
];

const searchDigitalStatusResultOptions = [
  {
    label: "所有",
    value: DigitStatusResult.ALL
  }
].concat(digitStatusResultOptions);

const brandOptions = [
  {
    label: "全部",
    value: BrandEnum.ALL
  },
  {
    label: "默认",
    value: BrandEnum.MINE
  },
  {
    label: "硅基",
    value: BrandEnum.GUI_JI
  },
  {
    label: "小冰",
    value: BrandEnum.XIAO_BING
  },
  {
    label: "腾讯",
    value: BrandEnum.TENG_XUN
  },
  {
    label: "飞影",
    value: BrandEnum.PLANT
  }
];

const FirstLoginEnumOptions = [
  {
    label: "微信登陆",
    value: FirstLoginEnum.WX
  },
  {
    label: "用户名密码登陆",
    value: FirstLoginEnum.EMAIL
  },
  {
    label: "验证码登陆",
    value: FirstLoginEnum.PHONE
  }
];
const CountryLanguageCodeArr = [
  {
    value: CountryLanguageCodeEnum.zhCN,
    name: "中文"
  },
  {
    value: CountryLanguageCodeEnum.zhHK,
    name: "中文粤语"
  },
  {
    value: CountryLanguageCodeEnum.enUS,
    name: "英语"
  },
  {
    value: CountryLanguageCodeEnum.jaJP,
    name: "日语"
  },
  {
    value: CountryLanguageCodeEnum.esES,
    name: "西班牙语"
  },
  {
    value: CountryLanguageCodeEnum.idID,
    name: "印尼语"
  },
  {
    value: CountryLanguageCodeEnum.ptPT,
    name: "葡萄牙语"
  },
  {
    value: CountryLanguageCodeEnum.ruRU,
    name: "俄语"
  },
  {
    value: CountryLanguageCodeEnum.thTH,
    name: "泰语"
  },
  {
    value: CountryLanguageCodeEnum.deCH,
    name: "瑞士德语"
  },
  {
    value: CountryLanguageCodeEnum.koKR,
    name: "韩语"
  },
  {
    value: CountryLanguageCodeEnum.frFR,
    name: "法语"
  }
];
const fromTypeOptions = [
  {
    label: "用户",
    value: FromType.USER
  },
  {
    label: "系统",
    value: FromType.SYSTEM
  }
];

const videoStatusArr = [
  {
    label: "成功",
    value: GlobalStateEnum.Success
  },
  {
    label: "合成中",
    value: GlobalStateEnum.Pending
  },
  {
    label: "失败",
    value: GlobalStateEnum.Fail
  }
];
const CloneCountryLanguageCodeArr = [
  {
    name: "中文", // 中文
    value: CountryLanguageCodeEnum.zhCN
  },
  {
    name: "英语", // 英语
    value: CountryLanguageCodeEnum.enUS //"英语"
  },
  {
    name: "日语",
    value: CountryLanguageCodeEnum.jaJP //"日语"
  },
  {
    name: "西班牙语",
    value: CountryLanguageCodeEnum.esES //"西班牙语"
  },
  {
    name: "印尼语",
    value: CountryLanguageCodeEnum.idID // "印尼语"
  },
  {
    name: "葡萄牙语",
    value: CountryLanguageCodeEnum.ptPT //"葡萄牙语"
  }
];

const UserTypeArr = [
  {
    label: "api用户", // api用户
    value: UserTypeEnum.API
  },
  {
    label: "用户", // 普通用户
    value: UserTypeEnum.USER
  }
];
const rankTypeOptions = [
  {
    label: "上移",
    value: RankTypeEnum.UP
  },
  {
    label: "下移",
    value: RankTypeEnum.DOWN
  }
];
const showLinkOptions = [
  {
    label: "非全屏", // 全屏
    value: MenuVisibleTypeEnum.MENU_VISIBLE
  },
  {
    label: "全屏", // 全屏
    value: MenuVisibleTypeEnum.MENU_INVISIBLE
  }
];
const routerTypeOptions = [
  {
    label: "前端路由", // 前端路由
    value: RouterTypeEnum.USER
  },
  {
    label: "后端路由", // 后端路由
    value: RouterTypeEnum.ADMIN
  }
];
// 前端是否出现支付渠道
const payChannelOptionArr = [
  {
    label: PayChannelStr.WX,
    value: PayChannel.WX
  },
  {
    label: PayChannelStr.ALIPAY,
    value: PayChannel.ALIPAY
  },
  {
    label: PayChannelStr.PAYPAL,
    value: PayChannel.PAYPAL
  }
];
const roleEnumOptions = [
  {
    label: "超管",
    value: RoleEnum.SuperAdmin
  },
  {
    label: "普管",
    value: RoleEnum.Admin
  },
  {
    label: "合伙人",
    value: RoleEnum.Partner
  },
  {
    label: "员工",
    value: RoleEnum.Staff
  },
  {
    label: "用户",
    value: RoleEnum.User
  }
];
const routerStatusEnumOptions = [
  {
    label: "是",
    value: GlobalStateEnum.Success
  },
  {
    label: "否",
    value: GlobalStateEnum.Fail
  }
];
const transactionDirectionOptions = [
  {
    label: "入账",
    value: TransactionDirectionEnum.INCOME
  },
  {
    label: "出账",
    value: TransactionDirectionEnum.EXPENSE
  }
];
const digitalStateEnumOptions = [
  {
    label: "成功",
    value: GlobalStateEnum.Success
  },
  {
    label: "失败",
    value: GlobalStateEnum.Fail
  },
  {
    label: "正在克隆中",
    value: GlobalStateEnum.Pending
  },
  {
    label: "等待开始",
    value: GlobalStateEnum.Waiting
  },
  {
    label: "等待加入克隆队列",
    value: GlobalStateEnum.WaitingBack
  }
];
// 生成选项数组
const routerNameOptions = Object.keys(RouterNameEnum)
  .filter(key => isNaN(Number(key))) // 过滤掉数字键
  .map(key => ({
    value: RouterNameEnum[key as keyof typeof RouterNameEnum],
    label: key
  }))
  .sort((a, b) => a.label.localeCompare(b.label)); // 按照字母顺序排序

const isFirstParentIdOptions = [
  {
    label: "一级路由",
    value: isFirstParentIdEnum.FIRST_PARENTID
  },
  {
    label: "非一级路由",
    value: isFirstParentIdEnum.NOT_FIRST_PARENTID
  }
];
export {
  digitalStateEnumOptions,
  transactionDirectionOptions,
  isFirstParentIdOptions,
  routerNameOptions,
  routerStatusEnumOptions,
  roleEnumOptions,
  routerTypeOptions,
  showLinkOptions,
  rankTypeOptions,
  CountryLanguageCodeArr,
  CloneCountryLanguageCodeArr,
  fromTypeOptions,
  FirstLoginEnumOptions,
  PayBusinessTypeOptions,
  statusOptionsArr,
  forceUpdateArr,
  typeOptions,
  giftTypeOptionsArr,
  langOptionsArr,
  operateOptionArr,
  screenComponentsArr,
  agentRateTypeArr,
  useStatusTypeOptions,
  brandOptions,
  digitStatusResultOptions,
  searchDigitalStatusResultOptions,
  SearchPayBusinessTypeOptions,
  OperateArr,
  totalManageSourceArr,
  demoManageSourceArr,
  payChannelOptionArr,
  skipRandomArr,
  videoStatusArr,
  createVideoModeArr,
  showCloseDigitalDocOptions,
  UserTypeArr
};

import { LocalStorageKeyEnum } from "@/config";
import { LocalStrogeUtils } from "@/utils";

export const ChatHelp = {
  get(): Chat.SaveModelType {
    const saveModelTypeRef = LocalStrogeUtils.get(
      ChatHelp.getAgentOrChatType(),
      true
    );
    return saveModelTypeRef;
  },
  // 如果是单个key,调用该方法
  saveByKey(key: string, value: any) {
    let saveModelType = LocalStrogeUtils.get(ChatHelp.getAgentOrChatType());
    if (saveModelType) {
      saveModelType[key] = value;
      LocalStrogeUtils.set(ChatHelp.getAgentOrChatType(), saveModelType, true);
    }
    saveModelType = LocalStrogeUtils.get(ChatHelp.getAgentOrChatType());
    return saveModelType;
  },
  // 对象调用该方法
  saveByObj(saveModelType: Chat.SaveModelType): Chat.SaveModelType {
    LocalStrogeUtils.set(ChatHelp.getAgentOrChatType(), saveModelType, true);
    saveModelType = LocalStrogeUtils.get(ChatHelp.getAgentOrChatType());
    return saveModelType;
  },
  // 对象调用该方法
  saveAgentOrChatType(agentChooseModel: LocalStorageKeyEnum) {
    LocalStrogeUtils.set(LocalStorageKeyEnum.CHAT_AGENT_TYPE, agentChooseModel);
  },
  // 对象调用该方法
  getAgentOrChatType(): LocalStorageKeyEnum {
    const agentChooseModel = LocalStrogeUtils.get(
      LocalStorageKeyEnum.CHAT_AGENT_TYPE
    );
    return agentChooseModel == LocalStorageKeyEnum.AGENT_CHOOSE_MODEL
      ? LocalStorageKeyEnum.AGENT_CHOOSE_MODEL
      : LocalStorageKeyEnum.CHAT_CHOOSE_MODEL;
  }
};

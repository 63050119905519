import { http } from "@/utils/http";
import { ResCommon, ReqDemoVideo, Result } from "./types";
import { ReqSysConfig } from "@/store/modules/types";
export const routesApi = {
  //获取有菜单的路由和没有菜单的路由
  getAsyncRoutes: async () => {
    const { data } = await http.request<Result>("get", "router/getAsyncRoutes");
    return {
      routerList: data.routerList,
      remainRouterList: data.remainRouterList
    };
  },
  /**获取系统配置信息 */
  getSystemConfig: () => {
    return http.get<any, ResCommon>("agent/agent/sys/config");
  },
  /**提交系统配置信息 */
  postSystemConfig: (data: ReqSysConfig) => {
    return http.post<any, ResCommon>("agent/update/agent/sys/config", { data });
  },

  /**提交首页视频案例信息 */
  postDemoVideo: (data: ReqDemoVideo) => {
    return http.post<any, ResCommon>("/user/update/agent/demo/video", { data });
  },

  /**获取微信公众号appId */
  getWxId: () => {
    return http.get<any, ResCommon>("agent/wx/app/id");
  }
};

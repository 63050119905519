import { App } from "vue";

let config: object = {};

const setConfig = (cfg?: unknown) => {
  config = Object.assign(config, cfg);
  // console.log("全局配置", config);
};

const getConfig = (key?: string): ServerConfigs => {
  if (typeof key === "string") {
    const arr = key.split(".");
    if (arr && arr.length) {
      let data = config;
      arr.forEach(v => {
        if (data && typeof data[v] !== "undefined") {
          data = data[v];
        } else {
          data = null;
        }
      });
      return data;
    }
  }
  return config;
};
// 需要变化才需要写到pinia中,项目一启动就固定的写到projectConfig中
import { initlang } from "@/utils/responsive";
const serverConfig = {
  Version: "1.0.0",
  Title: null,
  SubTitle: "FutureAI",
  FixedHeader: true,
  HiddenSideBar: false,
  MultiTagsCache: false,
  KeepAlive: true,
  Locale: initlang(),
  Layout: "horizontal",
  Theme: null,
  DarkMode: false,
  Grey: false,
  Weak: false,
  HideTabs: true,
  SidebarStatus: false,
  EpThemeColor: "#409EFF",
  ShowLogo: true,
  ShowModel: "smart",
  MenuArrowIconNoTransition: true,
  CachingAsyncRoutes: false,
  TooltipEffect: "light"
};

/** 获取项目动态全局配置 */
export const getServerConfig = (app: App): any => {
  app.config.globalProperties.$config = getConfig();
  // 响应对象中的 data 属性被解构并重命名为 config
  let $config = app.config.globalProperties.$config;
  // 自动注入项目配置
  if (app && $config && typeof serverConfig === "object") {
    $config = Object.assign($config, serverConfig);
    app.config.globalProperties.$config = $config;
    // 设置全局配置
    setConfig($config);
    return $config;
  }
};

export { getConfig, setConfig };
export * from "@/config/error-code";
export * from "@/config/constants";
export * from "@/config/constantsEnum";
export * from "@/config/constantsArray";
export * from "@/config/constantsTableActiveName";
export * from "@/config/voiceUtils";
export * from "@/config/promptCategoryList";

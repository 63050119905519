// 响应式storage
import { App } from "vue";
// 解决pinia vuex等刷新后状态丢失的问题
import Storage from "responsive-storage";
import { routerArrays } from "@/layout/types";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { storageLocal } from "@pureadmin/utils";

const nameSpace = "responsive-";
// 判断当前用户当前语言环境
export const initlang = () => {
  let lang =
    storageLocal().getItem<StorageConfigs>("responsive-locale")?.locale;
  if (!lang) {
    lang = (navigator.language || navigator["userLanguage"]).toLowerCase();
  }
  if (lang !== "en" && lang !== "zh-tw") {
    lang = "zh";
  }
  // 浏览器语言模型  zh-CN zh-TW en
  console.log("当前浏览器的语言环境是", lang);
  return lang;
};

// 国际化相关配置
export const injectResponsiveStorage = (app: App, config: ServerConfigs) => {
  const lang = useSystemConfigStoreHook()?.lang;
  const configObj = Object.assign(
    {
      // 国际化 默认中文zh
      locale: {
        locale: lang
      },
      // layout模式以及主题
      layout: {
        layout: config.Layout ?? "vertical",
        theme: config.Theme ?? "default",
        darkMode: config.DarkMode ?? false,
        sidebarStatus: false,
        epThemeColor: config.EpThemeColor ?? "#409EFF"
      },
      configure: {
        grey: config.Grey ?? false,
        weak: config.Weak ?? false,
        hideTabs: config.HideTabs ?? false,
        showLogo: config.ShowLogo ?? true,
        showModel: config.ShowModel ?? "smart",
        multiTagsCache: config.MultiTagsCache ?? false
      }
    },
    config.MultiTagsCache
      ? { tags: Storage.getData("tags", nameSpace) ?? routerArrays }
      : {}
  );

  app.use(Storage, { version: 3, nameSpace, memory: configObj });
};

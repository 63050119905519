import { addIcon } from "@iconify/vue/dist/offline";

/**
 * 这里存放本地图标，在 src/layout/index.vue 文件中加载，避免在首启动加载
 * 本地菜单图标，后端在路由的 icon 中返回对应的图标字符串并且前端在此处使用 addIcon 添加即可渲染菜单图标
 * @iconify-icons/ep
 */
import Menu from "@iconify-icons/ep/menu";
import Edit from "@iconify-icons/ep/edit";
import Guide from "@iconify-icons/ep/guide";
import Monitor from "@iconify-icons/ep/monitor";
import Histogram from "@iconify-icons/ep/histogram";
import Tag from "@iconify-icons/ri/bookmark-2-line";
import Ppt from "@iconify-icons/ri/file-ppt-2-line";
import Card from "@iconify-icons/ri/bank-card-line";
import Role from "@iconify-icons/ri/admin-fill";
import Info from "@iconify-icons/ri/file-info-line";
import Dept from "@iconify-icons/ri/git-branch-line";
import Table from "@iconify-icons/ri/table-line";
import Links from "@iconify-icons/ri/links-fill";
import Search from "@iconify-icons/ri/search-line";
import FlUser from "@iconify-icons/ri/admin-line";
import Setting from "@iconify-icons/ri/settings-3-line";
import MindMap from "@iconify-icons/ri/mind-map";
import BarChart from "@iconify-icons/ri/bar-chart-horizontal-line";
import LoginLog from "@iconify-icons/ri/window-line";
import Artboard from "@iconify-icons/ri/artboard-line";
import SystemLog from "@iconify-icons/ri/file-search-line";
import ListCheck from "@iconify-icons/ri/list-check";
import UbuntuFill from "@iconify-icons/ri/ubuntu-fill";
import OnlineUser from "@iconify-icons/ri/user-voice-line";
import EditBoxLine from "@iconify-icons/ri/edit-box-line";
import OperationLog from "@iconify-icons/ri/history-fill";
import TerminalWindowLine from "@iconify-icons/ri/terminal-window-line";
import CheckboxCircleLine from "@iconify-icons/ri/checkbox-circle-line";
import HomeFilled from "@iconify-icons/ep/home-filled";
import InformationLine from "@iconify-icons/ri/information-line";
import Lollipop from "@iconify-icons/ep/lollipop";
import MenuDigitalAssets from "@iconify-icons/ri/app-store-fill";
import CreateWork from "@iconify-icons/ri/video-add-fill";
import DigitTwins from "@iconify-icons/ri/video-add-fill";
import MenuWorkManagement from "@iconify-icons/ri/folder-2-fill";
import Mine from "@iconify-icons/ri/money-cny-box-fill";
import SetUp from "@iconify-icons/ep/set-up";
import Material from "@iconify-icons/ep/set-up";
import News from "@iconify-icons/ri/newspaper-fill";
import ChatAI from "@iconify-icons/ri/message-2-fill";
import ImageManage from "@iconify-icons/ri/image-2-fill";
import ImageAI from "@iconify-icons/ri/palette-fill";
import Cooperate from "@iconify-icons/ri/hand-heart-fill";
import ImageSquare from "@iconify-icons/ri/earth-fill";
import ImageAIGuide from "@iconify-icons/ri/lightbulb-fill";
import ImageAIParam from "@iconify-icons/ri/book-fill";
import ChatAIGuide from "@iconify-icons/ri/book-2-fill";
import WeChatPay from "@iconify-icons/ri/wechat-pay-fill";
import AICategory from "@iconify-icons/ri/home-3-line";
import TextExtract from "@iconify-icons/ri/file-text-line";
import TextToAudio from "@iconify-icons/ri/voiceprint-fill";
import AudioToText from "@iconify-icons/ri/chat-voice-fill";
import ChatAICategory from "@iconify-icons/ri/message-2-fill";
import AIPhotoShop from "@iconify-icons/ri/image-edit-fill";
import AIPoster from "@iconify-icons/ri/apps-fill";
import ImgRepair from "@iconify-icons/ri/paint-brush-fill";
import ImgCutOutNormal from "@iconify-icons/ri/user-3-fill";
import ImgCutOutFace from "@iconify-icons/ri/user-4-fill";
import ImgBeautify from "@iconify-icons/ri/moon-clear-fill";
import ImgFaceBecomesClear from "@iconify-icons/ri/star-smile-fill";
import ImgPhotoColoring from "@iconify-icons/ri/paint-fill";
import ImgCartoonAvatar from "@iconify-icons/ri/aliens-fill";
import DigitalOrder from "@iconify-icons/ri/list-ordered";
import AudioClone from "@iconify-icons/ri/mic-2-fill";
import Agent from "@iconify-icons/ri/terminal-window-line";
import LargeScreen from "@iconify-icons/ri/artboard-line";
import PermissionManageIcon from "@iconify-icons/ri/bar-chart-horizontal-fill";
import MenuMyCustom from "@iconify-icons/ri/account-circle-fill";
import MenuMyRights from "@iconify-icons/ri/magic-line";
import DigitalWorkManagement from "@iconify-icons/ri/clapperboard-line";
import AccountManage from "@iconify-icons/ri/account-box-fill";
import AlipayIcon from "@iconify-icons/ri/alipay-fill";
import BankCardIcon from "@iconify-icons/ri/bank-card-line";

addIcon("HomeFilled", HomeFilled);
addIcon("AlipayIcon", AlipayIcon);
addIcon("BankCardIcon", BankCardIcon);
addIcon("InformationLine", InformationLine);
addIcon("Lollipop", Lollipop);
addIcon("MenuDigitalAssets", MenuDigitalAssets);
addIcon("CreateWork", CreateWork);
addIcon("DigitTwins", DigitTwins);
addIcon("MenuWorkManagement", MenuWorkManagement);
addIcon("Mine", Mine);
addIcon("Setup", SetUp);
addIcon("Material", Material);
addIcon("News", News);
addIcon("ChatAI", ChatAI);
addIcon("ImageManage", ImageManage);
addIcon("ImageAI", ImageAI);
addIcon("Cooperate", Cooperate);
addIcon("ImageSquare", ImageSquare);
addIcon("ImageAIGuide", ImageAIGuide);
addIcon("ImageAIParam", ImageAIParam);
addIcon("ChatAIGuide", ChatAIGuide);
addIcon("WeChatPay", WeChatPay);
addIcon("AICategory", AICategory);
addIcon("TextExtract", TextExtract);
addIcon("TextToAudio", TextToAudio);
addIcon("AudioToText", AudioToText);
addIcon("ChatAICategory", ChatAICategory);
addIcon("AIPhotoShop", AIPhotoShop);
addIcon("AIPoster", AIPoster);
addIcon("ImgRepair", ImgRepair);
addIcon("ImgCutOutNormal", ImgCutOutNormal);
addIcon("ImgCutOutFace", ImgCutOutFace);
addIcon("ImgBeautify", ImgBeautify);
addIcon("ImgFaceBecomesClear", ImgFaceBecomesClear);
addIcon("ImgPhotoColoring", ImgPhotoColoring);
addIcon("ImgCartoonAvatar", ImgCartoonAvatar);
addIcon("DigitalOrder", DigitalOrder);
addIcon("AudioClone", AudioClone);
addIcon("Agent", Agent);
addIcon("LargeScreen", LargeScreen);
addIcon("PermissionManageIcon", PermissionManageIcon);
addIcon("MenuMyRights", MenuMyRights);
addIcon("MenuMyCustom", MenuMyCustom);
addIcon("DigitalWorkManagement", DigitalWorkManagement);
addIcon("AccountManage", AccountManage);
addIcon("ep:menu", Menu);
addIcon("ep:edit", Edit);
addIcon("ep:set-up", SetUp);
addIcon("ep:guide", Guide);
addIcon("ep:monitor", Monitor);
addIcon("ep:lollipop", Lollipop);
addIcon("ep:histogram", Histogram);
addIcon("ep:home-filled", HomeFilled);
addIcon("ri:bookmark-2-line", Tag);
addIcon("ri:file-ppt-2-line", Ppt);
addIcon("ri:bank-card-line", Card);
addIcon("ri:admin-fill", Role);
addIcon("ri:file-info-line", Info);
addIcon("ri:git-branch-line", Dept);
addIcon("ri:links-fill", Links);
addIcon("ri:table-line", Table);
addIcon("ri:search-line", Search);
addIcon("ri:admin-line", FlUser);
addIcon("ri:settings-3-line", Setting);
addIcon("ri:mind-map", MindMap);
addIcon("ri:bar-chart-horizontal-line", BarChart);
addIcon("ri:window-line", LoginLog);
addIcon("ri:file-search-line", SystemLog);
addIcon("ri:artboard-line", Artboard);
addIcon("ri:list-check", ListCheck);
addIcon("ri:ubuntu-fill", UbuntuFill);
addIcon("ri:user-voice-line", OnlineUser);
addIcon("ri:edit-box-line", EditBoxLine);
addIcon("ri:history-fill", OperationLog);
addIcon("ri:information-line", InformationLine);
addIcon("ri:terminal-window-line", TerminalWindowLine);
addIcon("ri:checkbox-circle-line", CheckboxCircleLine);
function offlineIcon() {}
export { offlineIcon };

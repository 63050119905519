<script setup lang="ts">
import { message } from "@/utils";
import { Clipboard } from "v-clipboard";

const props = defineProps({
  content: {
    type: String,
    default: null
  }
});

const truncateValue = value => {
  const desc = value + "";
  if (desc.includes("-")) {
    return desc.substring(desc.indexOf("-") + 1);
  }
  if (desc.includes("_")) {
    return desc.substring(desc.indexOf("_") + 1);
  }
  return desc;
};

const copy = value => {
  const item = truncateValue(value);
  Clipboard.copy(item);
  message("复制成功 " + item, { type: "success" });
};
</script>

<template>
  <el-tooltip class="box-item" effect="dark" content="复制" placement="top">
    <p
      @click="copy(props.content)"
      class="hover:text-primary active:text-[#f4f4f4] cursor-pointer"
    >
      {{ content }}
    </p>
  </el-tooltip>
</template>

<style></style>

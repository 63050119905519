<script setup lang="ts">
import { computed } from "vue";
import { SvgIcon } from "@/components";
import { useBasicLayout } from "@/layout/hooks/useBasicLayout";
import { useAppStore } from "@/store/modules/app/index";
// import { useAuthStore } from "@/store/modules/auth/index";
import { useChatStore } from "@/store/modules/chat/index";
import { useI18n } from "vue-i18n";
import { ChatHelp } from "../../utils/ChatHelp";
import { Constants } from "@/config";
const { t } = useI18n();

const { isMobile } = useBasicLayout();

const appStore = useAppStore();
const chatStore = useChatStore();

const dataSources = computed(() => chatStore.history);

const handleSelect = async (itemChat: Chat.History) => {
  if (isActive(itemChat.uuid)) return;
  if (chatStore.active)
    chatStore.updateHistory(chatStore.active, { isEdit: false });
  await chatStore.setActive(itemChat.uuid);
  if (isMobile.value) {
    appStore.setSiderCollapsed(true);
  }
  ChatHelp.saveByKey(Constants.ChatObjKey.systemId, itemChat.systemId); // 重新设置值
};

function handleEdit(
  { uuid }: Chat.History,
  isEdit: boolean,
  event?: MouseEvent
) {
  event?.stopPropagation();
  chatStore.updateHistory(uuid, { isEdit });
}

function handleDelete(index: number, event?: MouseEvent | TouchEvent) {
  event?.stopPropagation();
  chatStore.deleteHistory(index);
}

function handleEnter(
  { uuid }: Chat.History,
  isEdit: boolean,
  event: KeyboardEvent
) {
  event?.stopPropagation();
  if (event.key === "Enter") chatStore.updateHistory(uuid, { isEdit });
}

function isActive(uuid: number) {
  return chatStore.active === uuid;
}
</script>

<template>
  <el-scrollbar>
    <div class="flex flex-col gap-2 text-[14px]">
      <template v-if="!dataSources.length">
        <div
          class="flex flex-col items-center mt-4 text-center text-neutral-300"
        >
          <SvgIcon icon="ri:inbox-line" class="mb-2 text-3xl" />
          <span>{{ $t("common.noData") }}</span>
        </div>
      </template>
      <template v-else>
        <div v-for="(item, index) of dataSources" :key="index">
          <!--   'text-[#409EFF]', 'border-[#409EFF]',-->
          <a
            class="relative flex items-center gap-3 px-3 py-3 text-[#606266] hover:bg-[#C0D3E4] dark:text-[#cfd3dc] break-all border rounded-md cursor-pointer border-gray-300 group dark:border-neutral-800 dark:hover:bg-[#24272e]"
            :class="
              isActive(item.uuid) && [
                'border-none',
                'bg-[#C0D3E4]',

                'dark:bg-[#24272e]',
                'dark:border-[#24272e]',
                'pr-14'
              ]
            "
            @click="handleSelect(item)"
          >
            <span class="text-[16px]">
              <SvgIcon icon="ri:chat-4-line" />
            </span>
            <div
              class="relative flex-1 overflow-hidden break-all text-ellipsis whitespace-nowrap"
            >
              <el-input
                v-if="item.isEdit"
                v-model="item.title"
                size="small"
                @keypress="handleEnter(item, false, $event)"
              />
              <span v-else class="w-full">{{ item.title }}</span>
            </div>
            <div
              v-if="isActive(item.uuid)"
              class="absolute z-10 flex visible right-1"
            >
              <template v-if="item.isEdit">
                <button
                  class="p-1 text-gray-400 hover:text-black dark:text-gray-300 dark:hover:text-white text-[16px]"
                  @click="handleEdit(item, false, $event)"
                >
                  <SvgIcon icon="ri:save-line" />
                </button>
              </template>
              <template v-else>
                <button
                  class="p-1 text-gray-400 hover:text-black dark:text-gray-300 dark:hover:text-white text-[16px]"
                >
                  <SvgIcon
                    icon="ri:edit-line"
                    @click="handleEdit(item, true, $event)"
                  />
                </button>
                <el-popconfirm
                  :title="$t('chat.deleteHistoryConfirm')"
                  :confirm-button-text="t('common.yes')"
                  :cancel-button-text="t('common.no')"
                  @confirm="handleDelete(index, $event)"
                >
                  <template #reference>
                    <button
                      class="p-1 text-gray-400 hover:text-black dark:text-gray-300 dark:hover:text-white text-[16px]"
                    >
                      <SvgIcon icon="ri:delete-bin-line" />
                    </button>
                  </template>
                  {{ $t("chat.deleteHistoryConfirm") }}
                </el-popconfirm>
              </template>
            </div>
          </a>
        </div>
      </template>
    </div>
  </el-scrollbar>
</template>

import { RouterNameEnum } from "@/config";
import { capcut } from "@/router/enums";
// import hot from "@/assets/svg/hot.svg?component";
const IFrame = () => import("@/layout/frameView.vue");

export default {
  path: "/capcut",
  redirect: "/capcut/index",
  meta: {
    title: "剪映",
    rank: capcut,
    showLink: false
  },
  children: [
    {
      path: "/capcut/index",
      name: RouterNameEnum.FrameCapcut,
      component: IFrame,
      meta: {
        title: "剪映",
        frameSrc: "https://www.capcut.cn/"
        // frameSrc: "https://www.capcut.cn/editor"
        // frameSrc: "https://www.capcut.cn/home-page"
      }
    }
  ]
} as RouteConfigsTable;

<script setup lang="ts">
import { ref } from "vue";
import UserInfoDialog from "../dialog/UserInfoDialog.vue";
import { userType } from "@/store/modules/types";

const props = defineProps({
  userId: {
    type: Number,
    default: null
  }
});
const id = ref(props.userId);
const userInfoDialogRef = ref();

const findUser = () => {
  userInfoDialogRef.value.showDialog();
};

const selected = (mUser: userType) => {
  id.value = mUser.id;
  emit("selectedinfo", mUser);
};

const emit = defineEmits(["selectedinfo"]);
</script>

<template>
  <!-- 当前图片 -->
  <div>
    <div class="flex gap-5">
      <el-form-item label="userId">
        <el-input
          disabled
          type="number"
          v-model="id"
          placeholder="请点击选择按钮输入ID"
        />
      </el-form-item>
      <el-button @click="findUser">选择</el-button>
    </div>
    <UserInfoDialog @selected="selected" ref="userInfoDialogRef" />
  </div>
</template>

<style></style>

export class MathUtils {
  static isZero(value): boolean {
    return value == 0 || value == "0";
  }

  static add(a: number, b: number, decimalPointCount = 2): number {
    return +(a + b).toFixed(decimalPointCount);
  }

  static subtract(a: number, b: number, decimalPointCount = 2): number {
    return +(a - b).toFixed(decimalPointCount);
  }

  static multiply(a: number, b: number, decimalPointCount = 2): number {
    return +(a * b).toFixed(decimalPointCount);
  }

  static divide(a: number, b: number, decimalPointCount = 2): number {
    if (MathUtils.isZero(b)) {
      return 0;
    }
    return +(a / b).toFixed(decimalPointCount);
  }
  static safeParseFloat(value, decimalPointCount = 2): number {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : +parsed.toFixed(decimalPointCount); // 如果解析结果为 NaN，则返回 0
  }
}

import { http } from "@/utils/http";
import {
  UserResult,
  ReqWXLoginAfter,
  ResCommon,
  ReqRechargeScore,
  ReqSetPayPassword,
  ReqChangePayPassword,
  ReqWithdraw,
  ReqCommonHistory,
  ReqRegisterInfo,
  ResPayData,
  ReqDigitHumanRegisterVideoInfo,
  ResLoginQr,
  ResPayDataRechargeScoreType,
  sysVoiceListType,
  editUserInfoType
} from "./types";
import { demoType } from "@/components/types/types";
import { QrType } from "@/config/constantsEnum";
export const userApi = {
  /** 刷新token */
  refreshToken: () => {
    return http.post<object, ResCommon>("refresh/jwt");
  },
  /** 获取首页优秀案例 */
  getGoodVideo: () => {
    return http.get<object, ResCommon>("app/good/news");
  },
  /**
   * 新增首页视频案例demo
   */
  postCreateOrUodateDemo: (data: demoType) => {
    return http.post<any, ResCommon>("user/update/agent/demo/video", {
      data
    });
  },
  /** 注册 */
  postRegist: (data?: object) => {
    return http.post<object, UserResult>("user/register", { data });
  },
  /** 账号密码登陆 */
  getLogin: (data?: object) => {
    return http.post<object, UserResult>("user/login", {
      data
    });
  },
  /** 手机号登陆 */
  getLoginByPhone: (data?: object) => {
    return http.post<object, UserResult>("user/login/verify/code", {
      data
    });
  },

  /**根据用户名查找用户 */
  postFindByName: (username?: string) => {
    return http.post(`user/findByName/${username}`);
  },
  /**根据ID查找用户 */
  postFindById: (id?: number) => {
    return http.post(`user/findById/${id}`);
  },
  /**修改密码+忘记密码 */
  postUpdatePasword: (data: object) => {
    return http.post<any, ResCommon>("user/change/password", { data });
  },
  /**修改密码+忘记密码 */
  forgetUpdatePasword: (data: object) => {
    return http.post<any, ResCommon>("user/forget/password", { data });
  },
  /**请求验证码接口 */
  postGeetest: (data: object) => {
    return http.post<any, ResCommon>("user/verify/code", { data });
  },
  /**提交极验信息 */
  postTest: (data: any) => {
    return http.post<any, ResCommon>(
      "validate?captcha_id=fd8ee07e7825b9f3c0adf59463e936e4",
      { data }
    );
  },

  /**用户端获取新闻 这个有缓存 */
  postNewsList: (group?: number) => {
    return http.get<any, ResCommon>(`news/list/${group}`);
  },

  /**用户端获取订单记录 */
  getOnlinePayOrder: (data: any) => {
    return http.post<any, ResCommon>("on-line-pay/list", { data });
  },

  /*发送微信登陆后用户输入的邮箱或手机 */
  postWxLoginPre: (code: string) => {
    return http.post<any, ResCommon>(`user/login/pre/wx/${code}`);
  },
  /*微信登陆后创建用户 */
  postWxLoginAfter: (data: ReqWXLoginAfter) => {
    return http.post<any, ResCommon>("user/login/after/wx", { data });
  },
  /*修改昵称 */
  changeUserInfo: (data: editUserInfoType) => {
    return http.patch<editUserInfoType, ResCommon>("user/change/user/info", {
      data
    });
  },
  /**用户端获取积分充值类型 */
  getRechargeScoreType: () => {
    return http.get<any, ResPayDataRechargeScoreType>("score-recharge/list");
  },
  /**用户端获取积分*/
  getScoreAccount: () => {
    return http.get<any, ResCommon>("score-account");
  },
  /**用户端获取积分支付二维码信息 */
  postPreRechargeScore: (data: ReqRechargeScore) => {
    return http.post<any, ResPayData>("on-line-pay/pre/score/recharge", {
      data
    });
  },
  /**用户端获取积分支付成功后回调 */
  getAfterRechargeScore: (orderId: string) => {
    return http.get<any, ResCommon>(
      `on-line-pay/after/score/recharge/${orderId}`
    );
  },

  /**退出登陆 */
  logout: () => {
    return http.post<any, ResCommon>("user/login/out");
  },
  /**查询所有下级 */
  subUser: (page: number) => {
    return http.post<any, ResCommon>(`user/sub/user/${page}`);
  },
  /**设置支付必要信息 */
  setPayPassword: (data: ReqSetPayPassword) => {
    return http.post<any, ResCommon>("user/set/pwd", { data });
  },
  /**修改支付密码 */
  changePayPassword: (data: ReqChangePayPassword) => {
    return http.post<any, ResCommon>("user/change/pay/pwd", { data });
  },
  /**获取用户返现积分*/
  rewardScoreAccount: () => {
    return http.get<any, ResCommon>("reward-score-account");
  },
  /**获取用户返现积分列表*/
  rewardScoreAccountDetail: (data: any) => {
    return http.post<any, ResCommon>("reward-score-account-detail/list", {
      data
    });
  },
  /**
   * 提现
   * */
  withdraw: (data: ReqWithdraw) => {
    return http.post<any, ResCommon>("withdraw", { data });
  },
  /**
   * 提现列表
   * */
  withdrawList: (data: any) => {
    return http.post<any, ResCommon>("withdraw/list", { data });
  },
  /**
   * 购买后 获取数字人订单列表
   * */
  postDigitHumanList: (data: ReqCommonHistory) => {
    return http.post<any, ResCommon>("digital-human/list", { data });
  },
  /**
   * 购买后 获取数字人订单列表
   * */
  getVoiceList: (data: ReqCommonHistory) => {
    return http.post<any, ResCommon>("digital-human/list", { data });
  },
  /**
   * 当用户第二次购买的时候,获取用户名和邮箱回显,
   * 默认相同用户只需要第一次的时候填写用户信息,后面沿用之前的即可
   *  */
  getDigitHumanInfo: (id: number) => {
    return http.get<any, ResCommon>(`digital-human/${id}`);
  },
  /**
   * 用户购买之后通过该接口,更新用户资料,不同用户之前不能用户名和密码相同
   * ,该接口必须需要id,第一次创建时候购买的id会通过
   *  */
  postDigitRegisterInfo: (data: ReqRegisterInfo) => {
    return http.post<any, ResCommon>("digital-human/register/info", { data });
  },
  /**
   * 用户购买之后通过该接口,填写视频资料信息
   * ,该接口必须需要id,第一次创建时候购买的id会通过
   *  */
  postDigitRegisterVideoInfo: (data: ReqDigitHumanRegisterVideoInfo) => {
    return http.post<any, ResCommon>("digital-human/register/video/info", {
      data
    });
  },

  /**
   * 根据条件获取系统数字人列表信息
   */
  getSysVoiceList: (data: sysVoiceListType) => {
    return http.post<any, ResCommon>(`voice-clone/system`, {
      data
    });
  },
  /**
   * 获取用户数字人列表信息
   */
  getMyVoiceList: () => {
    return http.get<any, ResCommon>("voice-clone/customer");
  },
  /**
   * 获取网页端登录登录二维码
   */
  getLoginQr: (type: QrType) => {
    return http.get<any, ResLoginQr>(`user/wx/gz/qr/${type}`);
  },

  /**
   * 检查状态
   */
  checkGzLoginType: (hash: string) => {
    return http.get<any, ResCommon>(`user/check/gz/login/${hash}`);
  }
};

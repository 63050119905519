import { Constants, RouterNameEnum } from "@/config";
import { usercenter } from "../enums";
const Usercenter = () => import("@/views/usercenter/index.vue");
export default {
  path: "/usercenter",
  redirect: "/usercenter/index",
  meta: {
    title: "用户中心",
    rank: usercenter,
    roles: Constants.Roles.ALL,
    showLink: false
  },
  children: [
    {
      path: "/usercenter/index",
      name: RouterNameEnum.UserCenter,
      component: Usercenter,
      meta: {
        title: "用户中心",
        roles: Constants.Roles.ALL
      }
    }
  ]
} as RouteConfigsTable;

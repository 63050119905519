<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Constants } from "@/config";
import practicalGuide from "@/assets/svg/welcome/practical-guide.svg";
import education from "@/assets/svg/welcome/education.svg";
import schoolEnterpriseLinkage from "@/assets/svg/welcome/school-enterprise-linkage.svg";
import solution from "@/assets/svg/welcome/solution.svg";
import practicalGuideIcon from "@/assets/intelligence-study/two/practical-guide-icon.png";
import educationIcon from "@/assets/intelligence-study/two/education-icon.png";
import schoolEnterpriseLinkageIcon from "@/assets/intelligence-study/two/school-enterprise-linkage-icon.png";
import solutionIcon from "@/assets/intelligence-study/two/solution-icon.png";
import { deviceDetection } from "@pureadmin/utils";
import { useTranslationLang } from "@/layout/hooks/useTranslationLang";
import { getCurrentInstance } from "vue";
const { locale } = useTranslationLang();
const isMobile = deviceDetection();
const { t } = useI18n();
const { appContext } = getCurrentInstance();
const viewportHeight = appContext.config.globalProperties.$viewportHeight;
</script>

<template>
  <div
    :style="{ height: `${viewportHeight}px` }"
    :class="{ '!mt-[0.5rem]': isMobile && locale == Constants.Lang.en }"
    class="w-full flex flex-col items-center justify-center"
  >
    <div class="relative text-[1.8rem] lg:text-[2.8125rem] font-[OPPOSansB]">
      <div
        :class="{
          '!w-[80vw]': isMobile,
          '!text-[2rem] w-[90vw] lg:w-[98rem]':
            !isMobile && locale == Constants.Lang.en,
          '!text-[1.2rem] ': isMobile && locale == Constants.Lang.en
        }"
        class="flex flex-col lg:flex-row lg:justify-center"
      >
        <span>
          {{ t("firstPage.oneScreen.certification") }}
        </span>
        <div v-if="locale == Constants.Lang.en" class="mx-1">——</div>
        <span>
          {{ t("firstPage.oneScreen.certificationLine2") }}
        </span>
      </div>
    </div>
    <div
      v-if="!(isMobile && locale == Constants.Lang.en)"
      :class="{
        '!text-[0.7rem] !mt-[0vh]': isMobile && locale == Constants.Lang.en,
        '!mt-[1vh]': isMobile && locale !== Constants.Lang.en
      }"
      class="text-[#4E4E4E] w-[80vw] text-center text-[1.125rem] mt-[2vh] px-[0]"
    >
      {{ t("firstPage.oneScreen.cultivating") }}
    </div>
    <div
      :class="{
        '!mt-[1vh]': isMobile && locale == Constants.Lang.en,
        '!mt-[2vh]': isMobile && locale !== Constants.Lang.en
      }"
      class="flex w-full flex-wrap justify-center mt-[3vh] lg:mt-[10vh]"
    >
      <div
        :class="{
          'lg:py-[1rem]': !isMobile && locale == Constants.Lang.en,
          '!py-[1rem]': isMobile && locale == Constants.Lang.en
        }"
        class="mb-[1rem] py-[1rem] lg:py-[0rem] rounded-[1rem] w-[45%] lg:w-[17.92vw] lg:h-[17.92vw] box flex flex-col justify-start lg:justify-center items-center ml-[1rem] lg:ml-[2.2rem]"
      >
        <div
          v-if="!(isMobile && locale == Constants.Lang.en)"
          class="relative w-[2.5rem] h-[2.5rem] lg:w-[4.5rem] lg:h-[4.5rem] flex justify-center items-center"
        >
          <div class="absolute">
            <practicalGuide
              :class="{
                '!w-[3.5rem] !h-[3.5rem]':
                  !isMobile && locale == Constants.Lang.en
              }"
              class="w-[2.5rem] h-[2.5rem] lg:w-[4.5rem] lg:h-[4.5rem]"
            />
            <img
              class="absolute bottom-[-1rem] right-[-1rem] w-[2.875rem] h-[2.875rem]"
              :src="practicalGuideIcon"
            />
          </div>
        </div>
        <div
          :class="{
            '!text-[1rem]': !isMobile && locale == Constants.Lang.en,
            'text-[1rem] text-center': isMobile && locale == Constants.Lang.en
          }"
          class="mt-[5px] lg:mt-[2.5rem] text-[1.5rem] font-[OPPOSansM]"
        >
          {{ t("firstPage.oneScreen.practicePriented") }}
        </div>
        <div
          :class="{
            '!text-[1rem] ': !isMobile && locale == Constants.Lang.en,
            '!text-[0.8rem] !mt-[0rem]': isMobile && locale == Constants.Lang.en
          }"
          class="leading-[1.55rem] text-start font-[OPPOSansM] mt-[1.25rem] text-[1.125rem] text-[#8C8C8C] lg:text-center w-[80%] lg:w-[70.35%]"
        >
          {{ t("firstPage.oneScreen.national") }}
        </div>
      </div>
      <div
        :class="{
          'lg:py-[1rem]': !isMobile && locale == Constants.Lang.en,
          '!py-[1rem]': isMobile && locale == Constants.Lang.en
        }"
        class="mb-[1rem] py-[1rem] lg:py-[0rem] rounded-[1rem] w-[45%] lg:w-[17.92vw] lg:h-[17.92vw] box flex flex-col justify-start lg:justify-center items-center ml-[1rem] lg:ml-[2.2rem]"
      >
        <div
          v-if="!(isMobile && locale == Constants.Lang.en)"
          class="relative w-[2.5rem] h-[2.5rem] lg:w-[4.5rem] lg:h-[4.5rem] flex justify-center items-center"
        >
          <div class="absolute">
            <education
              :class="{
                '!w-[3.5rem] !h-[3.5rem]':
                  !isMobile && locale == Constants.Lang.en
              }"
              class="w-[2.5rem] h-[2.5rem] lg:w-[4.5rem] lg:h-[4.5rem]"
            />
            <img
              class="absolute bottom-[-1rem] right-[-1rem] w-[2.875rem] h-[2.875rem]"
              :src="educationIcon"
            />
          </div>
        </div>

        <div
          :class="{
            '!text-[1rem]': !isMobile && locale == Constants.Lang.en,
            'text-[1rem] text-center': isMobile && locale == Constants.Lang.en
          }"
          class="mt-[5px] lg:mt-[2.5rem] text-[1.5rem] font-[OPPOSansM]"
        >
          {{ t("firstPage.oneScreen.integration") }}
        </div>
        <div
          :class="{
            '!text-[1rem] ': !isMobile && locale == Constants.Lang.en,
            '!text-[0.8rem] !mt-[0rem]': isMobile && locale == Constants.Lang.en
          }"
          class="font-[OPPOSansM] leading-[1.55rem] mt-[1.125rem] text-[1.125rem] text-[#8C8C8C] text-start lg:text-center w-[80%] lg:w-[70.35%]"
        >
          {{ t("firstPage.oneScreen.universities") }}
        </div>
      </div>
      <div
        :class="{
          'lg:py-[1rem]': !isMobile && locale == Constants.Lang.en,
          '!py-[1rem]': isMobile && locale == Constants.Lang.en
        }"
        class="py-[1rem] lg:py-[0rem] rounded-[1rem] w-[45%] lg:w-[17.92vw] lg:h-[17.92vw] box flex flex-col justify-start lg:justify-center items-center ml-[1rem] lg:ml-[2.2rem]"
      >
        <div
          v-if="!(isMobile && locale == Constants.Lang.en)"
          class="relative w-[2.5rem] h-[2.5rem] lg:w-[4.5rem] lg:h-[4.5rem] flex justify-center items-center"
        >
          <div class="absolute">
            <schoolEnterpriseLinkage
              :class="{
                '!w-[3.5rem] !h-[3.5rem]':
                  !isMobile && locale == Constants.Lang.en
              }"
              class="w-[2.5rem] h-[2.5rem] lg:w-[4.5rem] lg:h-[4.5rem]"
            />
            <img
              class="absolute bottom-[-1rem] right-[-1rem] w-[2.875rem] h-[2.875rem]"
              :src="schoolEnterpriseLinkageIcon"
            />
          </div>
        </div>
        <div
          :class="{
            '!text-[1rem] ': !isMobile && locale == Constants.Lang.en,
            'text-[1rem] text-center': isMobile && locale == Constants.Lang.en
          }"
          class="mt-[5px] lg:mt-[2.5rem] text-[1.5rem] font-[OPPOSansM]"
        >
          {{ t("firstPage.oneScreen.school") }}
        </div>
        <div
          :class="{
            '!text-[0.9rem] ': !isMobile && locale == Constants.Lang.en,
            '!text-[0.8rem] !mt-[0rem]': isMobile && locale == Constants.Lang.en
          }"
          class="font-[OPPOSansM] leading-[1.55rem] mt-[1.125rem] text-[1.125rem] text-[#8C8C8C] text-start lg:text-center w-[80%] lg:w-[70.35%]"
        >
          {{ t("firstPage.oneScreen.technology") }}
        </div>
      </div>
      <div
        :class="{
          'lg:py-[1rem]': !isMobile && locale == Constants.Lang.en,
          '!py-[1rem]': isMobile && locale == Constants.Lang.en
        }"
        class="py-[1rem] lg:py-[0rem] rounded-[1rem] w-[45%] lg:w-[17.92vw] lg:h-[17.92vw] box flex flex-col justify-start lg:justify-center items-center ml-[1rem] lg:ml-[2.2rem]"
      >
        <div
          v-if="!(isMobile && locale == Constants.Lang.en)"
          class="relative w-[2.5rem] h-[2.5rem] lg:w-[3.5rem] lg:h-[4.125rem]"
        >
          <div class="absolute">
            <solution
              :class="{
                '!w-[3.5rem] !h-[3.5rem]':
                  !isMobile && locale == Constants.Lang.en
              }"
              class="w-[2.5rem] h-[2.5rem] lg:w-[3.5rem] lg:h-[4.125rem]"
            />
            <img
              class="absolute bottom-[-1rem] right-[-1rem] w-[2.875rem] h-[2.875rem]"
              :src="solutionIcon"
            />
          </div>
        </div>
        <div
          :class="{
            '!text-[1rem]': !isMobile && locale == Constants.Lang.en,
            'text-[1rem] text-center': isMobile && locale == Constants.Lang.en
          }"
          class="mt-[5px] lg:mt-[2.5rem] text-[1.5rem] font-[OPPOSansM]"
        >
          {{ t("firstPage.oneScreen.solution") }}
        </div>
        <div
          :class="{
            '!text-[1rem] ': !isMobile && locale == Constants.Lang.en,
            '!text-[0.8rem] !mt-[0rem]': isMobile && locale == Constants.Lang.en
          }"
          class="font-[OPPOSansM] leading-[1.55rem] mt-[1.125rem] text-[1.125rem] text-[#8C8C8C] text-start lg:text-center w-[80%] lg:w-[70.35%]"
        >
          {{ t("firstPage.oneScreen.provideIntegrated") }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.box {
  box-shadow: 0px 1px 20px 0px rgba(25, 120, 203, 0.1);
}
</style>

<script setup lang="ts">
import Sider from "./sider/index.vue";
import { useChatStore } from "@/store/modules/chat/index";
import { inject, onMounted, ref } from "vue";
import { AiTypeModelEnum } from "@/config";
import { ChatHelp } from "../utils/ChatHelp";
import { useAppStoreHook } from "@/store/modules/app";
import { useRouter } from "vue-router";
defineOptions({
  name: "ChatGPT"
});
const seniorChat = inject("seniorChat");
const router = useRouter();
const chatStore = useChatStore();
const mUid = Date.now();
const currentChatMember = ref();
const chatGPTRef = ref();
const saveModelTypeRef = ref<Chat.SaveModelType>();
const { getCategory } = useAppStoreHook();
saveModelTypeRef.value = ChatHelp.get();

const checkChat = async () => {
  // 如果当前路由已经有 uuid 参数，说明不需要再导航
  if (getCategory()?.isCreate) {
    const chatHistory = chatStore.findBySystemId(getCategory().systemId);
    if (chatHistory) {
      if (chatStore.active) {
        chatStore.updateHistory(chatStore.active, { isEdit: false });
      }
      await chatStore.setActive(chatHistory.uuid);
    } else {
      chatStore.addHistory(
        {
          title: "New Chat",
          uuid: mUid,
          isEdit: false,
          systemId: getCategory().systemId,
          systemTitle: getCategory().firstPrompt
        },
        []
      );
    }
    getCategory().isCreate = false;
  }
  router.replace({
    name: "Chat",
    params: { uuid: chatStore.active }
  });
};

onMounted(() => {
  checkChat();
  router.beforeEach((to, from, next) => {
    if (
      to.fullPath == "/chatai" &&
      !from.fullPath.includes("digital-workbench") &&
      from.fullPath != "/chatai-category"
    ) {
      console.log("再返回一次");
      window.history.go(-1);
    } else {
      console.log("正常返回");
      next();
    }
  });
});

const handleClearConversation = () => {
  console.log("handleClearConversation");
  chatGPTRef.value.handleClear();
};
const handleExportConversation = () => {
  console.log("handleExportConversation");
  chatGPTRef.value.handleExport();
};
//触发父组建的hadelChoseModel方法，实时更新localstorage中的choseModel.
const handlechangeModel = () => {
  saveModelTypeRef.value = ChatHelp.get();
  chatGPTRef.value.hadelChoseModel();
};
</script>

<template>
  <div class="relative flex h-full">
    <!-- 侧边栏 -->
    <Sider
      v-if="seniorChat"
      :type="AiTypeModelEnum.CHAT"
      :currentChatMember="currentChatMember"
      :saveModelType="saveModelTypeRef"
      @clearConversation="handleClearConversation"
      @exportConversation="handleExportConversation"
      @changeModel="handlechangeModel"
    />
    <!-- @changeModel="handlechangeModel" -->
    <!-- 问答区域 -->
    <div class="w-full h-full mx-auto">
      <!-- <router-view /> -->
      <router-view>
        <template #default="{ Component, route }">
          <component :is="Component" :key="route.fullPath" ref="chatGPTRef" />
        </template>
      </router-view>
    </div>
  </div>
</template>

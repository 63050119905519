import { ss } from "@/utils";

const LOCAL_NAME = "appSetting";

export type Theme = "light" | "dark" | "auto";

export type Language = "zh-CN" | "zh-TW" | "en-US";

export interface AppState {
  siderCollapsed: boolean;
  theme: Theme;
  language: Language;
}

// 默认语言版本设置，根据浏览器环境进行判断（用户可以手动调整）
export function defaultSetting(): AppState {
  return { siderCollapsed: false, theme: "light", language: "zh-CN" };
}

export function getLocalSetting(): AppState {
  const localSetting: AppState | undefined = ss.get(LOCAL_NAME);
  return { ...defaultSetting(), ...localSetting };
}

export function setLocalSetting(setting: AppState): void {
  ss.set(LOCAL_NAME, setting);
}

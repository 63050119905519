<script setup lang="ts">
import { onMounted, ref } from "vue";
import { CommonListData, CommonPagenationData } from "@/api/types";

const props = defineProps({
  defaultCall: {
    type: Boolean,
    default: true
  }
});
// 总记录
const mTableList = ref([]);
const conmmonListComponetsRef = ref();
const paginationData = ref<CommonPagenationData>();
const getPage = (isInit?: boolean) => {
  if (isInit || !paginationData.value) {
    paginationData.value = {
      page: 1,
      size: 20,
      total: 0
    };
  }
  return paginationData.value;
};

getPage();

const setDataToPage = (data: CommonListData) => {
  mTableList.value = data.list;
  paginationData.value.total = data.total;
  // paginationData.value.page = data.page;
};
// 默认会调用一次
const handleCurrentPageChange = (current: number) => {
  console.log("当前页", current);
  paginationData.value.page = current;
  emit("handleCurrentPageChange");
};
// 默认调用一次
onMounted(() => {
  if (props.defaultCall) {
    handleCurrentPageChange(paginationData.value.page);
  }
});
const emit = defineEmits(["handleCurrentPageChange"]);
defineExpose({ setDataToPage, getPage });
</script>

<template>
  <div class="w-full" ref="conmmonListComponetsRef">
    <el-card class="h-full">
      <slot class="mt-10" name="list-body" :mTableList="mTableList" />
    </el-card>
    <el-card class="mt-5">
      <div class="w-full flex justify-end">
        <el-pagination
          @current-change="handleCurrentPageChange(paginationData.page)"
          v-model:current-page="paginationData.page"
          background
          layout="prev, pager, next"
          :total="paginationData.total"
          :page-size="paginationData.size"
        />
      </div>
    </el-card>
  </div>
</template>

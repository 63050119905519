<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { computed, watch } from "vue";
import List from "./List.vue";
// import Footer from "./Footer.vue";
import { useBasicLayout } from "@/layout/hooks/useBasicLayout";
import { useAppStore } from "@/store/modules/app/index";
import { useChatStore } from "@/store/modules/chat/index";
import { useDataThemeChange } from "@/layout/hooks/useDataThemeChange";
import { SvgIcon } from "@/components";
import { Constants, LocalStorageKeyEnum, RouterNameEnum } from "@/config";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { ChatHelp } from "../../utils/ChatHelp";

const { t } = useI18n();
const router = useRouter();
const { dataTheme, dataThemeChange } = useDataThemeChange();
const appStore = useAppStore();
const chatStore = useChatStore();
const { isMobile } = useBasicLayout();
// const modelList = ref<Array<Chat.AiModelType>>();
// const selectedOption = ref<Chat.AiModelType>();

// const chooseModelLocal = localStorage.getItem(LocalStorageKeyEnum.CHOOSE_MODEL);
// if (chooseModelLocal) {
//   selectedOption.value = CommonUtils.getJSONObj(chooseModelLocal);
// }

// 定义传入的 props，并进行类型验证
// 使用 defineProps 定义 props
// const props = defineProps<{
//   currentChatMember: Object;
//   saveModelType?: Chat.SaveModelType;
// }>();
const saveModelTypeRef = ref(ChatHelp.get());
// ref<Chat.SaveModelType>(props.saveModelType);

const collapsed = computed(() => appStore.siderCollapsed);
// 控制首次进入页面时不执行slide-out动画
const firstLoad = ref(true);
function handleAdd() {
  chatStore.addHistory({ title: "New Chat", uuid: Date.now(), isEdit: false });
}

function handleUpdateCollapsed() {
  firstLoad.value = false;
  appStore.setSiderCollapsed(!collapsed.value);
}
const mobileSafeArea = computed(() => {
  if (isMobile.value) {
    return {
      paddingBottom: "env(safe-area-inset-bottom)"
    };
  }
  return {};
});

watch(
  isMobile,
  val => {
    appStore.setSiderCollapsed(val);
  },
  {
    immediate: true,
    flush: "post"
  }
);
watch(collapsed, () => {
  if (collapsed.value === false) {
    firstLoad.value = false;
  }
});
//获取数据模型列表
// const getChatgptModel = async () => {
//   const { data } = await chatAndAgentApi.getChatModels();
//   modelList.value = data;
//   if (!selectedOption.value && modelList?.value?.length > 0) {
//     selectedOption.value = modelList.value[0];
//   }
// };
//改变模型方式
const changeModelItem = (item: Chat.AiModelType) => {
  saveModelTypeRef.value = ChatHelp.saveByKey(
    Constants.ChatObjKey.selectModel,
    item
  );
  emit("changeModel");
};

const handleSwitchLightDark = () => {
  dataTheme.value = !dataTheme.value;
  // dataTheme.value = isDark;
  dataThemeChange();
};
// const handleGoToBuy = () => {
//   emitter.emit(
//     "openPayBusinessTypeDialog",
//     Constants.PayBusinessType.OPEN_CHAT
//   );
// };
const handleClearConversation = () => {
  emit("clearConversation");
};
const handleExportConversation = () => {
  emit("exportConversation");
};
const handleBack = () => {
  if (ChatHelp.getAgentOrChatType() == LocalStorageKeyEnum.AGENT_CHOOSE_MODEL) {
    router.push({ name: RouterNameEnum.DigitalWorkbench });
  } else {
    router.push({ name: RouterNameEnum.ChatAICategory });
  }
};

const emit = defineEmits([
  "buyChatGPT",
  "clearConversation",
  "exportConversation",
  "changeModel"
]);
onMounted(() => {
  // 切换暗黑模式
  // handleSwitchLightDark(true);
  // 切换到紫罗兰主题
  // setLayoutThemeColor("saucePurple");
  // 获取模型列表
  // getChatgptModel();
});
onUnmounted(() => {
  dataTheme.value = false;
  dataThemeChange();
});
</script>

<template>
  <div
    :style="mobileSafeArea"
    class="flex flex-col h-full w-[260px] absolute -translate-x-[100%] lg:-translate-x-0 lg:static z-40 bg-[#E4EDF5] dark:bg-[#020308] border-r border-gray-300 dark:border-none"
    :class="{
      'slide-in': !firstLoad && !collapsed && isMobile,
      'slide-out': !firstLoad && collapsed && isMobile
    }"
  >
    <!-- max-w-xs -->
    <div class="fixed inset-0 z-40 flex">
      <div class="relative flex flex-col flex-1 w-full translate-x-0">
        <div class="flex items-start flex-1 w-full h-full border-white/20">
          <nav
            class="flex flex-col flex-1 w-full h-full p-2 space-y-1 chat-sider-footer-container"
          >
            <div
              class="flex flex-row hover:cursor-pointer py-[0.5rem] lg:py-[1rem] text-[#7285b4] dark:text-white text-[14px] hover:text-primary dark:hover:text-primary"
              @click="handleBack"
            >
              <el-button
                ><IconifyIconOnline icon="ri:arrow-left-fill" />{{
                  t("login.back")
                }}</el-button
              >
            </div>
            <el-button @click="handleAdd"
              ><SvgIcon icon="ri:add-fill" class="mr-2" />
              {{ t("chat.newConversation") }}
            </el-button>
            <List />
            <!-- 选择模型模式 -->
            <div
              v-if="saveModelTypeRef.modelAndTipsData?.model?.length > 1"
              class="hover:cursor-pointer flex justify-center"
            >
              <el-dropdown class="w-full">
                <el-button type="primary" class="w-full">
                  {{ t("chatAICategory.currentModel")
                  }}{{ saveModelTypeRef.selectModel.key }}
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="(item, index) in saveModelTypeRef.modelAndTipsData
                        .model"
                      :key="index"
                      :command="item.value"
                      :class="{
                        'bg-slate-300':
                          saveModelTypeRef.selectModel.value === item.value
                      }"
                      @click="changeModelItem(item)"
                    >
                      {{ item.key }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <el-button @click="handleClearConversation"
              ><SvgIcon icon="ri:delete-bin-line" class="mr-2" />{{
                t("chat.clearChat")
              }}</el-button
            >
            <el-button @click="handleExportConversation"
              ><SvgIcon icon="ri:download-2-line" class="mr-2" />{{
                t("chat.exportChat")
              }}</el-button
            >
            <el-button @click="handleSwitchLightDark()">
              <SvgIcon icon="ri:moon-line" v-show="!dataTheme" class="mr-2" />
              <SvgIcon icon="ri:sun-line" v-show="dataTheme" class="mr-2" />{{
                dataTheme ? t("chat.dayTheme") : t("chat.nightTheme")
              }}</el-button
            >
            <!-- <el-button @click="handleGoToBuy">
              <SvgIcon icon="ri:shopping-cart-2-line" class="mr-2" />
              {{ t("chat.subscribeMember") }}
            </el-button> -->
          </nav>
        </div>
      </div>
      <!-- <div class="flex-shrink-0 w-14" /> -->
    </div>
  </div>
  <template v-if="isMobile">
    <div
      v-show="!collapsed"
      class="fixed inset-0 z-30 bg-black/40"
      @click="handleUpdateCollapsed"
    />
  </template>
</template>

<style lang="scss">
.chat-sider-footer-container {
  .el-button + .el-button {
    margin-left: 0 !important;
  }
}
</style>

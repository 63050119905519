import { Video } from "video-metadata-thumbnails";
export const ResourceUtils = {
  calcImageWH(url: string): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      // 设置图片的来源
      image.src = url;
      // 当图片加载完成后，获取宽度和高度
      image.onload = function () {
        const width = image.width;
        const height = image.height;
        console.log(`图片宽度: ${width}`);
        console.log(`图片高度: ${height}`);
        resolve({ width, height });
      };
      image.onerror = function () {
        reject(null);
      };
    });
  },
  scaleImage(
    originalWidth: number,
    originalHeight: number,
    maxWidth: number,
    maxHeight: number,
    scaleMode: number,
    hWidth: number
  ): { width: number; height: number; x: number; y: number } {
    // 计算宽度和高度比例
    const widthRatio = maxWidth / scaleMode / originalWidth;
    const heightRatio = maxHeight / scaleMode / originalHeight;

    // 选择较小的缩放比例，以确保缩放后的尺寸不会超过最大尺寸的一半
    const scaleRatio = Math.min(widthRatio, heightRatio);
    console.log("scaleImage的缩放比例", scaleRatio);
    // 计算缩放后的宽度和高度
    const scaledWidth = Math.round(originalWidth * scaleRatio);
    const scaledHeight = Math.round(originalHeight * scaleRatio);
    // 计算xy,居中展示
    const x = Math.round(((hWidth > 0 ? hWidth : maxWidth) - scaledWidth) / 2);
    const y = Math.round((maxHeight - scaledHeight) / 2);
    return { width: scaledWidth, height: scaledHeight, x: x, y: y };
  },
  calcXy(
    originalWidth: number,
    originalHeight: number,
    maxWidth: number,
    maxHeight: number
  ): { x: number; y: number } {
    // 计算xy,居中展示
    const x = Math.round((maxWidth - originalWidth) / 2);
    const y = Math.round((maxHeight - originalHeight) / 2);
    return { x: x, y: y };
  },
  /**
   * 根据图片的宽高 返回定义宽高的下标
   * @param width
   * @param height
   * @returns
   */
  calcImageWHID(width: number, height: number) {
    const ids = [4, 2, 0, 1, 3];
    const arr = [9 / 16, 3 / 4, 1, 4 / 3, 16 / 9];
    const radio = width / height;

    let tempRate = arr.length - 1;
    for (let count = 0; count < arr.length; count++) {
      if (radio <= arr[count]) {
        if (count == 0) {
          tempRate = 0;
        } else {
          if (radio - arr[count - 1] < arr[count] - radio) {
            tempRate = count - 1;
          } else {
            tempRate = count;
          }
        }
        break;
      }
    }
    // console.log("图片宽高比", radio, arr[tempRate], ids[tempRate]);
    return ids[tempRate];
  },
  /**
   * 根据 宽高和 颜色值 生成图片
   */
  generateImage(width: number, height: number, color: string): Promise<any> {
    return new Promise(resolve => {
      // 使用 Canvas API 生成图片
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, width, height);
      // 将 Canvas 转换为 Blob 数据
      canvas.toBlob(async blob => {
        // 使用 axios 发送 Blob 数据到服务器
        const formData = new FormData();
        formData.append("image", blob, "generated.jpg");
        console.log("生成的背景图片", blob);
        resolve(blob);
      }, "image/jpeg");
    });
  },
  /**添加纯色背景 */
  addBackgroundColorToBase64(base64Data, bgColor, callback) {
    // 创建一个新的Image对象
    const img = new Image();
    img.src = base64Data;

    img.onload = function () {
      // 创建一个Canvas元素
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");

      // 绘制纯色背景
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 绘制原始图像
      ctx.drawImage(img, 0, 0);

      // 获取新的Base64数据
      const newBase64Data = canvas.toDataURL();
      callback(newBase64Data);
    };
  },
  async calcVideoHW(url: string): Promise<{ width: number; height: number }> {
    const video = new Video(url);
    const metaData = await video.getMetadata();
    return { height: metaData.height, width: metaData.width };
  }
};

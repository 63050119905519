import { $t } from "@/plugins/i18n";
import { digitTwins } from "../enums";
import { Constants, RouterNameEnum } from "@/config";

export default {
  path: "/digit-twins",
  meta: {
    // icon: "HomeFilled",
    title: $t("menus.hshome"),
    rank: digitTwins
  },
  children: [
    {
      path: "/digit-twins",
      name: RouterNameEnum.DigitTwins,
      component: () => import("@/views/operate/DigitTwins.vue"),
      meta: {
        icon: "DigitTwins",
        title: $t("menus.digitTwins"),
        roles: Constants.Roles.ALL,
        showLink: false,
        saveScrollTop: true
      }
    }
  ]
} as RouteConfigsTable;

import { Constants, RouterNameEnum } from "@/config";
import { news } from "../enums";
const News = () => import("@/views/news/index.vue");

export default {
  path: "/news",
  redirect: "/news/index",
  meta: {
    title: "新闻",
    rank: news,
    roles: Constants.Roles.ALL
  },
  children: [
    {
      path: "/news/index",
      name: RouterNameEnum.News,
      component: News,
      meta: {
        title: "新闻",
        saveScrollTop: true,
        showLink: false,
        roles: Constants.Roles.ALL
      }
    }
  ]
} as RouteConfigsTable;

import { $t } from "@/plugins/i18n";
import { about } from "../enums";
import { Constants, RouterNameEnum } from "@/config";

export default {
  path: "/create-work",
  redirect: "/create-work/index",
  meta: {
    title: $t("menus.aboutFutureAI"),
    rank: about,
    roles: Constants.Roles.ALL
  },
  children: [
    {
      path: "/create-work/index",
      component: () => import("@/views/create-work/index.vue"),
      name: RouterNameEnum.CreateWork,
      meta: {
        icon: "CreateWork",
        title: $t("menus.createWork"),
        // 通过设置showParent为true，显示父级
        showParent: true,
        showLink: false,
        roles: Constants.Roles.ALL
        // keepAlive: true
        // 删掉动画页面切换会有1s空白时间
        // transition: {
        //   enterTransition: "animate__fadeInRight",
        //   leaveTransition: "animate__fadeOutDown"
        // }
      }

      /** // ynamic Route Matching with Params
       * https://router.vuejs.org/guide/essentials/dynamic-matching.html
       */
      // children: [
      //   {
      //     path: "/operate/create-work/:sceneId",
      //     component: CreateWork
      //   }
      // ]
    }
  ]
} as RouteConfigsTable;

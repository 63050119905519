import { Constants, RoleEnum } from "@/config";
export const AuthUtils = {
  isAdminAll(role: string): boolean {
    return Constants.Roles.ADMIN_ALL.includes(role as RoleEnum);
  },

  isIncludeStaff(role: string): boolean {
    return Constants.Roles.ADMIN_STAFF.includes(role as RoleEnum);
  },

  isIncludePartner(role: string): boolean {
    return Constants.Roles.ADMIN_PARTNER.includes(role as RoleEnum);
  },

  isIncludeAdmin(role: string): boolean {
    return Constants.Roles.SUPER_ADMIN_ADMIN.includes(role as RoleEnum);
  },

  isSuperAdmin(role: string): boolean {
    return Constants.Roles.SuperAdmin.includes(role as RoleEnum);
  }
};

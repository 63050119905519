<script setup lang="ts">
import { useI18n } from "vue-i18n";
import boxbg from "@/assets/intelligence-study/one/bg.png";
import boxPeopleBg from "@/assets/intelligence-study/one/box-people-bg.png";
import boxPeopleBgPhone from "@/assets/intelligence-study/one/box-people-bg-phone.png";
import { useTranslationLang } from "@/layout/hooks/useTranslationLang";
import { deviceDetection } from "@pureadmin/utils";
import { Constants } from "@/config";

const { locale } = useTranslationLang();
const isMobile = deviceDetection();
const { t } = useI18n();
</script>

<template>
  <div class="relative w-full h-full flex">
    <!-- 人物大图 -->
    <img
      v-if="!isMobile"
      class="absolute bottom-0 right-0 object-cover h-[90.6vh]"
      :src="boxPeopleBg"
    />
    <img
      v-else
      class="absolute bottom-0 w-full object-cover"
      :src="boxPeopleBgPhone"
    />
    <!-- 背景图1 -->
    <img
      class="z-[-999] absolute top-[-19.5rem] left-[-8rem] lg:top-[-11.8rem] lg:left-[-5rem] w-[34.312rem] h-[34.312rem]"
      :src="boxbg"
    />
    <!-- 背景图2 -->
    <img
      class="z-[-999] absolute bottom-[-9rem] right-[-16rem] w-[34.312rem] h-[34.312rem]"
      :src="boxbg"
    />
    <div
      class="z-[99] flex flex-col h-full ml-[11.6vw] lg:justify-center mt-[9.5rem] lg:mt-[-1rem]"
    >
      <div
        :class="{
          'w-[50vw]': isMobile,
          'w-[70vw]': isMobile && locale == Constants.Lang.en
        }"
        class="text-[2rem] lg:text-[2.5rem] font-[OPPOSansB] leading-[2.7rem] lg:leading-[3.125rem]"
      >
        {{ t("firstPage.threeScreen.short_video_marketing") }}<br />{{
          t("firstPage.threeScreen.ai_digital_human_content")
        }}
      </div>
      <div
        :class="{ '': isMobile }"
        class="text-[1rem] lg:text-[1.25rem] leading-[1.7rem] lg:leading-[2.5rem] mt-[1rem] lg:mt-[4vh] text-[#3D3D3D]"
      >
        {{ t("firstPage.threeScreen.digital_human_customization") }}<br />{{
          t("firstPage.threeScreen.efficient_voice_over")
        }}
      </div>
    </div>
  </div>
</template>
<style scoped>
.btn {
  background: linear-gradient(
      193deg,
      #81daff -22%,
      var(--el-color-primary) 126%
    ),
    #ffffff;
}
.textbg {
  font-weight: 700;
  margin-left: 10px;
  border-color: var(--el-color-primary);
  /* 渐变蓝色 */
  font-variation-settings: "opsz" auto;
  background: linear-gradient(
    194deg,
    #81daff -16%,
    var(--el-color-primary) 119%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
</style>

<template>
  <!-- Config Provider is used for providing global configurations, 
    which enables your entire application to access these configurations everywhere. -->
  <el-config-provider :locale="currentLocale">
    <router-view />
  </el-config-provider>
</template>

<script lang="ts">
import { defineComponent, provide } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import zhTw from "element-plus/dist/locale/zh-tw.mjs";
import en from "element-plus/dist/locale/en.mjs";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { Lang } from "./config";
import { initlang } from "./utils/responsive";
export default defineComponent({
  name: "app",
  components: {
    [ElConfigProvider.name]: ElConfigProvider
  },
  setup() {
    provide("seniorChat", true);
  },
  created() {
    console.log(
      "useSystemConfigStoreHook().childDomain:",
      useSystemConfigStoreHook().childDomain
    );
    if (!useSystemConfigStoreHook().childDomain) {
      throw new Error("/error/404");
    }
  },
  computed: {
    currentLocale() {
      /** global.d.ts 里面定义的全局属性 */
      const lang = initlang();
      switch (lang) {
        case Lang.LAN_CN:
          return zhCn;
        case Lang.LAN_EN:
          return en;
        case Lang.LAN_TW:
          return zhTw;
        default:
          return zhCn;
      }
    }
  }
});
</script>

export default {
  path: "/empty",
  meta: {
    title: "空页面",
    rank: 103
  },
  children: [
    {
      path: "/empty",
      name: "Empty",
      component: () => import("@/views/empty/index.vue"),
      meta: {
        title: "空页面",
        showLink: false
      }
    }
  ]
} as RouteConfigsTable;

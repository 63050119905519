import { Constants, RouterNameEnum } from "@/config";
import { chatgpt } from "../enums";
const ChatGPT = () => import("@/views/chatgpt/index.vue");
import { ChatLayout } from "@/views/chatgpt/layout";
export default {
  path: "/chatai",
  component: ChatLayout,
  name: RouterNameEnum.ChatAI,
  meta: {
    title: "Chat AI",
    rank: chatgpt,
    notFlat: true,
    showLink: false,
    roles: Constants.Roles.ALL
  },
  children: [
    {
      path: "/chatai/:uuid?",
      name: RouterNameEnum.Chat,
      component: ChatGPT,
      meta: {
        title: "Chat AI",
        roles: Constants.Roles.ALL
      }
    }
  ]
} as RouteConfigsTable;

import Cookies from "js-cookie";
import { storageLocal } from "@pureadmin/utils";
import CryptoJS from "crypto-js";
import { useUserStoreHook } from "@/store/modules/user";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { ScoreType } from "@/store/modules/types";
import { userRolePermissionsType } from "@/api/types";
import { UserTypeEnum } from "@/config";
import { CommonUtils } from "./commonUtils";
//import { initRouter } from "@/router/utils";
// import { useRoute } from "vue-router";
// import router from "@/router";

export interface DataInfo<T> {
  /**用户id */
  id: number;
  /**昵称*/
  nickname: string;
  extra?: string;
  /**头像 */
  avatar: string;
  /**邀请码 */
  inviteCode: string;
  /** 用户名 */
  username: string;
  /** 当前登陆用户的角色 */
  roles: Array<string>;
  /** 当前登陆用户的角色 */
  role: string;
  type: UserTypeEnum;
  token?: T;
  isAgent: boolean;

  /**经销商权限 */
  agentPermissions: number;
  /**姓名 */
  realName: string;
  /**支付宝账户 */
  payAccount: string;
  permissions: number;
  /**上级用户名 */
  parentUsername?: string;
  agentId: number;
  changeAgentInfo: boolean;
  rolePermissions: Array<userRolePermissionsType>;
  agentRole: number;
  scoreDetail?: ScoreType; // 获取积分后持久化
}

export const sessionKey = "user-info";
export const TokenKey = "authorized-token";

/** 获取`token` */
export function getToken(): DataInfo<string> {
  // 此处与`TokenKey`相同，此写法解决初始化时`Cookies`中不存在`TokenKey`报错
  return Cookies.get(TokenKey)
    ? CommonUtils.getJSONObj(Cookies.get(TokenKey))
    : storageLocal().getItem(sessionKey);
}

/**
 * @description 设置`token`以及一些必要信息并采用无感刷新`token`方案
 * 无感刷新：后端返回`accessToken`（访问接口使用的`token`）、`refreshToken`（用于调用刷新`accessToken`的接口时所需的`token`，`refreshToken`的过期时间（比如30天）应大于`accessToken`的过期时间（比如2小时））、`expires`（`accessToken`的过期时间）
 * 将`accessToken`、`expires`这两条信息放在key值为authorized-token的cookie里（过期自动销毁）
 * 将`username`、`roles`、`refreshToken`、`expires`这四条信息放在key值为`user-info`的sessionStorage里（浏览器关闭自动销毁）
 */
export async function setToken(data: DataInfo<string>) {
  const { token } = data;
  const cookieString = CommonUtils.getJSON({ token });
  Cookies.set(TokenKey, cookieString);
  useUserStoreHook().SET_ID(data.id);
  useUserStoreHook().SET_AVATAR(data.avatar);
  useUserStoreHook().SET_NICKNAME(data.nickname);
  useUserStoreHook().SET_EXTRA(data.extra);
  useUserStoreHook().SET_USERNAME(data.username);
  useUserStoreHook().SET_INVITE_CODE(data.inviteCode);
  useUserStoreHook().SET_ROLES(data.roles);
  useUserStoreHook().SET_TYPE(data.type);
  useUserStoreHook().SET_ROLE(data.roles[0]);

  useUserStoreHook().SET_AGENT_PERMISSIONS(data.agentPermissions);
  useUserStoreHook().SET_IS_AGENT(data.isAgent);
  useUserStoreHook().SET_PERMISSIONS(data.permissions);
  useUserStoreHook().SET_PAYACCOUNT(data.payAccount);
  useUserStoreHook().SET_REALNAME(data.realName);
  useUserStoreHook().SET_PARENT_USERNAME(data.parentUsername);
  useUserStoreHook().SET_AGENT_ID(data.agentId);
  useUserStoreHook().SET_CHANGE_AGENT_INFO(data.changeAgentInfo);
  useUserStoreHook().SET_ROLE_PERMISSIONS(data.rolePermissions);
  useUserStoreHook().SET_ROLE_PERMISSIONS(data.rolePermissions);
  // if (data.scoreDetail) {
  //   useUserStoreHook().SET_SCORE_DETAIL(data.scoreDetail);
  // }
  useUserStoreHook().SET_IS_LOGIN(true);
  storageLocal().setItem(sessionKey, data);
  // 重新获取一下系统配置
  await useSystemConfigStoreHook().getSystemConfig();

  // const route = useRoute();
  // router.push("/");
  // 重新获取一下积分,如果不获取,切换账户的时候会是老的数据
  useUserStoreHook().getUserScore();
}

/** 删除`token`以及key值为`user-info`的session信息 */
export function removeToken() {
  Cookies.remove(TokenKey);
  // storageLocal().clear();
  storageLocal().removeItem(sessionKey);
}

/** 格式化token（jwt格式） */
export const formatToken = (token: string): string => {
  return "Bearer " + token;
};

/** 登陆密码和上传文件名加密 */
export const createSha256 = (data: string): string => {
  return CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex).substring(0, 18);
};

import App from "./App.vue";
import router from "./router";
import { setupStore } from "@/store";
import ElementPlus from "element-plus";
// 国际化插件
import { useI18n } from "@/plugins/i18n";
// build目录下的json全局配置文件
// 全局注册按钮级别权限组件
import { Auth } from "@/components/ReAuth";
import { getServerConfig, OnLineServiceStatusEnum } from "@/config";
// Directive 自定义指令
import { createApp, Directive } from "vue";
// 动画插件
import { MotionPlugin } from "@vueuse/motion";
import { injectResponsiveStorage } from "@/utils/responsive";
// 引入重置样式
import "./style/reset.scss";
// 导入公共样式
import "./style/index.scss";
// 一定要在main.ts中导入tailwind.css，防止vite每次hmr都会请求src/style/index.scss整体css文件导致热更新慢的问题
import "./style/tailwind.css";
import "element-plus/dist/index.css";
// 导入字体图标
import "./assets/iconfont/iconfont.js";
import "./assets/iconfont/iconfont.css";
import { setupAssets } from "./style/chat";
import { useUserStoreHook } from "@/store/modules/user";
import { IconifyIconOffline, IconifyIconOnline, FontIcon } from "@/components";
// 自定义指令
import * as directives from "@/directives";
import VueTippy from "vue-tippy";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { CommonUtils } from "./utils/commonUtils";
import ContactDialog from "@/views/components/dialog/ContactDialog.vue";
import DiscountCodeDialog from "@/views/components/dialog/DiscountCodeDialog.vue";
import ClickToSynchronizeDialog from "@/views/components/dialog/ClickToSynchronizeDialog.vue";
import SelectUserItem from "@/views/components/aitools/SelectUserItem.vue";
import { CommonAdminSearch } from "@/components/common";
import CopyItem from "@/views/components/aitools/CopyItem.vue";
import LastScreen from "@/views/welcome/LastScreen.vue";
import DiscountCode from "@/views/components/pay/DiscountCode.vue";
// import VConsole from "vconsole";

// const vConsole = new VConsole();
// if (
//   process.env.NODE_ENV === "development" ||
//   new URLSearchParams(window.location.search).has("debug")
// ) {
//   import("vconsole").then(VConsoleModule => {
//     const VConsole = VConsoleModule.default;
//     new VConsole();
//   });
// }
const { VITE_ONLINE_SERVICE_KEY, VITE_BAIDU_STATISTIC } = import.meta.env;

const app = createApp(App);
// 设置样式
setupAssets();
/**设置 自定义指令 */
Object.keys(directives).forEach(key => {
  app.directive(
    key,
    (directives as unknown as { [key: string]: Directive })[key]
  );
});
app.component("IconifyIconOffline", IconifyIconOffline);
app.component("IconifyIconOnline", IconifyIconOnline);
app.component("FontIcon", FontIcon);
app.component("ContactDialog", ContactDialog);
app.component("DiscountCodeDialog", DiscountCodeDialog);
app.component("ClickToSynchronizeDialog", ClickToSynchronizeDialog);
app.component("SelectUserItem", SelectUserItem);
app.component("CommonAdminSearch", CommonAdminSearch);
app.component("CopyItem", CopyItem);
app.component("LastScreen", LastScreen);
app.component("DiscountCode", DiscountCode);
// 获取浏览器的可视高度并设置为全局属性
app.config.globalProperties.$viewportHeight = window.innerHeight;
app.config.globalProperties.$config = {
  seniorChat: false
};

app.component("Auth", Auth);

const config = getServerConfig(app);
/**获取系统配置信息 */
const initSiteConfig = async () => {
  await useSystemConfigStoreHook().getSystemConfig();
  // 设置Title ,Theme,
  config.Title = useSystemConfigStoreHook().config.sysConfig.appName;
  config.Theme = useSystemConfigStoreHook().config.sysConfig.theme;
  changeFavicon(useSystemConfigStoreHook().config.sourceImg?.logoUrl);
  if (
    useSystemConfigStoreHook().config.sysConfig.onLineServiceStatus !=
    OnLineServiceStatusEnum.CLOSE
  ) {
    setOnlineService();
  }
};

/* 动态改变favicon */
const changeFavicon = faviconUrl => {
  // console.log("favicon", faviconUrl);
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link["type"] = "image/x-icon";
  link["rel"] = "shortcut icon";
  link["href"] = faviconUrl;
  document.getElementsByTagName("head")[0].appendChild(link);
};

/**动态设置在线客服 key */
const setOnlineService = () => {
  // 在线客服

  // 确保在 AlimeDialog 加载后调用
  if (typeof window.AlimeDialog === "function") {
    window.AlimeDialog({
      from: import.meta.env.VITE_ONLINE_SERVICE_KEY
    });
  } else {
    console.error("window.AlimeDialog is not a function");
  }

  // 创建一个观察者实例并传入回调函数
  const observer = new MutationObserver((mutations, obs) => {
    const div = document.getElementById("J_xiaomi_dialog");
    if (div) {
      console.log("div:", div);
      div.style.right = "90px";
      // 找到元素后停止观察
      obs.disconnect();
    }
  });
  // 配置观察选项:
  const config = { childList: true, subtree: true };
  // 选择目标节点并开始观察
  observer.observe(document.body, config);
  // 设置超时时间，例如10秒
  setTimeout(() => {
    observer.disconnect(); // 超时后停止观察
    console.log("Timeout: Stopped observing due to no changes detected.");
  }, 2000); // 2秒
};

/**设置百度统计 */
const setBaiduStatistic = () => {
  window._hmt = window._hmt || [];
  (function () {
    const hm = document.createElement("script");
    hm.src = `https://hm.baidu.com/hm.js?${VITE_BAIDU_STATISTIC}`;
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
};

// 处理404错误
app.config.errorHandler = error => {
  console.log("error:", error);
  let message;
  if (error) {
    message = error["message"];
  }
  if (message == "/error/404") {
    router.push({ path: message });
  }
};

const initApp = async () => {
  app.use(router);
  // 等待 async enter hooks and async components 完成
  await router.isReady();
  await initSiteConfig();

  setBaiduStatistic();
  // 初始化响应式布局
  injectResponsiveStorage(app, config);
  // 初始化状态管理
  setupStore(app);
  app.use(MotionPlugin).use(useI18n).use(ElementPlus);
  app.use(VueTippy);
  app.mount("#app");

  const userInfo = CommonUtils.getUserInfo();
  if (userInfo) {
    useUserStoreHook().getUserScore();
  }
};
initApp();

export default app;

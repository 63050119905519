import {
  CategoryEnum,
  certificateRegistEnum,
  CurrencysEnum,
  DemoGroupEnum,
  DigitStatusResult,
  DiscountStatusEnum,
  IsThirdServiceEnum,
  LanguageVideoEnum,
  MainRouterEnum,
  PermissionsTypeEnum,
  RoleEnum,
  SmallBannerEnumOptionsEnum,
  SwiperSortingOptionsEnum,
  WxMenuTypeEnum,
  WxXmlTypeEnum
} from "./constantsEnum";
import { Code } from "./error-code";
// 短视频数字人
import digitNewUser from "@/assets/svg/digit-human-logo/digit/digit-human-logo-newuser.svg";
import digitLite from "@/assets/svg/digit-human-logo/digit/digit-human-logo-lite.svg";
import digitPro from "@/assets/svg/digit-human-logo/digit/digit-human-logo-pro.svg";
import digitEnterprise from "@/assets/svg/digit-human-logo/digit/digit-human-logo-digit-enterprise.svg";
import digitEnterpriseActive from "@/assets/svg/digit-human-logo/digit/digit-human-logo-digit-enterprise-active.svg";
import digitNewUserActive from "@/assets/svg/digit-human-logo/digit/digit-human-logo-newuser-active.svg";
import digitLiteActive from "@/assets/svg/digit-human-logo/digit/digit-human-logo-lite-active.svg";
import digitProActive from "@/assets/svg/digit-human-logo/digit/digit-human-logo-pro-active.svg";
//交互数字人
import interactiveDigitalBeginnner from "@/assets/svg/digit-human-logo/interactive-digital/interactive-digital-beginnner-trial-version.svg";
import interactiveDigitalLite from "@/assets/svg/digit-human-logo/interactive-digital/interactive-digital-lite-version.svg";
import interactiveDigitalPro from "@/assets/svg/digit-human-logo/interactive-digital/interactive-digital-pro-version.svg";
import interactiveDigitalBeginnnerActive from "@/assets/svg/digit-human-logo/interactive-digital/interactive-digital-beginnner-trial-version-active.svg";
import interactiveDigitalLiteActive from "@/assets/svg/digit-human-logo/interactive-digital/interactive-digital-lite-version-active.svg";
import interactiveDigitalProActive from "@/assets/svg/digit-human-logo/interactive-digital/interactive-digital-pro-version-active.svg";
//直播数字人
import liveDigitalBeginner from "@/assets/svg/digit-human-logo/live-digital-human/live-digital-beginner-trial-version.svg";
import liveDigitalLite from "@/assets/svg/digit-human-logo/live-digital-human/live-digital-live-streaming-streaming-lite-version.svg";
import liveDigitalPro from "@/assets/svg/digit-human-logo/live-digital-human/live-digital-live-streaming-pro-version.svg";
import liveDigitalBeginnerActive from "@/assets/svg/digit-human-logo/live-digital-human/live-digital-beginner-trial-version-active.svg";
import liveDigitalLiteActive from "@/assets/svg/digit-human-logo/live-digital-human/live-digital-live-streaming-streaming-lite-version-active.svg";
import liveDigitalProActive from "@/assets/svg/digit-human-logo/live-digital-human/live-digital-live-streaming-pro-version-active.svg";
//声音定制
import voiceCustomizationBeginner from "@/assets/svg/digit-human-logo/voice-customization/voice-customization-beginner-trial-version.svg";
import voiceCustomizationLite from "@/assets/svg/digit-human-logo/voice-customization/voice-customization-lite.svg";
import voiceCustomizationBeginnerActive from "@/assets/svg/digit-human-logo/voice-customization/voice-customization-beginner-trial-version-active.svg";
import voiceCustomizationLiteActive from "@/assets/svg/digit-human-logo/voice-customization/voice-customization-lite-active.svg";
// ai自动播
import aiAutoPlay from "@/assets/svg/digit-human-logo/ai-auto-play/ai-auto-play-logo.svg";
import aiAutoPlayActive from "@/assets/svg/digit-human-logo/ai-auto-play/ai-auto-play-logo-active.svg";
/**
 * 全局常量 命名风格:首字母大写驼峰式
 */
const Constants = {
  Common: {
    DeleteLock: "deleteLock", // 删除锁
    NewsTime: "newsTime",
    CreateMode: "createMode",
    CloseNewDoalog: "closeNewDoalog",
    videoSlider: 5,
    noVideoSlider: 4,
    currentProductLength: 3,
    freeDigital: 0,
    NewsManagerDemoVideoFileSize: 10,
    parentRouterId: -1, //一级路由的父路由默认值-1
    menuWidowWidth: 1551,
    routeChildWidth: 4,
    videoTranslationFileSize: 500, //视频翻译的文件大小限制为500M
    chooseCozeModel: {
      key: "coze",
      value: "coze"
    },
    cozeSystemId: 13,
    shortcuts: [
      {
        text: "最近一周",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        }
      },
      {
        text: "最近一个月",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        }
      },
      {
        text: "最近三个月",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        }
      }
    ]
  },
  PalPay: {
    devClient: {
      clientId:
        "AVqHOSUSNZMbaUfZIUdPcWWuKH-yJOTRJeI_mKBPNnzp8MOSSQtsORsOVUok2oHW4c3s0WUwkJEZehm5"
    },
    prodClient: {
      clientId:
        "AZSAy1R1RkMt2udcFMnce2af3eaqOAMwhKqvsQN9EmGWzVE7zYKfCHlH0Bj4QOqLxL4-tNjKMvz2UAuq"
    }
  },
  ChatObjKey: {
    selectModel: "selectModel", // 选中的模型
    selectTip: "selectTip", // 选中的提示
    systemId: "systemId", // 选中的提示
    modelAndTipsData: "modelAndTipsData"
  },
  env: {
    dev: "development",
    prod: "production"
  },
  UploadFileLimit: {
    AudioClone: 5
  },

  //添加折扣码时候设置状态
  selectCodeState: [
    {
      label: "成功",
      value: 1
    },
    {
      label: "拒绝",
      value: 2
    }
  ],
  TableIndex: {
    One: "first", //Constants.TableIndex.One
    Two: "second", //Constants.TableIndex.Two
    Three: "third", //Constants.TableIndex.Three
    Four: "fourth" //Constants.TableIndex.Four
  },
  SecondTableIndex: {
    One: "one", //Constants.SecondTableIndex.One
    Two: "two", //Constants.SecondTableIndex.Two
    Three: "three", //Constants.SecondTableIndex.Three
    Four: "four" //Constants.SecondTableIndex.Four
  },
  // 是否激活
  DigitActivityStatusList: [
    {
      key: "所有",
      value: DigitStatusResult.ALL
    },
    {
      key: "激活",
      value: DigitStatusResult.SUCCESS
    },
    {
      key: "未激活",
      value: DigitStatusResult.PENDING
    }
  ],
  // 是否开通
  DigitEnableStatusList: [
    {
      key: "所有",
      value: DigitStatusResult.ALL
    },
    {
      key: "已开通",
      value: DigitStatusResult.SUCCESS
    },
    {
      key: "未开通",
      value: DigitStatusResult.PENDING
    }
  ],

  // 是否可用
  DigitTimeoutList: [
    {
      key: "所有",
      value: DigitStatusResult.ALL
    },
    {
      key: "正常",
      value: DigitStatusResult.SUCCESS
    },
    {
      key: "失效",
      value: DigitStatusResult.PENDING
    }
  ],
  // 是否可用
  IsThridService: [
    {
      key: "三方",
      value: IsThirdServiceEnum.ThirdService
    },
    {
      key: "平台",
      value: IsThirdServiceEnum.PLATFORM
    },
    {
      key: "所有",
      value: IsThirdServiceEnum.ALL
    }
  ],
  //折扣码申请表折扣码状态枚举
  DiscountStatusList: [
    {
      key: "待处理",
      value: DiscountStatusEnum.DISCOUNT_AWAIT
    },
    {
      key: "拒绝",
      value: DiscountStatusEnum.DISCOUNT_CANCEL
    },
    {
      key: "已激活",
      value: DiscountStatusEnum.DISCOUNT_ACTIVE
    },
    {
      key: "所有",
      value: DiscountStatusEnum.DISCOUNT_ALL
    }
  ],
  // 图片方向
  Orientation: {
    // 纵向
    Vertical: 1,
    // 横向
    Horizontal: 2
  }, // 图片方向
  OrientationDesc: {
    // 纵向
    Vertical: "vertical",
    // 横向
    Horizontal: "horizontal"
  },
  ResourceType: {
    Bg: 0,// 公模北京
    Model: 1,// 模特
    Img: 2, // ai工具图片
    Video: 3, // 视频
    Srt: 4
  },
  /**
   * 硅基api 素材类型 '1'-视频素材 '2'-图片素材
   * "3" 自己添加的字幕表示
   *  */
  GuiJiNodeType: {
    Video: "1",
    Img: "2",
    Srt: "3"
  },
  ButtonAction: {
    ADD: "add",
    EDIT: "edit"
  },
  digitOrderConnact: [
    {
      label: "姓名:"
    },
    {
      label: "邮箱:"
    },
    {
      label: "电话:"
    }
  ],
  lagerScreenTime: {
    serviceTime: 60,
    maxLiveTime: 1800
  },
  sizeUncropOptions: [
    {
      label: "自定义",
      value: {
        width: 1200,
        height: 700
      }
    },
    {
      label: "横向",
      value: {
        width: 889,
        height: 500
      }
    },
    {
      label: "竖向",
      value: {
        width: 332,
        height: 590
      }
    },
    {
      label: "正方形",
      value: {
        width: 500,
        height: 500
      }
    }
  ],
  BusinessType: {
    // 系统背景图
    SystemBg: 1,
    // 用户背景
    Img: 2,
    // 用户音频
    Audio: 3,
    // 用户训练视频
    Video: 4,
    // 合成的视频 后端使用
    UserTraining: 5,
    // 用户头像
    UserAvatar: 6,
    // 系统图片
    SystemImg: 7,
    // 系统视频
    SystemVideo: 8,
    // 系统配置图片
    WebImg: 9,
    // 系统配置视频
    WebVideo: 10,
    largeScreeApk: 11, //大屏apk
    certificateRegistPdf: 12, //证书注册PDF
    certificateRegist3D: 13 //证书注册3D
  },
  CompositeType: {
    // 1 录音,2 文本
    Audio: 1,
    Text: 2
  },
  // 视频合成任务 或音频合成任务
  CompositionStatus: {
    // 失败
    Fail: 2,
    // 成功
    Success: 1,
    // 合成中
    Pending: 0
  },
  MediaType: {
    JPG: "image/jpeg",
    GIF: "image/gif",
    PNG: "image/png",
    MP4: "video/mp4",
    MOV: "video/quicktime",
    MP3: "audio/mpeg",
    WAV: "audio/wav",
    M4A: "audio/x-m4a",
    FLAC: "audio/flac"
  },
  UploadFileType: {
    Audio: "audio",
    Video: "video",
    Img: "img",
    apk: "apk",
    pdf: "pdf",
    "3D": "3D"
  },
  UploadFileTypeUser: {
    Audio: "userAudio",
    Video: "userVideo",
    Img: "userImg",
    UserWeb: "userWeb",
    userTraining: "userTraining"
  },
  Tip: {
    Buy: 1,
    Email: 2
  },
  // 数字人模特类型
  DigitHumanModelType: {
    // 免费
    System: 1,
    // 定制
    Training: 2
  },
  // 模特状态
  ModelOrigin: {
    // 公有
    Public: 1,
    // 私有
    private: 2
  },
  TableLoadingSvg: `<path class="path" d=" M 30 15 L 28 17 M 25.61 25.61 A 15 15, 0, 0, 1, 15 30 A 15 15, 0, 1, 1, 27.99 7.5 L 15 15 " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>`,
  ChatGPT: {
    MessageType: {
      START: 1, // 开始传输数据
      END: 2, // 结束传输数据
      USER_END: 3, // 用户主动停止传输
      DATA: 4, // 发送数据
      ERROR: 5 //错误
    },
    MemberType: {
      Count: 0, //次卡
      Week: 1, // 周卡
      Month: 2, // 月卡
      Quarter: 3, // 季卡
      HalfYear: 4, //半年卡
      Year: 5, //年卡
      Final: 6 //终生
    },
    ScoreRechargeChannelType: {
      Admin: 1, // 管理员
      WX: 2, // 微信
      INVITE_REWARD: 3 // 邀请奖励
    },
    SystemId: {
      // 万能提问
      Common: 1
    },
    OptimizeOption: {
      1: "把以下文字优化的更短一些：",
      2: "把以下文字优化的更长一些：",
      3: "把以下文字优化的更专业一些：",
      4: "把以下文字优化的更吸引人一些：",
      5: "把以下文字修正一下：",
      6: "把以下文字重新生成一份："
    }
  },
  WxLogin: {
    // 微信网页登陆
    WxWeb: 1,
    // 微信服务号（一键登录）
    WxService: 2
  },

  /**
   * 模型ID
   */
  ImageAIModelTypeId: {
    StableDiffusion: 0,
    Midjourney: 1
  },
  /**
   *  midjourney 绘画状态
   */
  DrawImgStatus: {
    // 队列满了,等待加入队列
    Waiting: -2,
    // 已经提交,等待回调
    WaitingBack: -1,
    // 任务正在执行
    Pending: 0,
    Success: 1,
    Fail: 2
  },
  WebSocketStatus: {
    Open: "OPEN",
    Connecting: "CONNECTING",
    Closed: "CLOSED"
  },
  WebSocketChannel: {
    ChatAI: "chat_ai",
    MidDraw: "midDraw"
  },
  DialogActionType: {
    CREATE: "创建",
    UPDATE: "更新"
  },
  MidButtonType: {
    u1: "u1",
    u2: "u2",
    u3: "u3",
    u4: "u4",
    v1: "v1",
    v2: "v2",
    v3: "v3",
    v4: "v4",
    draw: "draw",
    r: "r"
  },
  MidButtonTypeDesc: {
    u1: "左上放大",
    u2: "右上放大",
    u3: "左下放大",
    u4: "右下放大",
    v1: "左上变换",
    v2: "右上变换",
    v3: "左下变换",
    v4: "右下变换",
    draw: "原始图片",
    r: "重绘这张"
  },
  MidUButtonType: ["u1", "u2", "u3", "u4"],
  // 如果parent不是u1到u4的话可以操作以下,
  MDrawAction: ["u1", "u2", "u3", "u4", "r", "v1", "v2", "v3", "v4"],
  MPanAction: ["u1", "u2", "u3", "u4", "r"],

  MUAction: [
    /**
     * drawtype是 u1到u4的话可以操作以下
     */
    "high_variation",
    "low_variation",
    "out_paint_2",
    "out_paint_1_5",
    "pan_left",
    "pan_right",
    "pan_up",
    "pan_down"
  ],
  // midjourney 全部按钮
  MTotalActionType: [
    "u1",
    "u2",
    "u3",
    "u4",
    "r",
    "v1",
    "v2",
    "v3",
    "v4",
    "high_variation",
    "low_variation",
    "out_paint_2",
    "out_paint_1_5",
    "pan_left",
    "pan_right",
    "pan_up",
    "pan_down"
  ],
  MidSpeedMode: {
    SLOW: 0,
    FAST: 1,
    ALL: 2
  },
  SDButtonType: {
    DoubleUpscale: 2,
    QuadrupleUpscale: 4
  },
  // 积分类型:1青铜,2白银,3黄金,4铂金,5钻石 6王者
  ScoreType: {
    Bronze: 1,
    Silver: 2,
    Gold: 3,
    Platinum: 4,
    Diamond: 5,
    King: 6
  },
  // 支付业务类型
  ReUploadKey: {
    editDemoDialogImg: "editDemoDialogImg",
    editDemoDialogVideo: "editDemoDialogVideo",
    guijiDialogVideo: "guijiDialogVideo",
    guijiDialogImg: "guijiDialogImg"
  },

  // 支付后的状态
  OnLinePayState: {
    SUCCESS: 1,
    PENDING: 0,
    FAIL: 2
  },
  DrawerDataTitle: {
    Upload: "图片",
    Midjourney: "Midjourney",
    StableDiffusion: "StableDiffusion",
    PSImage: "图片"
  },
  LanguageVideoArr: [
    { value: LanguageVideoEnum.ZH, name: "中文" },
    { value: LanguageVideoEnum.EN, name: "英文" },
    { value: LanguageVideoEnum.JA, name: "日语" },
    { value: LanguageVideoEnum.KO, name: "韩语" },
    { value: LanguageVideoEnum.DE, name: "德语" },
    { value: LanguageVideoEnum.FR, name: "法语" },
    { value: LanguageVideoEnum.RU, name: "俄语" },
    { value: LanguageVideoEnum.IT, name: "意大利语" },
    { value: LanguageVideoEnum.ES, name: "西班牙语" },
    { value: LanguageVideoEnum.AR, name: "阿拉伯语" },
    { value: LanguageVideoEnum.HI, name: "印地语" },
    { value: LanguageVideoEnum.PT, name: "葡萄牙语" },
    { value: LanguageVideoEnum.ID, name: "印度尼西亚语" },
    { value: LanguageVideoEnum.NL, name: "荷兰语" },
    { value: LanguageVideoEnum.TR, name: "土耳其语" },
    { value: LanguageVideoEnum.PL, name: "波兰语" },
    { value: LanguageVideoEnum.SV, name: "瑞典语" },
    { value: LanguageVideoEnum.FIL, name: "菲律宾语" },
    { value: LanguageVideoEnum.MS, name: "马来语" },
    { value: LanguageVideoEnum.RO, name: "罗马尼亚语" },
    { value: LanguageVideoEnum.UK, name: "乌克兰语" },
    { value: LanguageVideoEnum.EL, name: "希腊语" },
    { value: LanguageVideoEnum.CS, name: "捷克语" },
    { value: LanguageVideoEnum.DA, name: "丹麦语" },
    { value: LanguageVideoEnum.BG, name: "保加利亚语" },
    { value: LanguageVideoEnum.HR, name: "克罗地亚语" },
    { value: LanguageVideoEnum.TA, name: "泰米尔语" }
  ],
  // 翻译列表
  LanguageObjList: [
    { value: "zh-Hans", label: "中文(Chinese Simplified)" },
    { value: "zh-Han", label: "繁体(Chinese Traditional)" },
    { value: "en", label: "英语(English)" },
    { value: "de", label: "德语(German)" },
    { value: "fr", label: "法语(French)" },
    { value: "ja", label: "日语(Japanese)" },
    { value: "ko", label: "韩语(Korean)" },
    { value: "ru", label: "俄语(Russian)" },
    { value: "th", label: "泰语(Thai)" },
    { value: "ms", label: "马来语(Malay)" },
    { value: "es", label: "西班牙语(Spanish)" },
    { value: "fil", label: "菲律宾语(Filipino)" },
    { value: "he", label: "希伯来语(以色列)(Hebrew (Israel))" },
    { value: "it", label: "意大利语(Italian)" },
    { value: "pt", label: "葡萄牙语(巴西)(Portuguese (Brazil))" },
    { value: "pt-PT", label: "葡萄牙语(葡萄牙)(Portuguese (Portugal))" },
    { value: "el", label: "希腊语(Greek)" },
    { value: "ar", label: "阿拉伯语(Arabic)" },
    { value: "id", label: "印度尼西亚语(Indonesian)" }
  ],
  /**消耗积分数据 */
  AIToolsPrice: {
    dyDetail: 4, // 提取抖音详情  成本0.02
    dyVideo: 4, // 提取抖音视频   成本0.02
    txtToVideo: 4, // 文字转音频 成本 0.0114
    audioToTxt: 8, // 音频转文字,成本 0.0375
    audio: 4 // 成本0.02
  },
  /**todo */
  ProcessReq: [
    Code.CHILD_DOMAIN_ERROR.statusCode,
    Code.MAIN_KEY_ERROR.statusCode,
    Code.SECOND_CONFIG_ERROR.statusCode,
    Code.SECOND_KEY_ERROR.statusCode,
    Code.MAIN_KEY_NOT_INCLUDE_SPACE.statusCode
  ],
  Roles: {
    ALL: [
      RoleEnum.User,
      RoleEnum.SuperAdmin,
      RoleEnum.Admin,
      RoleEnum.Partner,
      RoleEnum.Staff
    ],
    SuperAdmin: [RoleEnum.SuperAdmin],
    ADMIN: [RoleEnum.Admin],
    PARTNER: [RoleEnum.Partner],
    STAFF: [RoleEnum.Staff],
    SUPER_ADMIN_ADMIN: [RoleEnum.SuperAdmin, RoleEnum.Admin],
    ADMIN_PARTNER: [RoleEnum.SuperAdmin, RoleEnum.Admin, RoleEnum.Partner],
    ADMIN_STAFF: [RoleEnum.SuperAdmin, RoleEnum.Admin, RoleEnum.Staff],
    ADMIN_ALL: [
      RoleEnum.SuperAdmin,
      RoleEnum.Admin,
      RoleEnum.Partner,
      RoleEnum.Staff
    ]
  },
  /**图像处理类型 */
  ImgDealType: {
    IMG_CUT_OUT_FACE: {
      value: 3,
      name: "头像图片处理"
    },
    IMG_BEAUTIFY: {
      value: 4,
      name: "无损高清"
    },
    IMG_CUT_OUT_NORMAL: {
      value: 6,
      name: "AI智能抠图"
    },
    IMG_FACE_BECOMES_CLEAR: {
      value: 18,
      name: "照片变清晰"
    },
    IMG_PHOTO_COLORING: {
      value: 19,
      name: "老照片上色"
    },

    IMG_CARTOON_AVATAR: {
      value: 30,
      name: "卡通头像"
    },
    IMG_REPAIR: {
      value: 31,
      name: "杂物消除"
    }
  },
  /**卡通头像类型 */
  CartoonType: {
    RomanceStyleFullBody: "5", // 言情风格全身/多人
    RomanceStyleAvatar: "4", // 言情风格头像
    DisneyRealPersonAvatar: "0", // 迪士尼真人头像
    Disney3DAvatar: "1", // 3D迪士尼头像
    Barbie: "2", // 芭比
    JapaneseCartoon: "3", // 日系卡通
    Pixelization: "6", // 像素化
    SmileCartoon: "7", // 笑脸卡通
    DisneyRealPersonMultiPerson: "8", // 迪士尼真人/多人
    AmericanComicAvatar: "9", // 美漫头像
    AmericanComicFullBody: "10", // 美漫全身
    NewPixarAvatarHeader: "11_header", // 新版皮克斯头像
    NewPixarFullBody: "11_full", // 新版皮克斯全身
    PinkPuffAvatarHeader: "12_header", // 粉扑扑头像
    PinkPuffFullBody: "12_full" // 粉扑扑全身
  },
  ProjectName: {
    IIII: "iiii",
    AI: "ai"
  },
  SquareTypeOption: [
    { id: 1, key: "SCENERY", name: "风景" },
    { id: 2, key: "CHARACTER", name: "人物" },
    { id: 3, key: "ANIME", name: "动漫" },
    { id: 4, key: "SCIFI", name: "科幻" },
    { id: 5, key: "ARCHITECTURE", name: "建筑" },
    { id: 6, key: "MECHANISM", name: "机械" },
    { id: 7, key: "REALISM", name: "写实" },
    { id: 8, key: "FANTASY", name: "幻想" },
    { id: 9, key: "ART", name: "艺术" },
    { id: 10, key: "ANIMAL", name: "动物" },
    { id: 11, key: "CARTOON", name: "卡通" },
    { id: 12, key: "PIXEL", name: "像素" },
    { id: 13, key: "MONSTER", name: "怪物" },
    { id: 14, key: "HORROR", name: "恐怖" },
    { id: 15, key: "GAME", name: "游戏" },
    { id: 16, key: "MYTHOLOGY", name: "神话" },
    { id: 17, key: "OIL_PAINTING", name: "油画" },
    { id: 18, key: "WATERCOLOR", name: "水彩" },
    { id: 19, key: "LIFE", name: "生活" },
    { id: 20, key: "FOOD", name: "食物" },
    { id: 21, key: "ANCIENT", name: "古代" },
    { id: 22, key: "ICON", name: "图标" },
    { id: 23, key: "WALLPAPER", name: "壁纸" },
    { id: 24, key: "ADVERTISEMENT", name: "广告" },
    { id: 25, key: "CITY", name: "城市" },
    { id: 26, key: "PLANT", name: "植物" },
    { id: 27, key: "CULTURE", name: "文化" },
    { id: 28, key: "ABSTRACT", name: "抽象" }
  ],
  AliyunOssUrl: "https://oss.iiii.com/web/",
  cartoonTypeObjList: [
    {
      key: "RomanceStyleFullBody",
      url: "https://oss.iiii.com/web/" + "pika1.jpg",
      value: "5",
      desc: "言情风格全身/多人"
    },
    {
      key: "RomanceStyleAvatar",
      url: "https://oss.iiii.com/web/" + "pika2.jpg",
      value: "4",
      desc: "言情风格头像"
    },
    {
      key: "DisneyRealPersonAvatar",
      url: "https://oss.iiii.com/web/" + "pika3.jpg",
      value: "0",
      desc: "迪士尼真人头像"
    },
    {
      key: "Disney3DAvatar",
      url: "https://oss.iiii.com/web/" + "pika4.jpg",
      value: "1",
      desc: "3D迪士尼头像"
    },
    {
      key: "Barbie",
      url: "https://oss.iiii.com/web/" + "pika5.jpg",
      value: "2",
      desc: "芭比"
    },
    {
      key: "JapaneseCartoon",
      url: "https://oss.iiii.com/web/" + "pika6.jpg",
      value: "3",
      desc: "日系卡通"
    },
    {
      key: "Pixelization",
      url: "https://oss.iiii.com/web/" + "pika7.jpg",
      value: "6",
      desc: "像素化"
    },
    {
      key: "SmileCartoon",
      url: "https://oss.iiii.com/web/" + "pika8.jpg",
      value: "7",
      desc: "笑脸卡通"
    },
    {
      key: "DisneyRealPersonMultiPerson",
      url: "https://oss.iiii.com/web/" + "pika9.jpg",
      value: "8",
      desc: "迪士尼真人/多人"
    },
    {
      key: "AmericanComicAvatar",
      url: "https://oss.iiii.com/web/" + "pika11.jpg",
      value: "9",
      desc: "美漫头像"
    },
    {
      key: "AmericanComicFullBody",
      url: "https://oss.iiii.com/web/" + "pika12.jpg",
      value: "10",
      desc: "美漫全身"
    },
    {
      key: "NewPixarAvatarHeader",
      url: "https://oss.iiii.com/web/" + "pika11.jpg",
      value: "11_header",
      desc: "新版皮克斯头像"
    },
    {
      key: "NewPixarFullBody",
      url: "https://oss.iiii.com/web/" + "pika11_full.png",
      value: "11_full",
      desc: "新版皮克斯全身"
    },
    {
      key: "PinkPuffAvatarHeader",
      url: "https://oss.iiii.com/web/" + "pika12_header.png",
      value: "12_header",
      desc: "粉扑扑头像"
    },
    {
      key: "PinkPuffFullBody",
      url: "https://oss.iiii.com/web/" + "pika12_full.png",
      value: "12_full",
      desc: "粉扑扑全身"
    }
  ],
  Namespace: "qiaoqiao-poster",
  AIPosterDevUrl: "http://localhost:3000",
  BackgroundColor: [
    "#1b2a47",
    "#ffffff",
    "#f5222d",
    "#bc1f1a",
    "#fadb14",
    "#13c2c2",
    "#52c41a",
    "#eb2f96",
    "#722ed1"
  ],
  Lang: {
    zh: "zh",
    en: "en",
    zhTw: "zh-tw"
  },
  CategoryEnumOptions: [
    {
      value: CategoryEnum.WX_GZ,
      label: "公众号AppId和AppSecret"
    },
    {
      value: CategoryEnum.WX_PAY,
      label: "支付"
    },
    {
      label: "万能验证码",
      value: CategoryEnum.UNIVERSAL_VERIFICATION_CODE
    },
    {
      label: "邀请码要求",
      value: CategoryEnum.REQUIRED_INVITE_CODE
    },
    {
      label: "验证码过期时间",
      value: CategoryEnum.VERIFICATION_CODE_EXPIRATION_TIME
    },
    {
      label: "合成视频文字最低长度",
      value: CategoryEnum.CREATE_VIDEO_TEXT_MIN
    },
    {
      label: "合成视频文字最高长度",
      value: CategoryEnum.CREATE_VIDEO_TEXT_MAX
    },
    {
      label: "背景图",
      value: CategoryEnum.BACKGROUND_GROUP
    },
    {
      value: CategoryEnum.GPT_INIT_COUNT,
      label: "未付费用户初始次数"
    },
    {
      value: CategoryEnum.OPEN_INVITE,
      label: "开通邀请机制"
    },
    {
      value: CategoryEnum.INVITE_CHATGPT,
      label: "开通邀请机制chatgpt"
    },
    {
      value: CategoryEnum.INVITE_CHATGPT_REGISTER,
      label: "注册奖励"
    },
    {
      value: CategoryEnum.IMG_GROUP,
      label: "系统图片"
    },
    {
      value: CategoryEnum.VIDEO_GROUP,
      label: "系统视频"
    }
  ],
  // 菜单权限与按钮权限
  PermissionsTypeEnumOptions: [
    {
      label: "菜单权限",
      value: PermissionsTypeEnum.MENU
    },
    {
      label: "按钮权限",
      value: PermissionsTypeEnum.BUTTON
    }
  ],
  //主页面排序
  MainRouterEnumOptions: [
    {
      label: "ai工具",
      value: MainRouterEnum.AI_TOOL
    },
    {
      label: "人工智能运营师",
      value: MainRouterEnum.INTELLIGENCETRAINING
    },
    {
      label: "数字人购买",
      value: MainRouterEnum.DIGITAL_HUMAN
    },
    {
      label: "交互数字人",
      value: MainRouterEnum.INTERACTIVE_DIGITALS
    },
    {
      label: "合伙人计划",
      value: MainRouterEnum.PARTNER_PLAN
    },
    {
      label: "定制化模型",
      value: MainRouterEnum.CUSTOMIZE_DMODEL
    },
    {
      label: "优秀案例",
      value: MainRouterEnum.OUT_STANDING_CASE
    },
    {
      label: "数字人操作",
      value: MainRouterEnum.HUMAN_OPERATION
    }
  ],
  dashboardProgressColors: [
    { color: "#f56c6c", percentage: 20 },
    { color: "#e6a23c", percentage: 40 },
    { color: "#5cb87a", percentage: 60 },
    { color: "#13c2c2", percentage: 93 },
    { color: "#13c2c2", percentage: 100 }
  ],
  smallBannerEnumOptions: [
    {
      label: "人工智能实训",
      value: SmallBannerEnumOptionsEnum.AI_CERTIFICATION
    },
    {
      label: "交互式数字人",
      value: SmallBannerEnumOptionsEnum.INTERACTIVE_DIGITAL_PERSON
    },
    {
      label: "数字人直播",
      value: SmallBannerEnumOptionsEnum.DIGITAL_PERSON_LIVE
    },
    {
      label: "数字人定制",
      value: SmallBannerEnumOptionsEnum.DIGITAL_PERSON_CUSTOMIZATION
    },
    {
      label: "合伙人计划",
      value: SmallBannerEnumOptionsEnum.PARTNER_PROGRAM
    },
    {
      label: "AI 作画",
      value: SmallBannerEnumOptionsEnum.AI_PAINTING
    },
    {
      label: "优秀案例",
      value: SmallBannerEnumOptionsEnum.EXCELLENT_CASE
    },
    {
      label: "定制化模型",
      value: SmallBannerEnumOptionsEnum.CUSTOMIZED_MODEL
    },
    {
      label: "工作台",
      value: SmallBannerEnumOptionsEnum.DIGITAL_WORKBENCH
    }
  ],
  swiperSortingOptions: [
    {
      label: "工信部人工智能运营师认证",
      value: SwiperSortingOptionsEnum.MIIT_AI_OPERATION_CERT
    },
    {
      label: "人工智能运营师适用人群",
      value: SwiperSortingOptionsEnum.AI_OPERATOR_GROUP
    },
    {
      label: "短视频营销时代",
      value: SwiperSortingOptionsEnum.SHORT_VIDEO_MARKETING
    },
    {
      label: "交互式数字人 ",
      value: SwiperSortingOptionsEnum.INTERACTIVE_DIGITAL_PERSON
    }
  ],

  CurrencysEnumOptions: [
    {
      label: "美元-USD",
      value: CurrencysEnum.USD
    },
    {
      label: "人民币-CNY",
      value: CurrencysEnum.CNY
    },
    {
      label: "新台币-TWD",
      value: CurrencysEnum.TWD
    }
  ],
  WxMenuTypeEnumOptions: [
    {
      label: "网址",
      value: WxMenuTypeEnum.VIEW // 网址
    },
    {
      label: "下发图片",
      value: WxMenuTypeEnum.MEDIA_ID // 下发图片
    }
  ],
  WxXmlTypeEnumOptions: [
    {
      label: "文本",
      value: WxXmlTypeEnum.TEXT // 网址
    },
    {
      label: "图片",
      value: WxXmlTypeEnum.IMG // 下发图片
    }
  ],
  newsLinkOptions: [
    {
      label: "0元定制数字人",
      value: `/digit-human/index?type=${DemoGroupEnum.DIGITAL_SHORT}`
    },
    {
      label: "直播数字人",
      value: `/digit-human/index?type=${DemoGroupEnum.DIGITAL_LIVE}`
    },
    {
      label: "交互式数字人",
      value: `/interactive-digital/index`
    },
    {
      label: "合伙人计划",
      value: "/partner-plan/index"
    },
    {
      label: "优秀案例",
      value: "/outstanding-case/index"
    },

    {
      label: "AI绘画",
      value: "/image-ai/index"
    },
    {
      label: "定制化模型",
      value: "/customized-model/index"
    },
    {
      label: "人工智能运营师",
      value: "/intelligence-training/index"
    }
  ],
  //主域名key
  // domainEnumOptions: [
  //   {
  //     label: "iiii",
  //     value: DomainEnum.IIII
  //   },
  //   {
  //     label: "zqsdai",
  //     value: DomainEnum.ZQSDAI
  //   },
  //   {
  //     label: "test",
  //     value: DomainEnum.TEST
  //   },
  //   {
  //     label: "bihuoai",
  //     value: DomainEnum.BIHUOAI
  //   },
  //   {
  //     label: "snkxd-ai",
  //     value: DomainEnum.SNKXD_AI
  //   },
  //   {
  //     label: "ai365",
  //     value: DomainEnum.AI365
  //   }
  // ],
  //售卖的数字人所有商品的logo
  logoOfTheProductFoSale: [
    {
      index: 0,
      key: digitNewUser,
      activeKey: digitNewUserActive,
      name: "digitNewUser",
      value: "短视频数字人-新手体验版"
    },
    {
      index: 1,
      key: digitLite,
      activeKey: digitLiteActive,
      name: "digitLite",
      value: "短视频数字人-数字人Lite版本"
    },
    {
      index: 2,
      key: digitPro,
      activeKey: digitProActive,
      name: "digitPro",
      value: "短视频数字人-数字人Pro版本"
    },
    {
      index: 3,
      key: digitEnterprise,
      activeKey: digitEnterpriseActive,
      name: "digitEnterprise",
      value: "短视频数字人-数字人定制企业版"
    },
    {
      index: 4,
      key: interactiveDigitalBeginnner,
      activeKey: interactiveDigitalBeginnnerActive,
      name: "interactiveDigitalBeginnner",
      value: "互动数字人-新手体验版"
    },
    {
      index: 5,
      key: interactiveDigitalLite,
      activeKey: interactiveDigitalLiteActive,
      name: "interactiveDigitalLite",
      value: "互动数字人-Lite版本"
    },
    {
      index: 6,
      key: interactiveDigitalPro,
      activeKey: interactiveDigitalProActive,
      name: "interactiveDigitalPro",
      value: "互动数字人-Pro版本"
    },
    {
      index: 7,
      key: liveDigitalBeginner,
      activeKey: liveDigitalBeginnerActive,
      name: "liveDigitalBeginner",
      value: "直播数字人-新手体验版"
    },
    {
      index: 8,
      key: liveDigitalLite,
      activeKey: liveDigitalLiteActive,
      name: "liveDigitalLite",
      value: "直播数字人-Lite版"
    },
    {
      index: 9,
      key: liveDigitalPro,
      activeKey: liveDigitalProActive,
      name: "liveDigitalPro",
      value: "直播数字人-pro版"
    },
    {
      index: 10,
      key: voiceCustomizationBeginner,
      activeKey: voiceCustomizationBeginnerActive,
      name: "voiceCustomizationBeginner",
      value: "声音定制-新手体验版"
    },
    {
      index: 11,
      key: voiceCustomizationLite,
      activeKey: voiceCustomizationLiteActive,
      name: "voiceCustomizationLite",
      value: "声音定制-Lite版"
    },
    {
      index: 12,
      key: aiAutoPlay,
      activeKey: aiAutoPlayActive,
      name: "aiAutoPlay",
      value: "ai自动播-ai自动播"
    }
  ],
  // 用来当循环出列表用的
  PhoneRegexMap: [
    {
      number: "86",
      country: "中国",
      regex: "^(\\+?86|\\+?0086)1[3456789]\\d{9}$"
    },
    { number: "886", country: "中国台湾", regex: "^(\\+?886)0?9\\d{8}$" },
    {
      number: "852",
      country: "中国香港",
      regex: "^(\\+?852\\-?)[569]\\d{3}\\-?\\d{4}$"
    },
    { number: "853", country: "中国澳门", regex: "^(853)?6d{7}$" },
    {
      number: "1",
      country: "美国",
      regex: "^(\\+?1)[-]?\\(?(\\d{3})\\)?[-]?(\\d{3})[-]?(\\d{4})$"
    },
    { number: "44", country: "英国", regex: "^(\\+?44)?7\\d{9}$" },
    {
      number: "49",
      country: "德国",
      regex:
        "^(\\+?49[\\.\\-])([\\(]{1}[0-9]{1,6}[\\)])?([0-9\\.\\-\\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$"
    },
    {
      number: "60",
      country: "马来西亚",
      regex:
        "^(\\+?6?01)(([145]{1}(\\-|\\s)?\\d{7,8})|([236789]{1}(\\s|\\-)?\\d{7}))$"
    },
    { number: "61", country: "澳大利亚", regex: "^(\\+?61|0)4\\d{8}$" },
    { number: "64", country: "新西兰", regex: "^(\\+?64)2\\d{7,9}$" },
    { number: "65", country: "新加坡", regex: "^(\\+?0?65\\-?)\\d{10}$" },
    { number: "66", country: "泰国", regex: "^(\\+?0?66\\-?)\\d{10}$" },
    {
      number: "81",
      country: "日本",
      regex: "^(\\+?81)\\d{1,4}[\\-]?\\d{1,4}[\\-]?\\d{4}$"
    },
    { number: "82", country: "韩国", regex: "^(\\+?82)01[016789](\\d{8})$" },
    { number: "91", country: "印度", regex: "^(\\+?91)[789]\\d{9}$" },
    { number: "855", country: "柬埔寨", regex: "^(\\+?855)\\d{6,9}$" }
  ],
  certificateRegistOptions: [
    {
      label: "口述",
      value: certificateRegistEnum.SPOKEN
    },
    {
      label: "音乐",
      value: certificateRegistEnum.MUSIC
    },
    {
      label: "戏剧",
      value: certificateRegistEnum.DRAMA
    },
    {
      label: "曲艺",
      value: certificateRegistEnum.QUYI
    },
    {
      label: "舞蹈",
      value: certificateRegistEnum.DANCE
    },
    {
      label: "杂技",
      value: certificateRegistEnum.ACROBATICS
    },
    {
      label: "美术",
      value: certificateRegistEnum.ART
    },
    {
      label: "建筑",
      value: certificateRegistEnum.ARCHITECTURE
    },
    {
      label: "摄影",
      value: certificateRegistEnum.PHOTOGRAPHY
    },
    {
      label: "电影",
      value: certificateRegistEnum.FILM
    },
    {
      label: "类似摄制电影方法创作作品",
      value: certificateRegistEnum.FILM_LIKE_CREATION
    },
    {
      label: "工程设计图、产品设计图",
      value: certificateRegistEnum.ENGINEERING_DESIGN
    },
    {
      label: "地图、示意图",
      value: certificateRegistEnum.MAPS
    },
    {
      label: "模型",
      value: certificateRegistEnum.MODEL
    },
    {
      label: "其他作品",
      value: certificateRegistEnum.OTHER
    }
  ]
};
export { Constants };

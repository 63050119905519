<script setup lang="ts">
import { ref } from "vue";
import { adminApi } from "@/api/admin-api";
import {
  AdminSearchType,
  CommonPagenationData,
  ReqUserList
} from "@/api/types";
import { Constants } from "@/config/constants";
import { CommonUtils } from "@/utils/commonUtils";
import { CommonListComponents } from "@/components/common";
import { userType } from "@/store/modules/types";
import { message } from "@/utils";
import { Clipboard } from "v-clipboard";
import { deviceDetection } from "@pureadmin/utils";
import {
  UserTypeArr,
  CommonDialogTypeEnum,
  UserTypeEnum,
  Code
} from "@/config";

import CommonDialog from "@/views/components/dialog/CommonDialog.vue";
import { AuthUtils } from "@/utils/authUtils";
import { useUserStoreHook } from "@/store/modules/user";
const isIncludeSuperAdmin = AuthUtils.isSuperAdmin(useUserStoreHook().role);
const isMobile = deviceDetection();
const props = defineProps({
  isHaveSelectUser: {
    type: Boolean,
    default: false,
    required: false
  }
});
const adminSearchType = ref<AdminSearchType>(null);
const commonListComponentsRef = ref();
const tableLoading = ref(true);
const commonDialogRef = ref();
const typeOfuser = ref<UserTypeEnum>(UserTypeEnum.USER);
const userID = ref(null);
const submit = async () => {
  const paginationData: CommonPagenationData =
    commonListComponentsRef.value.getPage();
  tableLoading.value = true;
  // 默认请求最近一月的数据
  const reqData: ReqUserList = CommonUtils.createNormalRecharge(
    adminSearchType.value,
    {
      page: paginationData.page,
      size: paginationData.size
    }
  );

  const { data } = await adminApi.postUserList(reqData);
  console.log("用户列表：", data);
  tableLoading.value = false;
  commonListComponentsRef.value.setDataToPage(data);
};

const selectUser = (mUser: userType) => {
  emit("mySelected", mUser);
};
const commonDialogSubmit = async (type: CommonDialogTypeEnum) => {
  if (type === CommonDialogTypeEnum.RECHARGE_TITLE) {
    const res = await adminApi.changeUserType(userID.value, typeOfuser.value);
    if (res.statusCode === Code.SUCCESS.statusCode) {
      message("修改成功", { type: "success" });
      submit();
      commonDialogRef.value.commonDialogClose();
    }
  }
};
const setUserType = row => {
  typeOfuser.value = row.type;
  userID.value = row.id;
  commonDialogRef.value.showDialog(CommonDialogTypeEnum.RECHARGE_TITLE, row);
};
const handleCurrentPageChange = () => {
  submit();
};

const serarchSubmit = (mAdminSearchType: AdminSearchType) => {
  adminSearchType.value = mAdminSearchType;
  submit();
};

const copy = value => {
  Clipboard.copy(value);
  message("复制成功 " + value, { type: "success" });
};
const emit = defineEmits(["mySelected", "updataUserInfo"]);
defineExpose({ submit, serarchSubmit });
</script>

<template>
  <div>
    <CommonListComponents
      ref="commonListComponentsRef"
      @handleCurrentPageChange="handleCurrentPageChange"
    >
      <template v-slot:list-body="{ mTableList }">
        <el-table
          :data="mTableList"
          border
          v-loading="tableLoading"
          element-loading-text="加载中"
          :element-loading-svg="Constants.TableLoadingSvg"
          class="custom-loading-svg"
          element-loading-svg-view-box="-10, -10, 50, 50"
          style="width: 100%"
        >
          <el-table-column prop="id" label="ID">
            <template #default="scope">
              <p
                class="hover:text-primary active:text-[#f4f4f4] cursor-pointer"
                @click="copy(scope.row.id)"
              >
                {{ scope.row.id }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="updatedOn" label="更新时间" />
          <el-table-column prop="username" label="用户名">
            <template #default="scope">
              <p
                class="hover:text-primary active:text-[#f4f4f4] cursor-pointer"
                @click="copy(scope.row.username)"
              >
                {{ scope.row.username }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="inviteCode" label="邀请码" />
          <el-table-column prop="parentId" label="邀请人ID" />
          <el-table-column prop="role" label="角色" />
          <el-table-column prop="wxOpenId" label="Open ID" />
          <el-table-column prop="type" label="类型">
            <template #default="scope">
              {{ CommonUtils.getLabelByValue(UserTypeArr, scope.row.type) }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="180"
            v-if="props.isHaveSelectUser || isIncludeSuperAdmin"
          >
            <template #default="{ row }">
              <div class="flex justify-evenly whitespace-nowrap">
                <el-button
                  v-if="props.isHaveSelectUser"
                  size="small"
                  type="primary"
                  plain
                  @click="selectUser(row)"
                  >选择添加</el-button
                >
                <el-button
                  v-if="isIncludeSuperAdmin"
                  size="small"
                  type="primary"
                  plain
                  @click="setUserType(row)"
                  >设置类型</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </CommonListComponents>
    <CommonDialog
      :title="'设置'"
      ref="commonDialogRef"
      :width="isMobile ? '80%' : '30%'"
      @commonDialogSubmit="commonDialogSubmit"
    >
      <template #content>
        <el-form-item label="用户类型:">
          <el-radio-group v-model="typeOfuser">
            <el-radio
              v-for="(item, index) in UserTypeArr"
              :key="index"
              :value="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </template>
    </CommonDialog>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from "vue";
import { AdminSearchType } from "@/api";
import { Constants } from "@/config";
import SelectAgentItem from "@/views/components/aitools/SelectAgentItem.vue";
import { AuthUtils } from "@/utils/authUtils";
import { useUserStoreHook } from "@/store/modules/user";
import { deviceDetection } from "@pureadmin/utils";
const isIncludeAdmin = AuthUtils.isIncludeAdmin(useUserStoreHook().role);
const isMobile = deviceDetection();
const gAgent = ref();
const props = defineProps({
  userIdUnShow: {
    default: false,
    type: Boolean
  },
  usernameUnShow: {
    default: false,
    type: Boolean
  },
  dateShow: {
    default: false,
    type: Boolean
  },
  emailShow: {
    default: false,
    type: Boolean
  },
  agentShow: {
    default: false,
    type: Boolean
  }
});
const adminSearch = reactive({
  userId: "",
  username: "",
  contactMail: "",
  date: null,
  agent: null
});
const shortcuts = ref(Constants.Common.shortcuts);

const submit = () => {
  const username = adminSearch.username?.trim();
  const contactMail = adminSearch.contactMail?.trim();
  const adminSearchType: AdminSearchType = {
    userId: adminSearch.userId ? +adminSearch.userId : null,
    username: username ? username : null,
    contactMail: contactMail ? contactMail : null,
    start: adminSearch.date ? adminSearch.date[0].toISOString() : null,
    end: adminSearch.date ? adminSearch.date[1].toISOString() : null,
    agent: gAgent.value
  };
  if (props.userIdUnShow) {
    adminSearchType.userId = null;
  }
  if (props.usernameUnShow) {
    adminSearchType.username = null;
  }
  if (!props.dateShow) {
    adminSearchType.start = null;
    adminSearchType.end = null;
  }
  if (!props.emailShow) {
    adminSearchType.contactMail = null;
  }
  if (!props.agentShow) {
    adminSearchType.agent = null;
  }

  emit("serarchSubmit", adminSearchType);
};

const selectedItemAgent = agent => {
  gAgent.value = agent;
};

const emit = defineEmits(["serarchSubmit"]);
</script>

<template>
  <div class="flex flex-col items-start my-5">
    <div>
      <el-form
        ref="ruleFormRef"
        :model="adminSearch"
        :inline="true"
        status-icon
        label-position="left"
      >
        <el-form-item v-if="props.dateShow" label="日期">
          <el-date-picker
            :class="
              isMobile ? '!w-[300px] ' : '!w-[350px] mr-[40px]  lg:mr-[80px]'
            "
            v-model="adminSearch.date"
            type="daterange"
            unlink-panels
            range-separator="到"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :shortcuts="shortcuts"
          />
        </el-form-item>
        <el-form-item v-if="!props.userIdUnShow" label="用户ID">
          <el-input
            v-model="adminSearch.userId"
            placeholder="输入用户ID"
            @keyup.enter="submit"
            clearable
          />
        </el-form-item>
        <el-form-item v-if="!props.usernameUnShow" label="用户名">
          <el-input
            v-model="adminSearch.username"
            placeholder="输入用户名"
            @keyup.enter="submit"
            clearable
          />
        </el-form-item>

        <el-form-item v-if="props.emailShow" label="邮箱">
          <!-- <el-form-item label="邮箱"> -->
          <el-input
            v-model="adminSearch.contactMail"
            placeholder="输入邮箱"
            @keyup.enter="submit"
            clearable
          />
        </el-form-item>

        <SelectAgentItem
          v-if="props.agentShow && isIncludeAdmin"
          @selectedItemAgent="selectedItemAgent"
        />
        <slot class="inline-block" name="adminSearchSolt" />
      </el-form>
    </div>
    <div class="w-full flex flex-row items-center justify-center">
      <!-- 定义一个名为 "customSlot" 的具名插槽 -->
      <el-form-item class="flex flex-row items-center justify-center">
        <el-button
          type="primary"
          style="padding: 0 100px"
          size="large"
          @click="submit"
          >点击/回车查询</el-button
        ></el-form-item
      >
    </div>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import SelectAgentDialog from "@/views/admin/agent-config/SelectAgentDialog.vue";
import { agentType } from "@/api";

const selectAgentDialogRef = ref();
const agentId = ref();
const websiteChildDomain = ref();

const props = defineProps({
  pAgentId: Number,
  websiteName: {
    default: "网站名称",
    type: String
  },
  enable: {
    default: true,
    type: Boolean
  },
  enableInput: {
    default: false,
    type: Boolean
  },
  isMain: {
    // 是否仅仅显示主站
    default: false,
    type: Boolean
  }
});

const selectioAgentList = (callData, agentList: Array<agentType>) => {
  agentId.value = agentList[0].id;
  websiteChildDomain.value = agentList[0].childDomain;
  emit("selectedItemAgent", agentList[0]);
};

onMounted(() => {
  selectAgentDialogRef.value.init();
});

// 打开搜索
const selectWebsite = () => {
  selectAgentDialogRef.value.showDialog();
};

// 打开搜索
const clearText = () => {
  emit("selectedItemAgent", null);
};

const emit = defineEmits(["selectedItemAgent"]);
</script>

<template>
  <!-- 当前图片 -->
  <div class="inline-block h-full">
    <el-form-item :label="props.websiteName" prop="agentId">
      <div class="flex">
        <el-input v-show="false" v-model="agentId" />
        <el-input clearable @clear="clearText" v-model="websiteChildDomain" />
        <el-button v-if="enable" type="default" @click="selectWebsite()"
          >选择</el-button
        >
      </div>
    </el-form-item>
  </div>
  <SelectAgentDialog
    ref="selectAgentDialogRef"
    :agentId="props.pAgentId"
    :isMultipleSelection="false"
    @selectioAgentList="selectioAgentList"
  />
</template>

<style></style>

<script setup lang="ts">
import { ref } from "vue";
import { adminApi } from "@/api";
import { message } from "@/utils";
import { hasAuth } from "@/router/utils";
import SelectAgentDialog from "@/views/admin/agent-config/SelectAgentDialog.vue";
import { PermissionsEnum } from "@/config/constantsEnum";
import { CommonUtils } from "@/utils/commonUtils";
const props = defineProps({
  itemName: String,
  item: {
    type: [String, Number, Boolean, Object, Array],
    default: null
  }
});
const selectAgentDialogRef = ref();
const handleEditWebConfig = (subKey: string, value) => {
  if (CommonUtils.isJsonString(value)) {
    value = CommonUtils.getJSON(value);
  }
  selectAgentDialogRef.value.showDialog({
    topKey: "sysConfig",
    subKey: subKey,
    value: value
  });
};
const selectioAgentList = async (backData: any, agentList: Array<any>) => {
  if (agentList.length > 0) {
    const ids = agentList.map(agent => agent.id);
    await adminApi.batchUpdateAllAgentKey({
      topKey: backData.topKey,
      subKey: backData.subKey,
      value: "" + backData.value,
      agentList: ids
    });
    message("更新成功", { type: "success" });
  }
};
</script>

<template>
  <el-button
    v-if="hasAuth(PermissionsEnum.BTN_UPDATE_ALL_AGENT_CONFIG)"
    @click="handleEditWebConfig(props.itemName, props.item)"
    type="danger"
    size="large"
    class="ml-2"
  >
    点击同步</el-button
  >
  <SelectAgentDialog
    @selectioAgentList="selectioAgentList"
    ref="selectAgentDialogRef"
  />
</template>

<style scoped></style>

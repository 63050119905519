import { RouterNameEnum } from "@/config";

const IntelTraining = () => import("@/views/intelligence-training/index.vue");
// import { $t } from "@/plugins/i18n";
export default {
  path: "/intelligence-training",
  redirect: "/intelligence-training/index",
  // $t("future.one.interactiveDigitalHuman")
  meta: {
    title: "智能交互数字人",
    showLink: false
  },
  children: [
    {
      path: "/intelligence-training/index",
      name: RouterNameEnum.IntelligenceTraining,
      component: IntelTraining,
      meta: {
        title: "智能交互数字人",
        saveScrollTop: false
      }
    }
  ]
} as RouteConfigsTable;

import { useNav } from "./useNav";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { watch, type Ref } from "vue";
import { Constants } from "@/config";
export function useTranslationLang(ref?: Ref) {
  const { $storage, changeTitle, handleResize } = useNav();
  const { locale, t } = useI18n();
  const route = useRoute();

  function translationCh() {
    $storage.locale = { locale: Constants.Lang.zh };
    locale.value = Constants.Lang.zh;
    ref && handleResize(ref.value);
  }

  function translationEn() {
    $storage.locale = { locale: Constants.Lang.en };
    locale.value = Constants.Lang.en;
    ref && handleResize(ref.value);
  }
  function translationTw() {
    $storage.locale = { locale: Constants.Lang.zhTw };
    locale.value = Constants.Lang.zhTw;
    ref && handleResize(ref.value);
  }
  watch(
    () => locale.value,
    () => {
      changeTitle(route.meta);
      // window.location.reload();
    }
  );

  return {
    t,
    route,
    locale,
    translationCh,
    translationEn,
    translationTw
  };
}

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { deviceDetection } from "@pureadmin/utils";
import forPeopleEnterprise from "@/assets/intelligence-study/four/for-people-enterprise.png";
import vector from "@/assets/intelligence-study/four/vector.png";
import collegeStudents from "@/assets/intelligence-study/four/for-people-college-students.png";
import corporateTraining from "@/assets/intelligence-study/four/for-people-corporate-training.png";
import course from "@/assets/intelligence-study/four/for-people-course.png";
import peoplesocialPerson from "@/assets/intelligence-study/four/for-people-for-peoplesocial-person.png";
import { useTranslationLang } from "@/layout/hooks/useTranslationLang";
import { Constants } from "@/config";
const { locale } = useTranslationLang();
const isMobile = deviceDetection();
const { t } = useI18n();
</script>

<template>
  <div class="relative w-full h-full flex flex-col lg:flex-row lg:items-center">
    <div class="absolute maskBg w-full h-full" />
    <img
      class="z-[-999] absolute W-full h-full object-cover"
      :src="forPeopleEnterprise"
    />
    <div
      :class="{
        'flex flex-col items-center justify-center ': isMobile,
        '!mt-[2vh]': isMobile && locale == Constants.Lang.en
      }"
      class="z-[999] ml-[0] lg:ml-[8vw] w-[100vw] lg:w-[15.58vw] mt-[8vh] lg:mt-[-10.4vh]"
    >
      <div
        :class="{
          'w-[80vw] text-[2rem] text-start ': isMobile,
          '!text-[1.5rem]  !leading-[2rem]':
            isMobile && locale == Constants.Lang.en
        }"
        class="text-[2.5rem] w-[100vw] lg:w-[15.58vw] text-center lg:text-start font-[OPPOSansB] leading-[3.06rem] text-[#fff]"
      >
        {{ t("firstPage.twoScreen.applicable_people") }}
      </div>
      <div
        :class="{
          'text-start text-[1rem] w-[80vw] mb-[6vh]': isMobile,
          '!mb-[2vh]': isMobile && locale == Constants.Lang.en
        }"
        class="text-[1.125rem] w-[100vw] lg:w-[15.58vw] text-center lg:text-start leading-[1.56rem] text-[#fff] mt-[1vh] lg:mt-[3vh]"
      >
        {{ t("firstPage.twoScreen.ai_impact") }}
      </div>
      <div class="w-full flex justify-center lg:justify-start">
        <img
          v-show="!isMobile"
          class="w-[5.8rem] object-cover mb-[4vh] lg:mb-0 mt-[2vh] lg:mt-[12.06vh]"
          :src="vector"
        />
      </div>
    </div>
    <div
      :class="{ 'ml-[10vw]': isMobile }"
      class="z-[999] flex lg:ml-[2.3vw] overflow-x-auto"
    >
      <!-- 全国大学生群体 -->
      <div
        :class="{ 'mr-[5vw]': isMobile }"
        class="lg:ml-[3vw] bg-white pb-[2vh]"
      >
        <!-- 头部图片 -->
        <div
          :class="{ 'w-[38vw]': isMobile }"
          class="relative overflow-hidden w-[24vw] lg:w-[13.54vw]"
        >
          <img
            :class="{ 'w-[38vw]': isMobile }"
            class="ransition-transform duration-300 ease-in-out hover:scale-110 w-[24vw] lg:w-[13.54vw] object-cover"
            :src="collegeStudents"
          />
          <div
            class="z-[999] absolute w-full text-center bg-black bg-opacity-50 bottom-0 text-white py-[1.3vh] text-[1.25rem] font-[OPPOSansB]"
          >
            {{ t("firstPage.twoScreen.national_university_students") }}
          </div>
        </div>
        <!-- 内容 -->
        <div
          :class="{ 'w-[38vw]': isMobile }"
          class="w-[24vw] lg:w-[13.54vw] pb-[2vh]"
        >
          <div
            :class="{
              '!mt-[1vh] text-[1rem] leading-[1.5rem] ':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor ml-[1.1vw] leading-[1.5rem] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.leading_edge") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.ai_skills_for_students") }}
          </div>
          <div
            :class="{
              '!mt-[1vh] text-[1rem]  leading-[1.5rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor ml-[1.1vw] leading-[1.5rem] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.employment_advantage") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.practical_training") }}
          </div>
        </div>
      </div>
      <!-- 社会从业者群体 -->
      <div :class="{ 'mr-[5vw]': isMobile }" class="lg:ml-[3vw] bg-white">
        <!-- 头部图片 -->
        <div
          :class="{ 'w-[38vw]': isMobile }"
          class="overflow-hidden relative w-[24vw] lg:w-[13.54vw]"
        >
          <img
            :class="{ 'w-[38vw]': isMobile }"
            class="ransition-transform duration-300 ease-in-out hover:scale-110 w-[24vw] lg:w-[13.54vw] object-cover"
            :src="peoplesocialPerson"
          />
          <div
            class="z-[999] absolute w-full text-center bg-black bg-opacity-50 bottom-0 text-white py-[1.3vh] text-[1.25rem] font-[OPPOSansB]"
          >
            {{ t("firstPage.twoScreen.professional_group") }}
          </div>
        </div>
        <!-- 内容 -->
        <div :class="{ 'w-[38vw]': isMobile }" class="w-[24vw] lg:w-[13.54vw]">
          <div
            :class="{
              '!mt-[1vh] text-[1rem]  leading-[1.5rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor leading-[1.5rem] ml-[1.1vw] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.skill_update") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.ai_skill_enhancement") }}
          </div>
          <div
            :class="{
              '!mt-[1vh] text-[1rem]  leading-[1.5rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor leading-[1.5rem] ml-[1.1vw] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.efficiency_improvement") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.ai_integration") }}
          </div>
        </div>
      </div>
      <!-- 企业定制化培训 -->
      <div
        :class="{ 'mr-[5vw]': isMobile }"
        class="lg:ml-[3vw] bg-white pb-[2vh]"
      >
        <!-- 头部图片 -->
        <div
          :class="{ 'w-[38vw]': isMobile }"
          class="relative overflow-hidden w-[24vw] lg:w-[13.54vw]"
        >
          <img
            :class="{ 'w-[38vw]': isMobile }"
            class="ransition-transform duration-300 ease-in-out hover:scale-110 w-[24vw] lg:w-[13.54vw] object-cover"
            :src="corporateTraining"
          />
          <div
            class="z-[999] absolute w-full text-center bg-black bg-opacity-50 bottom-0 text-white py-[1.3vh] text-[1.25rem] font-[OPPOSansB]"
          >
            {{ t("firstPage.twoScreen.corporate_customized_training") }}
          </div>
        </div>
        <!-- 内容 -->
        <div :class="{ 'w-[38vw]': isMobile }" class="w-[24vw] lg:w-[13.54vw]">
          <div
            :class="{
              '!mt-[1vh] text-[1rem]  leading-[1.5rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor leading-[1.5rem] ml-[1.1vw] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.innovation_upgrade") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.managerial_support") }}
          </div>
          <div
            :class="{
              '!mt-[1vh] text-[1rem]  leading-[1.5rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor leading-[1.5rem] ml-[1.1vw] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.capacity_optimization") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.employee_ai_skills") }}
          </div>
        </div>
      </div>
      <!--校企联合课程开发  -->
      <div
        :class="{ 'mr-[5vw]': isMobile }"
        class="lg:ml-[3vw] bg-white pb-[2vh]"
      >
        <!-- 头部图片 -->
        <div
          :class="{ 'w-[38vw]': isMobile }"
          class="relative overflow-hidden w-[24vw] lg:w-[13.54vw]"
        >
          <img
            :class="{ 'w-[38vw]': isMobile }"
            class="ransition-transform duration-300 ease-in-out hover:scale-110 w-[24vw] lg:w-[13.54vw] object-cover"
            :src="course"
          />
          <div
            class="z-[999] absolute w-full text-center bg-black bg-opacity-50 bottom-0 text-white py-[1.3vh] text-[1.25rem] font-[OPPOSansB]"
          >
            {{ t("firstPage.twoScreen.school_enterprise_course_development") }}
          </div>
        </div>
        <!-- 内容 -->
        <div :class="{ 'w-[38vw]': isMobile }" class="w-[24vw] lg:w-[13.54vw]">
          <div
            :class="{
              '!mt-[1vh] text-[1rem]  leading-[1.5rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor leading-[1.5rem] ml-[1.1vw] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.industry_education_integration") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.ai_course_development") }}
          </div>
          <div
            :class="{
              '!mt-[1vh] text-[1rem]  leading-[1.5rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="textColor leading-[1.5rem] ml-[1.1vw] text-[1.125rem] font-[OPPOSansB] mt-[3.5vh] mb-[1vh]"
          >
            {{ t("firstPage.twoScreen.job_adaptability") }}
          </div>
          <div
            :class="{
              '!leading-[1rem] !text-[0.8rem]':
                isMobile && locale == Constants.Lang.en
            }"
            class="text-[#3d3d3d] font-[OPPOSansB] ml-[1.1vw] lg:w-[11.15vw] text-[0.875rem] leading-[1.25rem]"
          >
            {{ t("firstPage.twoScreen.market_trend_courses") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.maskBg {
  opacity: 0.9;
  background: var(--el-color-primary);
}
.textColor {
  background: linear-gradient(212deg, #81daff 25%, var(--el-color-primary) 76%),
    #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
</style>

<script setup lang="ts">
import { ref } from "vue";
import { agentType } from "@/api";
import { message } from "@/utils";
import { useAppStoreHook } from "@/store/modules/app";
import { useUserStoreHook } from "@/store/modules/user";
import { AgentDTOType } from "@/store/modules/types";
const dialogVisible = ref(false);
// 经销商List

const agentTableRef = ref();
const multipleSelection = ref([]);
const domainViewRef = ref();
const agentListData = ref<Array<agentType>>([]); // 当前表单list
const childDomainViewRef = ref(); // 选中的childDomain
const agentDTODataRef = ref<AgentDTOType>(); // 原始数据
const childDomainListDataRef = ref([]); // 选中domain之后的子
const callBackData = ref();
const props = defineProps({
  isMultipleSelection: {
    default: true,
    type: Boolean
  },
  agentId: Number
});
// 新闻管理进行同步更新的id
const closeDialog = () => {
  dialogVisible.value = false;
  domainViewRef.value = null;
  childDomainViewRef.value = null;
};
const handleSelectionChange = val => {
  multipleSelection.value = val;
};
const toggleSelection = async () => {
  const agentList: Array<agentType> = [];
  multipleSelection.value.forEach(item => {
    agentList.push(item);
  });
  if (agentList.length == 0) {
    message("未选择项目", { type: "error" });
    return;
  }
  selectioIds(agentList);
};

const init = async () => {
  agentDTODataRef.value = await useAppStoreHook().getAgentDTOList();
  domainViewRef.value = agentDTODataRef.value.domainList[0];
  selectDomainChange();
  let tempAgentId = props.agentId;
  if (!tempAgentId) {
    tempAgentId = useUserStoreHook().agentId;
  }
  if (tempAgentId) {
    const agent = agentDTODataRef.value.agentList.find(
      item => item.id === tempAgentId
    );
    emit("selectioAgentList", callBackData?.value, [agent]);
  }
};

const showDialog = (tempCallData: any) => {
  callBackData.value = tempCallData;
  dialogVisible.value = true;
  childDomainViewRef.value = null;
  init();
};

const selectDomainChange = async () => {
  childDomainListDataRef.value =
    agentDTODataRef.value.domainAndChildDomainMap.get(domainViewRef.value);
  if (childDomainListDataRef.value?.length > 0) {
    childDomainViewRef.value = childDomainListDataRef.value[0];
  }
  agentListData.value = agentDTODataRef.value.agentList.filter(
    item => item.domain == domainViewRef.value
  );
};

const selectChildDomainChange = async () => {
  agentListData.value = agentDTODataRef.value.agentList.filter(
    item =>
      item.domain == domainViewRef.value &&
      item.childDomain == childDomainViewRef.value
  );
};

const selectioIds = (selectAgentList: Array<agentType>) => {
  dialogVisible.value = false;
  emit("selectioAgentList", callBackData?.value, selectAgentList);
};

defineExpose({ showDialog, closeDialog, init });

const emit = defineEmits(["selectioAgentList"]);
</script>

<template>
  <el-dialog
    v-if="dialogVisible"
    v-model="dialogVisible"
    :align-center="true"
    width="60%"
    top="20vh"
    class="el-button-ml-reset h-[90vh]"
    @close="closeDialog"
  >
    <!-- 搜索 -->
    <div class="z-[9999] flex mb-[20px]">
      <div class="mr-[10px]">
        <el-select
          placeholder="选择domain进行搜索"
          @change="selectDomainChange"
          v-model="domainViewRef"
        >
          <el-option
            v-for="item in agentDTODataRef.domainList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
      <el-select
        placeholder="选择childDomain进行搜索"
        @change="selectChildDomainChange"
        v-model="childDomainViewRef"
      >
        <el-option
          v-for="item in childDomainListDataRef"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <div class="overflow-y-auto h-[60vh]">
      <el-table
        border
        ref="agentTableRef"
        :data="agentListData"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="props.isMultipleSelection"
          :type="props.isMultipleSelection ? 'selection' : 'default'"
          width="55"
        />
        <el-table-column prop="id" label="id" />
        <el-table-column prop="domain" label="domain">
          <template #default="scope">
            <CopyItem :content="`${scope.row.domain}`" />
          </template>
        </el-table-column>
        <el-table-column prop="childDomain" label="childDomain">
          <template #default="scope">
            <CopyItem :content="`${scope.row.childDomain}`" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" />
        <el-table-column v-if="!props.isMultipleSelection" label="操作">
          <template #default="scope">
            <el-button type="primary" @click="selectioIds([scope.row])"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button class="mr-[20px]" @click="closeDialog"> 取消 </el-button>
        <el-button
          v-if="props.isMultipleSelection"
          type="primary"
          @click="toggleSelection()"
        >
          确认
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

import { ss } from "@/utils";

export function defaultState(): Chat.ChatState {
  const uuid = 1002;
  return {
    active: uuid,
    usingContext: true,
    history: [{ uuid, title: "New Chat", isEdit: false }],
    chat: [{ uuid, data: [] }]
  };
}

export function getLocalState(key: string): Chat.ChatState {
  const LOCAL_NAME = key;
  const localState = ss.get(LOCAL_NAME);
  return { ...defaultState(), ...localState };
}

export function setLocalState(key: string, state: Chat.ChatState) {
  const LOCAL_NAME = key;
  ss.set(LOCAL_NAME, state);
}

// 菜单比较多，将 rank 抽离出来，在此方便维护
// 平台规定只有 home 路由的 rank 才能为 0 ，所以后端在返回 rank 的时候需要从 1 开始
const home = 0,
  aiTools = 1,
  techAdvantage = 2,
  live = 3,
  login = 4,
  news = 5,
  digitHuman = 6,
  InteractiveDigital = 7,
  partnerPlan = 8,
  imageAI = 9,
  chatgpt = 10,
  usercenter = 11,
  operate = 12,
  about = 13,
  buyChatGPT = 14,
  chatgptCategory = 15,
  aiCategory = 16,
  capcut = 17,
  guide = 18,
  admin = 19,
  aiPrice = 20,
  aiPoster = 21,
  websiteConfig = 22,
  payH5 = 23,
  digitAudioUpload = 24,
  digitTwins = 25,
  digitalCertificateUpload = 26,
  digitalHumanWorkbench = 27;
export {
  home,
  about,
  techAdvantage,
  login,
  operate,
  admin,
  news,
  live,
  chatgpt,
  buyChatGPT,
  digitHuman,
  InteractiveDigital,
  chatgptCategory,
  usercenter,
  imageAI,
  capcut,
  aiCategory,
  guide,
  aiTools,
  aiPrice,
  aiPoster,
  partnerPlan,
  websiteConfig,
  payH5,
  digitAudioUpload,
  digitTwins,
  digitalCertificateUpload,
  digitalHumanWorkbench
};

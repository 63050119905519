<script setup lang="ts">
import { ref, computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { deviceDetection } from "@pureadmin/utils";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
// import HomeBG from "../../assets/image-home/homebg.jpg";
import HomeBG from "../../assets/image-home/bg.png";
import { useTranslationLang } from "@/layout/hooks/useTranslationLang";
import { RouterNameEnum } from "@/config";
import { CommonUtils } from "@/utils/commonUtils";
import router from "@/router";
// 客服对话框
const serviceDialogRef = ref();
// 折扣码
const { locale } = useTranslationLang();
const isMobile = deviceDetection();
const { t } = useI18n();
const props = defineProps(["isGeCode"]);
// 计算属性来判断是否包含TCP
const hasTCP = () => {
  return useSystemConfigStoreHook().config.sysConfig.record.includes("TCP");
};
// 获得经销商权限是否开启
const openDealer = computed(() => {
  return useSystemConfigStoreHook().openDealer;
});
//获取能否使用折扣码
const openDiscount = computed(() => {
  return useSystemConfigStoreHook().openDiscount;
});
const agreementUrl = computed(() => {
  return `/agreement?type=1&lang=${locale.value}`;
});
const privacyUrl = computed(() => {
  return `/agreement?type=2&lang=${locale.value}`;
});
const handleShowServiceDialog = () => {
  emit("serviceDialog");
  // serviceDialogRef.value.showServiceDialog();
};
// const handleGetDiscount = () => {
//   if (props.isGeCode) {
//      router.push({ name: RouterNameEnum.PartnerPlan });
//   } else {
//     emit("discountDialog");
//   }
// };
const handleGetDiscount = () => {
  if (props.isGeCode) {
    // 只进行一次路由跳转，并设置正确的hash，确保页面能够正确滚动到指定位置
    router
      .push({ name: RouterNameEnum.PartnerPlan, hash: "#indexPage" })
      .then(() => {
        // 使用nextTick确保DOM已更新
        nextTick(() => {
          const element = document.getElementById("indexPage");
          if (element) {
            // 使用原生JS进行滚动定位
            element.scrollIntoView();
          }
        });
      });
  } else {
    emit("discountDialog");
  }
};
const emit = defineEmits(["discountDialog", "serviceDialog"]);
</script>

<template>
  <div class="z-[999] h-full bg-[#38405E]">
    <!-- 上半部 -->
    <div
      :class="`text-${useSystemConfigStoreHook().config.sysConfig.textColor}`"
      class="w-full flex flex-col items-center justify-center text-center !text-[#000] bg-cover bg-center"
      :style="{
        backgroundImage: 'url(' + HomeBG + ')',
        height: isMobile ? 'calc(100% - 200px)' : 'calc(100% - 250px)'
      }"
    >
      <div v-if="props.isGeCode">
        <p class="text-[35px] lg:text-[3.125rem] px-[2rem] font-[OPPOSansB]">
          {{ t("welcome.five.digitSimple") }}
        </p>
        <!-- 当开启了合伙人，才出现成为合伙人字样 -->
        <p
          v-if="openDealer"
          class="text-[35px] lg:text-[3.125rem] mt-[40px] font-[OPPOSansB]"
        >
          {{ t("welcome.five.partner") }}
        </p>
      </div>
      <div v-else>
        <template v-if="openDiscount">
          <p class="text-[25px] lg:text-[3.125rem] font-[OPPOSansB]">
            {{ t("welcome.five.nowsq") }}
          </p>
          <p class="text-[21px] lg:text-[3.125rem] mt-[40px] font-[OPPOSansB]">
            {{ t("welcome.five.highsend") }}
            <span class="text-primary text-[3.125rem] lg:text-[60px]">20%</span>
          </p>
        </template>
        <p
          v-else
          class="text-[35px] px-[2rem] lg:text-[3.125rem] font-[OPPOSansB]"
        >
          {{ t("welcome.five.digitSimple") }}
        </p>
      </div>
      <div
        class="flex flex-col lg:flex-row gap-[20px] lg:gap-[40px] mt-[3rem] lg:mt-[5rem]"
      >
        <div v-if="props.isGeCode">
          <div
            v-if="openDealer"
            class="my-press-button-no-shadow font-[OPPOSansB] my-gradient-green-left text-[30px] lg:text-[1.88rem] text-center text-white rounded-[25px] lg:rounded-[2.6rem] px-[2.6rem] py-[10px] lg:px-[7rem] lg:py-[0.7rem] hover:cursor-pointer"
            @click="handleGetDiscount"
          >
            {{ t("welcome.five.nowdo") }}
          </div>
          <div
            class="mt-[1rem] lg:mt-[0rem] my-press-button-no-shadow font-[OPPOSansB] my-gradient-green-left text-[30px] lg:text-[1.88rem] text-center text-white rounded-[25px] lg:rounded-[2.6rem] px-[2.6rem] py-[10px] lg:px-[7rem] lg:py-[0.7rem] hover:cursor-pointer"
            @click="handleShowServiceDialog"
            v-if="isMobile || !openDealer"
          >
            {{ t("welcome.five.contactUs") }}
          </div>
        </div>
        <div v-else>
          <div
            v-if="openDiscount"
            class="my-press-button-no-shadow font-[OPPOSansB] my-gradient-green-left text-[30px] lg:text-[1.88rem] text-center text-white rounded-[25px] lg:rounded-[2.6rem] px-[2.6rem] py-[10px] lg:px-[7rem] lg:py-[0.7rem] hover:cursor-pointer"
            @click="handleGetDiscount"
          >
            {{ t("welcome.five.nowdo") }}
          </div>
          <div
            class="mt-[1rem] lg:mt-[0rem] my-press-button-no-shadow font-[OPPOSansB] my-gradient-green-left text-[30px] lg:text-[1.88rem] text-center text-white rounded-[25px] lg:rounded-[2.6rem] px-[2.6rem] py-[10px] lg:px-[7rem] lg:py-[0.7rem] hover:cursor-pointer"
            @click="handleShowServiceDialog"
            v-if="isMobile || !openDiscount"
          >
            {{ t("welcome.five.contactUs") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 下半部 -->
    <div
      :class="`text-${useSystemConfigStoreHook().config.sysConfig.textColor}`"
      class="w-full h-[250px] flex flex-row items-center justify-center bg-[#030304]"
    >
      <!-- 公司信息 col-1 -->
      <div
        :class="`text-${useSystemConfigStoreHook().config.sysConfig.textColor}`"
        class="text-white text-[1rem] lg:text-[1.79rem] flex flex-col justify-center ml-[10vw] lg:ml-0"
      >
        <span class="mt-[1rem] lg:mt-[2rem] text-[1.38rem] text-left">
          <span class="lg:text-primary">
            {{ useSystemConfigStoreHook().config.sysConfig.appName }}
          </span>
          {{
            CommonUtils.getTranslateText(
              useSystemConfigStoreHook().config.sysConfig.slogan,
              locale
            )
          }}
        </span>
        <div
          class="text-[1rem] lg:text-[1rem] text-left gap-2 leading-[2rem] mt-[1rem]"
        >
          <p v-if="useSystemConfigStoreHook().config.sysConfig.contactPhone">
            <span class="font-bold text-primary">
              {{ t("welcome.footer.contactNumber") }}：</span
            >{{ useSystemConfigStoreHook().config.sysConfig.contactPhone }}
          </p>
          <p v-if="useSystemConfigStoreHook().config.sysConfig.contactEmail">
            <span class="font-bold text-primary">
              {{ t("welcome.footer.contactEmail") }}：</span
            >{{ useSystemConfigStoreHook().config.sysConfig.contactEmail }}
          </p>
          <p v-if="useSystemConfigStoreHook().config.sysConfig.contactLine">
            <span class="font-bold text-primary">
              {{ t("welcome.footer.contactLine") }}：</span
            >{{ useSystemConfigStoreHook().config.sysConfig.contactLine }}
          </p>
          <p>
            <span class="font-bold text-primary">
              {{ t("welcome.footer.officeAddress") }}：</span
            >
            <!-- {{ t("welcome.footer.officeAddressValue") }} -->
            {{ useSystemConfigStoreHook().config.sysConfig.contactAddress }}
          </p>
          <p class="text-[1rem] mt-[10px] text-[#888]">
            <!-- <span>Copyright © 2022 FutureAI 版权所有</span> -->
            <span>
              {{ useSystemConfigStoreHook().config.sysConfig.appName }}
            </span>
            <!-- <a href="" class="hover:text-primary">
              {{ " " + useSystemConfigStoreHook().config.sysConfig.record }}
            </a> -->
            <!-- 如果包含TCP，渲染可以点击的链接 -->
            <a
              v-if="hasTCP"
              href="https://beian.miit.gov.cn/#/Integrated/index"
              target="_blank"
              class="hover:text-primary"
            >
              {{ " " + useSystemConfigStoreHook().config.sysConfig.record }}
            </a>
            <!-- 如果不包含TCP，渲染不可点击的文本 -->
            <span v-else class="hover:text-primary">
              {{ " " + useSystemConfigStoreHook().config.sysConfig.record }}
            </span>
          </p>
        </div>
        <!-- <div class="text-[1.47rem] mt-[1.31rem]">地址：深圳湾前海1号</div> -->
      </div>
      <!-- 链接信息 col-2 -->
      <div
        :class="`text-${useSystemConfigStoreHook().config.sysConfig.textColor}`"
        class="hidden lg:flex text-[1rem] lg:text-[1.25rem] flex-row justify-between items-center ml-[10rem]"
      >
        <div class="flex flex-col text-left pr-[7rem] border-r-[0.5px]">
          <router-link
            to="/about-future-ai"
            class="font-bold text-[#ccc] hover:text-primary"
            >{{ t("welcome.footer.aboutUs") }}</router-link
          >
          <router-link
            :to="privacyUrl"
            target="_blank"
            class="mt-[1.69rem] text-[1rem] text-[#ccc] hover:text-primary"
          >
            {{ t("login.privacyPolicy") }}
          </router-link>
          <router-link
            :to="agreementUrl"
            target="_blank"
            class="mt-[1.69rem] text-[1rem] text-[#ccc] hover:text-primary"
          >
            {{ t("login.serviceAgreement") }}
          </router-link>
        </div>
      </div>
      <div
        class="flex-row justify-center hidden text-white lg:flex ml-[3rem]"
        v-if="useSystemConfigStoreHook().config.sourceImg?.serviceQrUrl"
      >
        <div
          class="bg-no-repeat bg-contain w-[130px] h-[130px] flex flex-c cursor-pointer"
          @click="handleShowServiceDialog"
        >
          <div class="relative">
            <img
              :src="useSystemConfigStoreHook().config.sourceImg?.serviceQrUrl"
              class="w-[130px] h-[130px]"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 客服对话框 -->
    <ContactDialog ref="serviceDialogRef" />
  </div>
</template>

<style lang="scss" scoped></style>

const Code = {
  INTERCE_UNKNOWN: { statusCode: -3, message: "请求数据错误" },
  PATH_ERROR: { statusCode: -2, message: "请求路径不存在" },
  UNKNOWN: { statusCode: -1, message: "unknown error" },
  SUCCESS: { statusCode: 0, message: "success" },
  CHAT_SUCCESS: { statusCode: 0, message: "end" },
  PARAMS_ERROR: { statusCode: 400, message: "请求参数错误" },
  ACCOUNT_NOT_LOGIN: { statusCode: 401, message: "账户未登录" },
  ACCESS_DENIED: { statusCode: 403, message: "拒绝访问" },
  REQUEST_PATH_ERROR: { statusCode: 404, message: "请求路径错误" },
  REQUEST_METHOD_ERROR: { statusCode: 405, message: "请求方法错误" },
  TO_MANY_QUEST: { statusCode: 409, message: "请求频繁,请稍后尝试" },
  SERVER_ERROR: { statusCode: 500, message: "服务器错误" },
  ACCOUNT_ALREADY_EXISTS: { statusCode: 1001, message: "账户已存在" },
  USER_NAME_FORMAT_ERROR: { statusCode: 1002, message: "邮箱格式不正确" },
  ACCOUNT_ERROR: { statusCode: 1003, message: "用户名或密码错误" },
  ACCOUNT_NOT_EXIST: { statusCode: 1004, message: "用户名不存在" },
  LOGIN_ERROR: { statusCode: 1005, message: "登录失效,请重新登陆" },
  LOGIN_DUPLICATE: { statusCode: 1114, message: "账户已在其它设备登陆" },

  VERIFICATION_CODE_ERROR: { statusCode: 1006, message: "验证码错误" },
  NAME_PASS_NOT_EMPTY: { statusCode: 1007, message: "用户名或密码不能为空" },
  NAME_ERROR: { statusCode: 1008, message: "用户名格式错误" },
  PASS_LENGTH_ERROR: { statusCode: 1009, message: "密码长度错误...." },
  PARAMS_NULL: { statusCode: 1010, message: "请求参数不能为空" },
  TIME_OUT: { statusCode: 1011, message: "请求超时" },
  USERNAME_ERROR: { statusCode: 1012, message: "用户名格式错误" },
  PASS_ERROR: { statusCode: 1013, message: "密码错误" },
  VERI_CODE_ERROR: { statusCode: 1014, message: "验证码错误" },
  OSS_DIR_ERROR: { statusCode: 1015, message: "oss上传目录错误" },
  FILE_TYPE_ERROR: { statusCode: 1016, message: "上传文件类型错误" },
  INSUFFICIENT_PERMISSIONS: { statusCode: 1017, message: "权限不足" },
  GROUP_ID_ERROR: { statusCode: 1017, message: "group id 不存在" },
  AMOUNT_ERROR: { statusCode: 1018, message: "模特数量和时间不能同时为零" },
  NOT_ENOUGH_TIMES_ERROR: { statusCode: 1019, message: "时间不足,扣除失败" },
  INVITE_CODE_ERROR: {
    statusCode: 1020,
    message: "邀请码,不能为空,请联系客服人员"
  },
  NOT_EXIST_INVITE_CODE: { statusCode: 1021, message: "邀请码填写错误" },
  NOT_ENOUGH_MODELS_ERROR: {
    statusCode: 1022,
    message: "模特数量不足,扣除失败"
  },
  TRAINING_CALL_ERROR: { statusCode: 1023, message: "训练模特回调失败" },
  SIGN_ERROR: { statusCode: 1024, message: "签名校验失败" },
  DURATION_ERROR: { statusCode: 1025, message: "获取时长失败" },
  UPDATE_ORDER_ERROR: { statusCode: 1026, message: "更新订单状态失败" },
  MODEL_NOT_EXIST: {
    statusCode: 1027,
    message: "更新模特状态失败,不存在当前模特id"
  },
  ORDER_NOT_EXIST: {
    statusCode: 1028,
    message: "更新订单状态失败,不存在当前订单id"
  },
  UPDATE_MODEL_ERROR: { statusCode: 1029, message: "更新模特状态失败" },

  ORDER_ALREADY_DEAL: {
    statusCode: 1030,
    message: "当前订单已经处理过了,不能重复调用"
  },
  MODEL_ALREADY_DEAL: {
    statusCode: 1031,
    message: "当前模特已经处理过了,不能重复调用"
  },
  GEE_TEST_ERROR: { statusCode: 1032, message: "验证失败,请重新尝试" },
  VERIFICATION_ERROR: { statusCode: 1033, message: "验证码错误,请重新输入" },
  VERIFICATION_EXPIRE_OUT_TIMED: {
    statusCode: 1034,
    message: "验证码超时,请重新输入"
  },
  VERIFICATION_EXPIRED: { statusCode: 1034, message: "验证码过期,请重新输入" },
  CREATE_VIDEO_LENGTH_ERROR: {
    statusCode: 1035,
    message: "请输入合适的文本长度"
  },
  NOT_HAVE_SHORT_NAME: { statusCode: 1036, message: "发音人code不存在" },
  NOT_VOICE_ROLE_NAME: { statusCode: 1037, message: "发音人role不存在" },
  NOT_VOICE_STYLE_NAME: { statusCode: 1038, message: "发音人style不存在" },
  NOT_TEXT_EMPTY: { statusCode: 1039, message: "文本为空" },
  MODEL_NOT_ENABLE: { statusCode: 1040, message: "模特暂不可用" },
  MODEL_TRAINING: { statusCode: 1041, message: "模特合成中,暂不可用" },
  VIDEO_EXIST: { statusCode: 1042, message: "当前视频已经提交,请勿重复提交" },
  CATEGORY_ERROR: { statusCode: 1043, message: "category错误" },

  NOT_ENOUGH_COUNT: {
    statusCode: 1044,
    message: "可用次数不足,请联系客服人员"
  },
  // chatgpt错误
  CHAT_SYS_ERROR: { statusCode: 1045, message: "系统繁忙,请重试" },
  CHAT_TO_LONG: {
    statusCode: 2046,
    message: "字数太长,最长8000字符."
  },
  CHAT_WAITING: { statusCode: 1046, message: "请等待之前的问题回答结束." },

  CHAT_SYS_CONNECT_ERROR: { statusCode: 1047, message: "连接失败,请重试" },
  LIMIT_REACHED: {
    statusCode: 2047,
    message: "Open api request limit reached"
  },
  // 服务器错误
  CHAT_LEVEL_NOT_EXIST: { statusCode: 1048, message: "不存在当前会员级别" },

  CREATE_GPT_ERROR: { statusCode: 1049, message: "订阅失败,请联系客服" },
  CHILD_DOMAIN_ERROR: {
    statusCode: -7,
    message: "child domain error",
    messageEn: "child domain error"
  },
  MAIN_KEY_ERROR: {
    statusCode: -6,
    message: "main key  error",
    messageEn: "main key  error"
  },
  SECOND_CONFIG_ERROR: {
    statusCode: -5,
    message: "second config error",
    messageEn: "second config error"
  },
  SECOND_KEY_ERROR: {
    statusCode: -4,
    message: "second key error",
    messageEn: "second key error"
  },
  MAIN_KEY_NOT_INCLUDE_SPACE: {
    statusCode: 1142,
    message: "二级域名的key不能包含空格",
    messageEn: "The key of the second-level domain name cannot contain spaces."
  },
  MAIN_KEY_ACCESS_DENIED: {
    statusCode: 1143,
    message: "请使用正确的域名访问:",
    messageEn: "Please use the correct domain name to access"
  }
};
export { Code };

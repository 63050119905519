import { Constants, RouterNameEnum } from "@/config";
import { payH5 } from "../enums";
const PayH5 = () => import("@/views/pay-h5/index.vue");
export default {
  path: "/pay-h5",
  redirect: "/pay-h5/index",
  meta: {
    title: "支付",
    rank: payH5,
    roles: Constants.Roles.ALL,
    showLink: false
  },
  children: [
    {
      path: "/pay-h5/index",
      name: RouterNameEnum.PayH5,
      component: PayH5,
      meta: {
        title: "支付",
        showLink: false
      }
    }
  ]
} as RouteConfigsTable;

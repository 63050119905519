<script setup lang="ts">
import CloseBold from "@iconify-icons/ep/close-bold";
import { Motion } from "@/utils";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useSystemConfigStoreHook } from "@/store/modules/systemConfig";
import { Clipboard } from "v-clipboard";
import { message } from "@/utils";

const { t } = useI18n();

// 客服对话框
const serviceDialogVisible = ref(false);
const showServiceDialog = () => {
  serviceDialogVisible.value = true;
};
const copy = value => {
  Clipboard.copy(value);
  message(t("common.copySuccess") + " " + value, { type: "success" });
};
defineExpose({ showServiceDialog });
</script>

<template>
  <div v-if="serviceDialogVisible" class="modal-overlay">
    <Motion class="w-full h-full flex-c">
      <div
        class="bg-[#fff] w-[90%] lg:w-[65.19rem] pb-[2rem] lg:pb-[4rem] relative lg:rounded-[0.63rem] flex flex-col items-center"
      >
        <IconifyIconOffline
          :icon="CloseBold"
          @click="serviceDialogVisible = false"
          class="absolute text-[#676565] right-0 lg:text-[#fff] lg:right-[-70px] lg:top-[-10px] text-[3rem] transition duration-300 hover:cursor-pointer hover:-rotate-45 hover:text-primary"
        />

        <div class="relative">
          <div class="textColor mt-[4rem] text-[2.82rem] font-[OPPOSansB]">
            {{ t("dialog.contact") }}
          </div>
          <img
            alt="Future AI"
            :src="useSystemConfigStoreHook().config.sourceImg?.serviceQrUrl"
            class="w-[11.56rem] h-[11.56rem] mt-[6rem] lg:mt-[2rem] z-[9999] rounded-[0.4rem]"
          />
        </div>
        <p class="text-[1.1rem] lg:text-[1.125rem] text-[#3d3d3d]">
          {{ t("dialog.contactWechat") }}
        </p>
        <!-- 底下联系框 -->
        <div
          class="flex flex-col lg:flex-row mt-[3.25rem] lg:mt-[3rem] justify-center items-center"
        >
          <div
            v-if="useSystemConfigStoreHook().config.sysConfig.contactEmail"
            class="w-[20rem] border-[#D8D8D8] mb-[1rem] flex justify-center lg:border-r-[0.5px]"
          >
            <div class="flex flex-col justify-start">
              <div
                class="text-[#3d3d3d] mb-[0.5rem] text-[1.29rem] font-[OPPOSansM] font-[700] text-start"
              >
                {{ t("table.email") }}
              </div>
              <div class="text-[1.29rem] text-[#3d3d3d]">
                {{ useSystemConfigStoreHook().config.sysConfig.contactEmail }}
                <el-link
                  class="!text-[0.94rem] text-primary ml-[1.5rem]"
                  :underline="false"
                  type="primary"
                  @click="
                    copy(
                      useSystemConfigStoreHook().config.sysConfig.contactEmail
                    )
                  "
                >
                  {{ t("common.copy") }}
                </el-link>
              </div>
            </div>
          </div>
          <div
            v-if="useSystemConfigStoreHook().config.sysConfig.contactPhone"
            class="w-[15.44rem] lg:border-r-[0.5px] border-[#D8D8D8] mb-[1rem] flex justify-center"
          >
            <div class="flex flex-col justify-start">
              <div
                class="text-[#3d3d3d] mb-[0.5rem] text-[1.29rem] font-[700] text-start"
              >
                {{ t("table.phone") }}
              </div>
              <div class="text-[1.29rem] text-[#3d3d3d] flex items-center">
                {{ useSystemConfigStoreHook().config.sysConfig.contactPhone }}
                <el-link
                  class="!text-[0.94rem] text-primary ml-[1.5rem]"
                  :underline="false"
                  type="primary"
                  @click="
                    copy(
                      useSystemConfigStoreHook().config.sysConfig.contactPhone
                    )
                  "
                >
                  {{ t("common.copy") }}
                </el-link>
              </div>
            </div>
          </div>
          <div
            v-if="useSystemConfigStoreHook().config.sysConfig.contactLine"
            class="w-[20rem] border-[#D8D8D8] pl-0 mb-[1rem] flex justify-center"
          >
            <div class="flex flex-col justify-start">
              <div
                class="text-[#3d3d3d] mb-[0.5rem] text-[1.29rem] font-[700] text-start"
              >
                Line
              </div>
              <div class="text-[1.29rem] text-[#3d3d3d] flex items-center">
                {{ useSystemConfigStoreHook().config.sysConfig.contactLine
                }}<el-link
                  class="!text-[0.94rem] ml-[1.5rem] text-primary"
                  :underline="false"
                  type="primary"
                  @click="
                    copy(
                      useSystemConfigStoreHook().config.sysConfig.contactLine
                    )
                  "
                >
                  {{ t("common.copy") }}
                </el-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Motion>
  </div>
</template>

<style scoped>
.textColor {
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  background: linear-gradient(
    194deg,
    #81daff -15%,
    var(--el-color-primary) 118%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
</style>

import intelligenceTraining from "@/assets/intelligence-study/banner/card-intelligence-training.png";
import interactiveDigital from "@/assets/intelligence-study/banner/card-interactive-digital.png";
import liveHuman from "@/assets/intelligence-study/banner/card-live-human.png";
import digitHuman from "@/assets/intelligence-study/banner/card-digit-human.png";

import customizedModel from "@/assets/intelligence-study/banner/card-customized-model.png";
import imageAi from "@/assets/intelligence-study/banner/card-image-ai.png";
import outstandingCase from "@/assets/intelligence-study/banner/card-outstanding-case.png";
import partnerPlan from "@/assets/intelligence-study/banner/card-partner-plan.png";

// import interactiveDigitalPeople from "@/assets/intelligence-study/banner/interactive-digital-people.png";
// import trainingBase from "@/assets/intelligence-study/banner/training-base.png";

import intelligenceTrainingGreen from "@/assets/intelligence-study/banner/card-intelligence-training-green.png";
import interactiveDigitalGreen from "@/assets/intelligence-study/banner/card-interactive-digital-green.png";
import liveHumanGreen from "@/assets/intelligence-study/banner/card-live-human-green.png";
import digitHumanGreen from "@/assets/intelligence-study/banner/card-digit-human-green.png";

import customizedModelGreen from "@/assets/intelligence-study/banner/card-customized-model-green.png";
import imageAiGreen from "@/assets/intelligence-study/banner/card-image-ai-green.png";
import outstandingCaseGreen from "@/assets/intelligence-study/banner/card-outstanding-case-green.png";
import partnerPlanGreen from "@/assets/intelligence-study/banner/card-partner-plan-green.png";

// import trainingBaseGreen from "@/assets/intelligence-study/banner/training-base-green.png";
// import interactiveDigitalPeopleGreen from "@/assets/intelligence-study/banner/interactive-digital-people-green.png";

// import ministryOfIndustryBg from "@/assets/intelligence-study/banner/ministry-of-industry-bg.png";
// import digitalBg from "@/assets/intelligence-study/banner/digital-bg.png";

// import ministryOfIndustryBgGreen from "@/assets/intelligence-study/banner/ministry-of-industry-bg-green.png";
// import digitalBgGreen from "@/assets/intelligence-study/banner/digital-bg-green.png";

import digitBgBlue from "@/assets/intelligence-study/three/digitBgBlue.png";
import digitBgGreen from "@/assets/intelligence-study/three/digitBgGreen.png";
import digitBgGreenPhone from "@/assets/intelligence-study/three/digitBgGreenPhone.png";
import digitBgBluePhone from "@/assets/intelligence-study/three/digitBgBluePhone.png";

import welcomeNewsLogo from "@/assets/welcome/welcome-news-logo.png";
import welcomeNewsLogoGreen from "@/assets/welcome/welcome-news-logo-green.png";

import aboutHomePageBg from "@/assets/about/about-home-page-bg.png";
import aboutHomePageBgGreen from "@/assets/about/about-home-page-bg-green.png";
import aboutAIGC from "@/assets/about/about-AIGC.png";
import aboutAIGCGreen from "@/assets/about/about-AIGC-grenn.png";
import aboutAIGCPhone from "@/assets/about/about-AIGC-phone.png";
import aboutAIGCGreenPhone from "@/assets/about/about-AIGC-grenn-phone.png";
import aboutProjectOverview from "@/assets/about/about-project-overview.png";
import aboutProjectOverviewGreen from "@/assets/about/about-project-overview-green.png";
import aboutProjectOverviewPhone from "@/assets/about/about-project-overview-phone.png";
import aboutProjectOverviewGreenPhone from "@/assets/about/about-project-overview-green-phone.png";
import aboutProjectBg from "@/assets/about/about-computer.png";
import aboutProjectBgGreen from "@/assets/about/about-computer-green.png";

import productFeatures from "@/assets/welfuture-ai/welfuture-ai-product-features.png";
import productFeaturesGreen from "@/assets/welfuture-ai/welfuture-ai-product-features-green.png";
import intelligenceTrainingBg from "@/assets/intelligence-training/two/course-modules.png";
import intelligenceTrainingBgGreen from "@/assets/intelligence-training/two/course-modules-green.png";

import teachingObjectivesLeftGreen from "@/assets/intelligence-training/three/teaching-objectives-left-green.png";
import teachingObjectivesLeft from "@/assets/intelligence-training/three/teaching-objectives-left.png";
import teachingObjectivesRightGreen from "@/assets/intelligence-training/three/teaching-objectives-right-green.png";
import teachingObjectivesRight from "@/assets/intelligence-training/three/teaching-objectives-right.png";

import cityBgGreen from "@/assets/intelligence-training/five/city-bg-green.png";
import cityBg from "@/assets/intelligence-training/five/city-bg.png";
import applicablePeople from "@/assets/intelligence-training/one/applicablePeople.png";
import applicablePeopleGreen from "@/assets/intelligence-training/one/applicablePeopleGreen.png";
import commonRechargeBlue from "@/assets/commonRecharge/commonRechargeBlue.png";
import commonRechargeGreen from "@/assets/commonRecharge/commonRechargeGreen.png";

import digitalCertificateBlue from "@/assets/digital-certificate/certificate-bg.png";
import digitalCertificatePhoneBlue from "@/assets/digital-certificate/certificate-bg-phone.png";
import digitalCertificate from "@/assets/digital-certificate/certificate-bg-green.png";
import digitalCertificatePhone from "@/assets/digital-certificate/certificate-bg-phone-green.png";
export const GetSourceFromTheme = theme => {
  // skyBlue
  if (theme == "skyBlue") {
    return {
      banner: {
        // banner底部四个卡片图片
        buttonList: {
          intelligenceTraining: intelligenceTraining, //人工智能实训
          interactiveDigital: interactiveDigital, //交互式数字人
          liveHuman: liveHuman, //数字人直播
          digitHuman: digitHuman, //数字人定制
          customizedModel: customizedModel, //定制化模型
          imageAi: imageAi, //ai作画
          outstandingCase: outstandingCase, //优秀案例
          partnerPlan: partnerPlan, //合伙人计划,
          workBench: outstandingCaseGreen //工作台
        }
      },
      commonRecharge: commonRechargeBlue, //充值积分页面背景图
      digitBg: digitBgBlue, // 首页最后一页交互式数字人背景图
      digitBgPhone: digitBgBluePhone, // 首页最后一页交互式数字人背景图手机版
      welcomeNewsLogo: welcomeNewsLogo, //登陆弹出的新闻的左上角logo,
      about: {
        aboutHomePageBg: aboutHomePageBg, //关于我们页面首页背景图
        aboutAIGC: aboutAIGC, //关于我们页面AIGC图片
        aboutAIGCPhone: aboutAIGCPhone, //关于我们页面AIGC图片手机版
        aboutProjectBg: aboutProjectBg, //关于我们页面项目概览电脑图片
        aboutProjectOverview: aboutProjectOverview, //关于我们页面项目概览背景图
        aboutProjectOverviewPhone: aboutProjectOverviewPhone //关于我们页面项目概览背景图手机版
      },
      //交互式数字人
      interactive: {
        productFeatures: productFeatures //交互式数字人的产品功能背景
      },
      //人工智能运营师
      artificialintelligence: {
        applicablePeople: applicablePeople, //人工智能运营师第一页图片
        //人工智能运营师的课程模块-职业技能背景图
        intelligenceTrainingBg: intelligenceTrainingBg, //人工智能运营师-课程模块-职业技能背景图
        teachingObjectivesLeft: teachingObjectivesLeft, //人工智能运营师-教学目标-左边的背景图
        teachingObjectivesRight: teachingObjectivesRight, //人工智能运营师-教学目标-右边的背景图
        cityBg: cityBg //人工智能运营师-智启时代认证赋能背景图
      },
      // 作品著作登记界面
      digitalCertificate: {
        digitalCertificateBg: digitalCertificateBlue, //作品著作登记界面背景图
        digitalCertificatePhoneBg: digitalCertificatePhoneBlue //作品著作登记界面背景图
      }
    };
  } else {
    return {
      banner: {
        // banner底部四个卡片图片
        buttonList: {
          intelligenceTraining: intelligenceTrainingGreen, //人工智能实训
          interactiveDigital: interactiveDigitalGreen, //交互式数字人
          liveHuman: liveHumanGreen, //数字人直播
          digitHuman: digitHumanGreen, //数字人定制
          customizedModel: customizedModelGreen, //定制化模型
          imageAi: imageAiGreen, //ai作画
          outstandingCase: outstandingCaseGreen, //优秀案例
          partnerPlan: partnerPlanGreen, //合伙人计划,
          workBench: outstandingCaseGreen //工作台
        }
      },
      digitBg: digitBgGreen, //首页最后一页交互式数字人背景图
      digitBgPhone: digitBgGreenPhone, // 首页最后一页交互式数字人背景图手机版
      welcomeNewsLogo: welcomeNewsLogoGreen, //登陆弹出的新闻的左上角logo
      commonRecharge: commonRechargeGreen, //充值积分页面背景图
      about: {
        aboutHomePageBg: aboutHomePageBgGreen, //关于我们页面首页背景图
        aboutAIGC: aboutAIGCGreen, //关于我们页面AIGC图片,
        aboutAIGCPhone: aboutAIGCGreenPhone, //关于我们页面AIGC图片手机版
        aboutProjectBg: aboutProjectBgGreen, //关于我们页面项目概览电脑图片
        aboutProjectOverview: aboutProjectOverviewGreen, //关于我们页面项目概览背景图
        aboutProjectOverviewPhone: aboutProjectOverviewGreenPhone //关于我们页面项目概览背景图手机版
      },
      //交互式数字人
      interactive: {
        productFeatures: productFeaturesGreen //交互式数字人的产品功能背景
      },
      //人工智能运营师
      artificialintelligence: {
        applicablePeople: applicablePeopleGreen, //人工智能运营师第一页图片
        intelligenceTrainingBg: intelligenceTrainingBgGreen, //人工智能运营师-课程模块-职业技能背景图
        teachingObjectivesLeft: teachingObjectivesLeftGreen, //人工智能运营师-教学目标-左边的背景图
        teachingObjectivesRight: teachingObjectivesRightGreen, //人工智能运营师-教学目标-右边的背景图
        cityBg: cityBgGreen //人工智能运营师-智启时代认证赋能背景图
      },
      // 作品著作登记界面
      digitalCertificate: {
        digitalCertificateBg: digitalCertificate, //作品著作登记界面背景图
        digitalCertificatePhoneBg: digitalCertificatePhone //作品著作登记界面背景图
      }
    };
  }
};

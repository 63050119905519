import CryptoJS from "crypto-js";
import SparkMD5 from "spark-md5";
const CryptoSecret = "__CRYPTO_SECRET__";
import { Buffer } from "buffer";
import { CommonUtils } from "../commonUtils";
export function enCrypto(data: any) {
  const str = CommonUtils.getJSON(data);
  return CryptoJS.AES.encrypt(str, CryptoSecret).toString();
}

export function deCrypto(data: string) {
  const bytes = CryptoJS.AES.decrypt(data, CryptoSecret);
  const str = bytes.toString(CryptoJS.enc.Utf8);

  if (str) return CommonUtils.getJSONObj(str);

  return null;
}
export function getFileHash(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result instanceof ArrayBuffer) {
        const uint8Array = new Uint8Array(event.target.result);
        const wordArray = CryptoJS.lib.WordArray.create(Array.from(uint8Array));
        const hash = CryptoJS.SHA256(wordArray);
        resolve(hash.toString(CryptoJS.enc.Hex));
      } else {
        reject(new Error("File read error"));
      }
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function computeMD5(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        const spark = new SparkMD5.ArrayBuffer();
        spark.append(e.target.result as ArrayBuffer);
        resolve(spark.end());
      } else {
        reject(new Error("File read error"));
      }
    };
    reader.onerror = () => {
      reject(new Error("File read error"));
    };
    const fileSize = file.size;
    // 如果文件大于 20MB，只读取前 20MB
    if (fileSize > 20 * 1024 * 1024) {
      const blob = file.slice(0, 20 * 1024 * 1024);
      reader.readAsArrayBuffer(blob);
    } else {
      // 如果文件小于或等于 20MB，读取整个文件
      reader.readAsArrayBuffer(file);
    }
    // reader.readAsArrayBuffer(file);
  });
}

/**读取文件原信息 */
export function readFileMetaData(file: any) {
  return new Promise<number>((resolve, reject) => {
    try {
      const audioElement = new Audio(URL.createObjectURL(file));
      let duration;
      audioElement.addEventListener("loadedmetadata", _event => {
        duration = audioElement.duration;
        console.log("元信息", audioElement);
        console.log(duration); //单位：秒
        resolve(parseInt(duration));
      });
      audioElement.addEventListener("error", _event => {
        reject(new Error("read metadata failed"));
      });
    } catch (err) {
      reject(err);
    }
  });
}
export function byteToHex(data: any, length?: number) {
  const buffer = Buffer.from(data, "binary");
  if (length && length > 0) {
    return buffer.slice(0, length).toString("hex").toLowerCase();
  } else {
    return buffer.toString("hex").toLowerCase();
  }
}

export function createStrMd5(data: string) {
  const hash = CryptoJS.MD5(data)
    .toString(CryptoJS.enc.Hex)
    ?.toLocaleLowerCase();
  return hash;
}

export function getFileArrayBuffer(file: any) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = function (evt) {
        if (evt.target.readyState == FileReader.DONE) {
          const arrayBuffer = evt.target.result as ArrayBuffer;
          resolve(arrayBuffer);
        }
      };
    } catch (err) {
      reject(err);
    }
  });
}
/**获取文件原信息 */
export async function getFileType(file: any) {
  const arrayBuffer = await getFileArrayBuffer(file);
  const firstBytes = byteToHex(arrayBuffer);
  let type = null;
  if (firstBytes.startsWith("89504e47")) {
    // PNG magic number
    type = "png";
  } else if (firstBytes.startsWith("ffd8ff")) {
    // JPG magic number
    type = "jpg";
  } else if (
    firstBytes.startsWith("474946383761") ||
    firstBytes.startsWith("474946383961")
  ) {
    // GIF magic number
    type = "gif";
  } else if (firstBytes.startsWith("52494646") && firstBytes.length > 16) {
    // WebP magic number
    if ((firstBytes + "").substring(16).startsWith("57454250")) {
      type = "webp";
    }
  }
  console.log("文件类型", type);
  return type;
}

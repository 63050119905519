export const NetworkUtils = {
  isPrivateIP(ip: string): boolean {
    if (ip.includes("://")) {
      ip = ip.substring(ip.indexOf("://") + 3);
    }
    if (ip.includes("localhost")) {
      return true;
    }
    const parts = ip.split(".").map(str => parseInt(str, 10));
    if (parts[0] === 127) {
      return true;
    }
    // 10.x.y.z
    if (parts[0] === 10) {
      return true;
    }

    // 172.16.0.0 - 172.31.255.255
    if (parts[0] === 172 && parts[1] >= 16 && parts[1] <= 31) {
      return true;
    }

    // 192.168.x.y
    if (parts[0] === 192 && parts[1] === 168) {
      return true;
    }

    return false;
  }
};

/**
 * 转义 HTML 字符
 * @param source
 */
export function encodeHTML(source: string) {
  return source
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");
}

/**
 * 判断是否为代码块
 * @param text
 */
export function includeCode(text: string | null | undefined) {
  const regexp = /^(?:\s{4}|\t).+/gm;
  return !!(text?.includes(" = ") || text?.match(regexp));
}

export function isHtml(text) {
  // 正则表达式匹配标签开头的'<'，可选的'/',
  // 然后是字母或数字的序列（标签名），接着是任意数量的非'>'字符，最后是闭合的'>'
  const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return htmlRegex.test(text);
}

/**
 * 复制文本
 * @param options
 */
export function copyText(options: { text: string; origin?: boolean }) {
  const props = { origin: true, ...options };

  let input: HTMLInputElement | HTMLTextAreaElement;

  if (props.origin) input = document.createElement("textarea");
  else input = document.createElement("input");

  input.setAttribute("readonly", "readonly");
  input.value = props.text;
  document.body.appendChild(input);
  input.select();
  if (document.execCommand("copy")) document.execCommand("copy");
  document.body.removeChild(input);
}

import Axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CustomParamsSerializer,
  InternalAxiosRequestConfig
} from "axios";
import {
  PureHttpError,
  RequestMethods,
  PureHttpResponse,
  PureHttpRequestConfig
} from "./types";
import { stringify } from "qs";
import { NProgress } from "../progress";
import { getToken, formatToken } from "../auth";
import { useUserStoreHook } from "@/store/modules/user";
import { message } from "../message";
import { Code, PlatformEnum } from "@/config";
import { emitter } from "../mitt";
import { DeviceUtils } from "../DeviceUtils";
import { initlang } from "@/utils/responsive";
import { CommonUtils } from "../commonUtils";
// 第一个代理后端地址
const { VITE_DOMAIN } = import.meta.env;
export const baseUrlApi = (url: string) => `${VITE_DOMAIN}/v1/${url}`;
// 第二个 后台管理系统配置midjourney绘画
export const baseUrlOtherApi = (url: string) => `https://www.iiii.com/${url}`;
// 相关配置请参考：www.axios-js.com/zh-cn/docs/#axios-request-config-1
const defaultConfig: AxiosRequestConfig = {
  // 请求超时时间
  timeout: 1000 * 60 * 5,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  },
  // 数组格式参数序列化（https://github.com/axios/axios/issues/5142）
  paramsSerializer: {
    serialize: stringify as unknown as CustomParamsSerializer
  }
};

class PureHttp {
  constructor() {
    this.httpInterceptorsRequest();
    this.httpInterceptorsResponse();
  }

  /** token过期后，暂存待执行的请求 */
  private static requests = [];
  /** 初始化配置对象 */
  private static initConfig: PureHttpRequestConfig = {};

  /** 保存当前Axios实例对象 */
  private static axiosInstance: AxiosInstance = Axios.create(defaultConfig);

  /** 重连原始请求 */
  private static retryOriginalRequest(config: PureHttpRequestConfig) {
    return new Promise(resolve => {
      PureHttp.requests.push((token: string) => {
        config.headers["Authorization"] = formatToken(token);
        resolve(config);
      });
    });
  }

  /** 请求拦截 */
  private httpInterceptorsRequest(): void {
    PureHttp.axiosInstance.interceptors.request.use(
      async (
        config: InternalAxiosRequestConfig
      ): Promise<InternalAxiosRequestConfig> => {
        // 开启进度条动画
        NProgress.start();
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof config["beforeRequestCallback"] === "function") {
          config["beforeRequestCallback"](config);
          return config;
        }
        if (PureHttp.initConfig.beforeRequestCallback) {
          PureHttp.initConfig.beforeRequestCallback(config);
          return config;
        }
        /** 请求白名单，放置一些不需要token的接口（通过设置请求白名单，防止token过期后再请求造成的死循环问题） */
        const whiteList = ["users/register", "users/login"];
        const reqPromise = new Promise(resolve => {
          const data = getToken();
          const locale = initlang();
          // 未登录也要发语言环境
          config.headers["lang"] = locale;
          // 发送当前网站配置信息
          // const href = formatUtils.getHref().toLowerCase();
          // if (NetworkUtils.isPrivateIP(href)) {
          //   config.headers["localhost"] = "www";
          // } else {
          //   config.headers["key"] = href;
          // }
          // 微信支付使用
          // config.headers["Referer"] = "https://www.iiii.com";
          //  当前设备信息
          config.headers["platform"] = DeviceUtils.isMobile()
            ? PlatformEnum.MOBILE
            : PlatformEnum.PC;
          if (data) {
            const { token } = data;
            if (CommonUtils.getUserInfo()) {
              config.headers["Authorization"] = formatToken(token);
              PureHttp.requests.forEach(cb => cb(token));
            }
            PureHttp.requests = [];
            resolve(config as any);
          } else {
            //
            resolve(config as any);
          }
        });
        // true/false 有/没有 满足条件的元素
        const result = whiteList.some(v => config.url.indexOf(v) > -1)
          ? config
          : reqPromise;
        return result as any;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  /** 响应拦截 */
  private httpInterceptorsResponse(): void {
    const instance = PureHttp.axiosInstance;
    instance.interceptors.response.use(
      (response: PureHttpResponse) => {
        const $config = response.config;
        // 关闭进度条动画
        NProgress.done();
        // 优先判断post/get等方法是否传入回掉，否则执行初始化设置等回掉
        if (typeof $config.beforeResponseCallback === "function") {
          $config.beforeResponseCallback(response);
          return response.data;
        }
        if (PureHttp.initConfig.beforeResponseCallback) {
          PureHttp.initConfig.beforeResponseCallback(response);
          return response.data;
        }
        return response.data;
      },
      (error: PureHttpError) => {
        const $error = error;
        $error.isCancelRequest = Axios.isCancel($error);
        // 关闭进度条动画
        NProgress.done();
        // 所有的响应异常 区分来源为取消请求/非取消请求
        return Promise.reject($error);
      }
    );
  }

  /** 通用请求工具函数 */
  public request<T>(
    method: RequestMethods,
    url: string,
    param?: AxiosRequestConfig,
    axiosConfig?: PureHttpRequestConfig
  ): Promise<T> {
    if (url.startsWith("draw")) {
      url = baseUrlOtherApi(url);
    } else {
      url = baseUrlApi(url);
    }
    const config = {
      method,
      url,
      ...param,
      ...axiosConfig
    } as PureHttpRequestConfig;

    // 单独处理自定义请求/响应回掉
    return new Promise((resolve, reject) => {
      PureHttp.axiosInstance
        .request(config)
        .then((response: any) => {
          // 请求成功
          if (response.statusCode === Code.SUCCESS.statusCode) {
            resolve(response);
          } else {
            // 登陆失效，token 过期 或者 账户已在其它设备登陆
            if (
              response.statusCode === Code.LOGIN_ERROR.statusCode ||
              response.statusCode === Code.LOGIN_DUPLICATE.statusCode
            ) {
              // 如果用户被剔下线之前打开了充值对话框,并在踢下线之后调用支付二维码 需要关闭充值对话框
              emitter.emit("closePayBusinessTypeDialog");
              useUserStoreHook().clearUserInfo();
            }
            message(response.message, { type: "error" });
            resolve(response);
          }
        })
        .catch(error => {
          // 全局打印错误信息 非200响应码的错误
          message(error, { type: "error" });
          console.log(error);

          reject(error);
        });
    });
  }

  /** 单独抽离的post工具函数 */
  public post<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("post", url, params, config);
  }

  /** 单独抽离的get工具函数 */
  public get<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("get", url, params, config);
  }

  /** 单独抽离的patch工具函数 */
  public patch<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("patch", url, params, config);
  }
  /** 单独抽离的put工具函数 */
  public put<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("put", url, params, config);
  }
  /** 单独抽离的delete工具函数 */
  public delete<T, P>(
    url: string,
    params?: AxiosRequestConfig<T>,
    config?: PureHttpRequestConfig
  ): Promise<P> {
    return this.request<P>("delete", url, params, config);
  }
}

export const http = new PureHttp();

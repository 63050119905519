import { http } from "@/utils/http";
import { ReqDiscountCode, ResCommon } from "./types";

export const agentApi = {
  /**
   * 提交折扣码信息 需要后台审核
   */
  postDiscountCode: (data: ReqDiscountCode) => {
    return http.post<any, ResCommon>("discount-code", { data });
  },
  /**获取折扣码信息 */
  getDiscountCode: () => {
    return http.get<any, ResCommon>("discount-code");
  }
};

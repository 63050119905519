import { http } from "@/utils/http";
import {
  ResCommon,
  ReqSysBackground,
  ReqUserList,
  ReqAddSubScore,
  ReqUpdateModel,
  ReqUpdateDiscountCode,
  ReqCreateOrUpdateAgent,
  ReqCreateDigitalHuman,
  stateData,
  ResStateData,
  agentRateData,
  agentRateType,
  reviewType,
  SendEmailForm,
  ResagentList,
  AdminUpdateAllAgentDigitalTypeForm,
  AdminUpdateAgentSysConfigForm,
  createCode,
  addUserpermissionsListType,
  createPermissionsType,
  addUserpermissionsType,
  creatRoleType,
  bindtUserRoleType,
  listUserPermissionsType,
  AdminBatchUpdateAgentWebType,
  newsToAll,
  createPlatformDigitalHumanData,
  AdminCreateScoreRechargeType,
  AdminUpdateScoreRecharType,
  addOrSubScoreData,
  getScoreListType,
  productDetailForm,
  getProductDetailType,
  changeScoreData,
  wxMnueType,
  updateOrAddWxQa,
  ReqAddSubDigitalAccount,
  getAudioCloneListType,
  rertainAudioType,
  scoreRechargeToAll,
  ReqUpdateDigitalHuman
} from "@/api/types";
import { UserTypeEnum } from "@/config";

export const adminApi = {
  /**
   * 获取用户列表信息
   */
  postUserList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>("admin/list/users", { data });
  },
  /**
   * 获取创建视频详情列表
   */
  postCreateVideoDetailList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>("admin/list/create/video/detail", {
      data
    });
  },
  /**
   * 增加数量
   */
  postAddAmount: (data?: object) => {
    return http.post<any, ResCommon>("admin/add/amount", {
      data
    });
  },
  /**
   * 减少数量
   */
  postSubAmount: (data?: object) => {
    return http.post<any, ResCommon>("admin/sub/amount", {
      data
    });
  },
  /**
   * 修改用户为api用户和普通用户
   */
  changeUserType: (userId: number, type: UserTypeEnum) => {
    return http.patch<any, ResCommon>(
      `admin/change/user/type/${userId}/${type}`
    );
  },
  /**
   * 增加系统配置
   */
  postSystemConfig: (data: object) => {
    return http.post<any, ResCommon>("system-config", { data });
  },

  /**
   * 获取系统配置
   */
  getSystemConfig: () => {
    return http.get<any, ResCommon>("system-config");
  },
  /**
   * 修改系统配置
   */
  patchSystemConfig: (id: number, value: string) => {
    return http.patch<any, ResCommon>(`system-config/${id}/${value}`);
  },
  /**
   * 获取素材对应的分组id列表
   */
  getBackgroudConfig: (businessType: number) => {
    return http.get<any, ResCommon>(
      `system-config/resource/group/id/${businessType}`
    );
  },

  /**
   * 后台管理系统 news 无缓存
   */
  postNewsList: (data: object) => {
    return http.post<any, ResCommon>("admin-news/list/news", {
      data
    });
  },
  /**
   * 增加新闻
   */
  postCreateOrUodateNews: (data: object) => {
    return http.post<any, ResCommon>("admin-news/create/or/update/news", {
      data
    });
  },
  /**
   *将当前新闻更新到所有经销商   */
  postNewsSynchronizeToAll: (data: newsToAll) => {
    return http.post<any, ResCommon>(`admin-news/update/to/global/news`, {
      data
    });
  },
  /**
   *将当前新闻更新到所有经销商   */
  postScoreRechargeSynchronizeToAll: (data: scoreRechargeToAll) => {
    return http.post<any, ResCommon>(
      `admin-score/update/to/global/score/recharge`,
      {
        data
      }
    );
  },
  /**
   * 上传素材图片
   */
  postBackground: (data?: ReqSysBackground) => {
    return http.post<ReqSysBackground, ResCommon>("admin-sources/background", {
      data
    });
  },
  /**
   * 获取系统素材列表
   */
  getSystemMaterialList: (
    businessType: number,
    groupId: number,
    orientation: number
  ) => {
    return http.get<any, ResCommon>(
      `admin-sources/system/resource/${businessType}/${groupId}/${orientation}`
    );
  },
  /**
   * 获取用户素材列表
   */
  getUserMaterialList: (
    businessType: number,
    userId: number,
    orientation: number
  ) => {
    return http.get<any, ResCommon>(
      `admin-sources/user/resource/${businessType}/${userId}/${orientation}`
    );
  },
  /**
   * 删除系统素材
   * @param id
   * @returns
   */
  deleteMaterial: (id: number) => {
    return http.delete<any, ResCommon>(`admin-sources/resource/${id}`);
  },

  /**
   * 更新视频状态/v1/admin-digital-manager/check/video/status/{taskId}
   */
  updateVideoStatus: (id: number) => {
    return http.get<any, ResCommon>(
      `admin-digital-manager/check/video/status/${id}`
    );
  },
  /**
   * 视频重试
   */
  retryVideo: (id: number) => {
    return http.get<any, ResCommon>(
      `admin-digital-manager/retry/order/video/${id}`
    );
  },
  rebackMicrosoftOrder: (id: number) => {
    return http.patch<any, ResCommon>(`admin/reback/microsoft/order/${id}`);
  },
  postMicrosoftOrderList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>("admin/list/microsoft/order", {
      data
    });
  },
  /**
   * 获取创建视频列表/v1/admin-digital-manager/list/create/video
   */
  postCreateVideoList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>(
      "admin-digital-manager/list/create/video",
      {
        data
      }
    );
  },
  // 获取火山声音克隆列表
  getAudioCloneList: (data: getAudioCloneListType) => {
    return http.post<ReqUserList, ResCommon>("admin-voice-clone/list", {
      data
    });
  },
  // 获取火山声音克隆列表
  updateVoice: (voiceId: string) => {
    return http.patch<any, ResCommon>(
      `admin-voice-clone/update/voice/${voiceId}`
    );
  },
  // 重新生成火山声音克隆
  rertainAudio: (data: rertainAudioType) => {
    return http.post<any, ResCommon>("admin-voice-clone/retry", {
      data
    });
  },
  // 刷新声音状态
  refreshVoiceStatus: (voiceId: string) => {
    return http.patch<any, ResCommon>(
      `admin-voice-clone/refresh/status/${voiceId}`
    );
  },
  /**
   * 获取数字人训练列表
   */
  postTrainingList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>(
      "admin-digital-manager/list/training",
      {
        data
      }
    );
  },
  /**
   * 修改模特公有,私有
   * 1公有 2私有
   */
  patchUpdateModel: (data: ReqUpdateModel) => {
    return http.patch<any, ResCommon>(`admin-digital-manager/patch/model`, {
      data
    });
  },
  /**
   * 更新系统模特
   */
  updateModel: () => {
    return http.post<any, ResCommon>("admin-digital-manager/update/model");
  },
  /**
   * 获取用户积分列表信息
   */
  postUserScoreList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>("admin-score/list/score", {
      data
    });
  },
  /**
   * 获取用户积分详情列表
   */
  postUserScoreDetailList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>("admin-score/list/score/detail", {
      data
    });
  },

  /**
   * 获取数字人账户信息详情
   */
  postDigitalAccountDetailList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>("admin-score/list/score/detail", {
      data
    });
  },

  /**
   * 增加积分
   */
  postAddScore: (data: ReqAddSubScore) => {
    return http.post<any, ResCommon>("admin-score/add/score", {
      data
    });
  },
  /**
   * 减少积分
   */
  postSubScore: (data: ReqAddSubScore) => {
    return http.post<any, ResCommon>("admin-score/sub/score", {
      data
    });
  },
  /**
   * 增加积分
   */
  postAddDigitalAccount: (data: ReqAddSubDigitalAccount) => {
    return http.post<any, ResCommon>("admin/add/digital/account", {
      data
    });
  },
  /**
   * 减少积分
   */
  postSubDigitalAccount: (data: ReqAddSubDigitalAccount) => {
    return http.post<any, ResCommon>("admin/sub/digital/account", {
      data
    });
  },

  /**
   * 获取数字人账户信息
   */
  postDigitalAccountlList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>("admin/list/digital/account", {
      data
    });
  },
  /**
   * 获取数字人账户信息
   */
  postDigitalAccountDetaillList: (data?: ReqUserList) => {
    return http.post<ReqUserList, ResCommon>(
      "admin/list/digital/account/detail",
      {
        data
      }
    );
  },
  /**
   * 获取充值积分类型列表
   */
  getRechargeScoreTypeList: (data: ReqUserList) => {
    return http.post<any, ResCommon>("admin-score/list/score/recharge", {
      data
    });
  },

  /**
   * 获取合伙人的充值积分类型列表
   */
  getRechargeScoreTypeListDealar: (domain: string) => {
    return http.get<any, ResCommon>(
      `admin-score/list/dealar/score/recharge/${domain}`
    );
  },

  /**
   * 更新积分充值类型
   */
  updateRechargeScoreType: (data: AdminUpdateScoreRecharType) => {
    return http.patch<any, ResCommon>("admin-score/update/score/recharge", {
      data
    });
  },
  /**
   * 创建积分充值类型
   */
  createRechargeScoreType: (data: AdminCreateScoreRechargeType) => {
    return http.patch<any, ResCommon>("admin-score/create/score/recharge", {
      data
    });
  },

  /**
   * 根据tag发布广场
   */
  postEnterprisePublish: (tag: string, desc: string, type: number) => {
    return http.post<any, ResCommon>(
      `admin-draw/create/square/from/tag/${tag}/${desc}/${type}`
    );
  },
  /**
   * 在线支付补单   */
  updateOnlinePayStatus: (orderId: number) => {
    return http.patch<any, ResCommon>(
      `admin-pay/on/line/pay/additional/${orderId}`
    );
  },
  /**
   * 在线支付列表   */
  postOnlinePayList: (data: ReqUserList) => {
    return http.post<any, ResCommon>("admin-pay/on/line/pay/list", { data });
  },
  /**
   * 获取代理商列表*/
  getAgentList: (data?: ResagentList) => {
    return http.post<any, ResCommon>("admin-agent/list/agent", { data });
  },
  /**
   * 创建或更新
   */
  createOrUpdateAgent: (data: ReqCreateOrUpdateAgent) => {
    return http.post<any, ResCommon>("admin-agent/create/or/update/agent", {
      data
    });
  },
  /**
   * 更新折扣码
   */
  updateAgentSysConfig: (data: AdminUpdateAgentSysConfigForm) => {
    return http.post<any, ResCommon>("admin-agent/update/agent/sys/config", {
      data
    });
  },
  /**
   * 一次性更新所有经销商的类型
   */
  updateAllAgentDigitalType: (data: AdminUpdateAllAgentDigitalTypeForm) => {
    return http.post<any, ResCommon>(
      "admin-agent/update/all/agent/digital/type",
      {
        data
      }
    );
  },
  /**
   * 一次性更新所有经销商的信息,按照topkey 和subKey
   */
  batchUpdateAllAgentKey: (data: AdminBatchUpdateAgentWebType) => {
    return http.post<any, ResCommon>("admin-agent/batch/update/all/agent/key", {
      data
    });
  },
  /**
   * 更新代理商状态 */
  updateAgent: (id: number, status: number) => {
    return http.patch<any, ResCommon>(
      `admin-agent/update/agent/status/${id}/${status}`
    );
  },
  /**
   * 更新折扣码
   */
  updateDiscountCode: (data: ReqUpdateDiscountCode) => {
    return http.post<any, ResCommon>("admin-agent/update/discount/code", {
      data
    });
  },
  /**
   * 创建折扣码
   */
  createCode: (data: createCode) => {
    return http.post<any, ResCommon>("admin-agent/create/discount/code", {
      data
    });
  },
  /**
   * 获取折扣码申请列表
   */
  postDiscountCodeList: (data: object) => {
    return http.post<any, ResCommon>("admin-agent/list/discount/code", {
      data
    });
  },
  //获取单个经销商或者折扣码用户返佣比例
  getAgentRate: (data: agentRateType) => {
    return http.post<any, ResCommon>("admin-agent/agent/rate", { data });
  },
  // 获取产品详情列表
  getProductDetailList: (data: getProductDetailType) => {
    return http.post<any, ResCommon>("admin-agent-score/list/product/detail", {
      data
    });
  },
  // 获取产品详情列表
  getProductDetailById: (id: number) => {
    return http.get<any, ResCommon>(
      `admin-agent-score/product/detail/by/${id}`
    );
  },
  // 更新产品详情
  editScoreDetailList: (data: productDetailForm) => {
    return http.post<any, ResCommon>(
      "admin-agent-score/update/product/detail",
      {
        data
      }
    );
  },
  // 创建产品详情
  addScoreDetailList: (data: productDetailForm) => {
    return http.post<any, ResCommon>(
      "admin-agent-score/create/product/detail",
      {
        data
      }
    );
  },
  // 账户详情
  getScoreDetailList: (data: getScoreListType) => {
    return http.post<any, ResCommon>("admin-agent-score/list/score/detail", {
      data
    });
  },
  //分页获取当前系统的所有用户账号
  getAgentScore: () => {
    return http.get<any, ResCommon>("admin-agent-score");
  },
  //分页获取当前系统的所有代理商的账号
  getScoreList: (data: getScoreListType) => {
    return http.post<any, ResCommon>("admin-agent-score/list/score", {
      data
    });
  },
  //后台扣除积分
  subScore: (data: addOrSubScoreData) => {
    return http.post<any, ResCommon>("admin-agent-score/sub/score", {
      data
    });
  },
  //后台扣除积分
  changeScore: (data: changeScoreData) => {
    return http.post<any, ResCommon>("admin-agent-score/change/score", {
      data
    });
  },
  addScore: (data: addOrSubScoreData) => {
    return http.post<any, ResCommon>("admin-agent-score/add/score", {
      data
    });
  },
  //给代理商或者折扣码配置返佣比例
  setAgentRate: (data: agentRateData) => {
    return http.post<any, ResStateData>("admin-agent/create/agent/rate", {
      data
    });
  },
  /**获取当前系统所有数字人相关订单新
   * 后台这个接口需要给经销商也可以使用./admin-digital-order/list/digital/human/order
   */
  digitalOrderList: (data: ReqUserList) => {
    return http.post<any, ResCommon>(
      "admin-digital-order/list/digital/human/order",
      {
        data
      }
    );
  },
  // 新增
  /**后台手动给用户创建平台小冰数字人账号*/
  createPlantformDigitalHuman: (data: createPlatformDigitalHumanData) => {
    return http.post<any, ResCommon>(
      `admin-digital-order/create/platform/digital/human`,
      {
        data
      }
    );
  },
  // 更新数字人状态
  changeState: (data: stateData) => {
    return http.post<any, ResStateData>(
      "admin-digital-order/update/digital/human/state",
      {
        data
      }
    );
  },
  /**
   * 查看维修记录或者跟进记录
   */
  lookupReview: (data: reviewType) => {
    return http.post<any, ResCommon>(
      "admin-digital-order/create/digital/review",
      { data }
    );
  },
  /**获取当前系统所有数字人相关订单新
   * 后台这个接口需要给经销商也可以使用.
   */
  sendEmail: (data: SendEmailForm) => {
    return http.post<any, ResCommon>("admin-digital-order/send/email", {
      data
    });
  },
  // 获取企业数字人订单
  getgitalCompanyOrderList: (data: ReqUserList) => {
    return http.post<any, ResCommon>(
      "admin-digital-order/list/xb/digital/human/order",
      {
        data
      }
    );
  },
  //更新企业数字人订单是否开通
  activeCompany: (id: number, status: number) => {
    return http.post<any, ResCommon>(
      `admin-digital-order/update/xb/good/open/${id}/${status}`
    );
  },
  /**
   * 查看是否激活
   */
  lookupIsActivity: (id: number) => {
    return http.post<any, ResCommon>(
      `admin-digital-order/check/xb/is/activity/${id}`
    );
  },
  // 新增
  /**后台创建小冰数字人 */
  createDigitalHuman: (data: ReqCreateDigitalHuman) => {
    return http.post<any, ResCommon>(
      "admin-digital-order/create/digital/human",
      { data }
    );
  },
  /**编辑数字人 */
  updateDigitalHuman: (data: ReqUpdateDigitalHuman) => {
    return http.post<any, ResCommon>(
      "admin-digital-manager/update/video/info",
      { data }
    );
  },
  /**
   *
   *获取菜单和按钮的权限列表
   */
  getPermissionsList: () => {
    return http.get<any, ResCommon>("admin-permissions/list/permissions");
  },
  /**
   *
   *新增或者更新菜单和按钮的权限列表
   */
  addOrEditpermissionsList: (data: addUserpermissionsType) => {
    return http.post<any, ResCommon>(
      "admin-permissions/create/or/update/permissions",
      {
        data
      }
    );
  },
  /**
   *
   *新增或者更新菜单和按钮的权限列表
   */
  delPermission: (id: number) => {
    return http.post<any, ResCommon>(`admin-permissions/del/permission/${id}`);
  },
  /**
   * 获取用户的权限
   *
   */
  getUserpermissionsList: (data: listUserPermissionsType) => {
    return http.post<any, ResCommon>(
      `admin-permissions/list/user/permissions`,
      {
        data
      }
    );
  },
  // /**
  //  * 获取用户的权限
  //  *
  //  */
  // getUserRole: (userId: number) => {
  //   return http.get<any, ResCommon>(`admin-permissions/user/role/${userId}`);
  // },
  /**
   * 获取用户的权限
   *
   */
  getUserRolesList: () => {
    return http.get<any, ResCommon>(`admin-permissions/list/user/role`);
  },
  /**
   * 给用户增加菜单和按钮权限
   *
   */
  addUserpermissionsList: (data: addUserpermissionsListType) => {
    return http.post<any, ResCommon>("admin-permissions/add/user/permissions", {
      data
    });
  },
  /**
   * 获取角色列表
   *
   */
  getRoleList: () => {
    return http.get<any, ResCommon>("admin-permissions/list/role");
  },
  /**
   * 新建新的用户角色
   *
   */
  creatUserRole: (data: creatRoleType) => {
    return http.post<any, ResCommon>("admin-permissions/create/role", { data });
  },
  /**
   * 绑定用户角色
   *
   */
  bindtUserRole: (data: bindtUserRoleType) => {
    return http.post<any, ResCommon>("admin-permissions/bind/user/role", {
      data
    });
  },
  /**
   *
   *获取菜单和按钮的权限列表
   */
  getRolepermissionsList: () => {
    return http.get<any, ResCommon>("admin-permissions/list/role/permissions");
  },
  /**
   * 创建用户角色
   *
   */
  createRolePermissions: (data: createPermissionsType) => {
    return http.post<any, ResCommon>(
      "admin-permissions/create/role/permissions",
      {
        data
      }
    );
  },
  /**
   *
   *获取微信公众号菜单，
   */
  getWxMenuList: () => {
    return http.get<any, ResCommon>("admin-wx/get/menu");
  },
  /**
   *
   *获取微信资源pic列表，用于用户选择
   */
  getWxSourceList: () => {
    return http.get<any, ResCommon>("admin-wx/source/list");
  },
  /**
   *
   *微信创建菜单
   */
  wxCreatemMnue: (data: wxMnueType) => {
    return http.post<any, ResCommon>("admin-wx/create/menu", { data });
  },
  /**
   *
   *删除微信菜单
   */
  wxDelMenuByName: (name: string) => {
    return http.get<any, ResCommon>(`admin-wx/delete/menu/${name}`);
  },
  /**
   *
   *提交公众号菜单
   */
  wxUploadSource: (data: any) => {
    return http.post<any, ResCommon>("admin-wx/upload/source", { data });
  },
  /**
   *
   *获取公众号回复号qa
   */
  getWxQa: () => {
    return http.get<any, ResCommon>("admin-wx/get/qa");
  },
  /**
   *
   *删除微信公众号回复菜单
   */
  deleteWxQa: (data: updateOrAddWxQa) => {
    return http.post<any, ResCommon>("admin-wx/delete/qa", { data });
  },
  /**
   *
   *新增微信公众号回复
   */
  createWxQa: (data: updateOrAddWxQa) => {
    return http.post<any, ResCommon>("admin-wx/create/qa", { data });
  },
  /**
   *
   *更新信公众号回复
   */
  updateWxQa: (data: updateOrAddWxQa) => {
    return http.post<any, ResCommon>("admin-wx/update/qa", { data });
  }
};

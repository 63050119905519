import { LocalStorageKeyEnum } from "@/config";
import { CommonUtils } from "./commonUtils";

export const LocalStrogeUtils = {
  // 点击不同模型进行跳转
  set(key: LocalStorageKeyEnum, value, needToJson = true) {
    localStorage.setItem(key, needToJson ? CommonUtils.getJSON(value) : value);
  },
  // 点击不同模型进行跳转
  get(key: LocalStorageKeyEnum, needToObj = true) {
    const value = localStorage.getItem(key);
    if (value) {
      return needToObj ? CommonUtils.getJSONObj(value) : value;
    }
    return null;
  },
  // 点击不同模型进行跳转
  remove(key: LocalStorageKeyEnum) {
    localStorage.removeItem(key);
  }
};

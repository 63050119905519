import { reactive } from "vue";
import { isAllEmpty, isEmail } from "@pureadmin/utils";
import type { FormRules } from "element-plus";
import { $t, transformI18n } from "@/plugins/i18n";

/** 6位数字验证码正则 */
export const REGEXP_SIX = /^\d{6}$/;

/** 密码正则（密码格式应为6-18位数字、字母、符号的任意两种组合） */
export const REGEXP_PWD =
  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[()])+$)(?!^.*[\u4E00-\u9FA5].*$)([^(0-9a-zA-Z)]|[()]|[a-z]|[A-Z]|[0-9]){6,18}$/;
/** 邮箱校验 */
export const REGEXP_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/**手机号校验 */
export const REGEXP_PHONE = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
/**邮箱或手机号校验，用于微信登陆后的弹窗 */
export const REGEXP_EMAIL_OR_PHONE =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^(?:(?:\+|00)86)?1[3-9]\d{9}$/;

export const REGEXP_REALNAME = /^(?:[\u4e00-\u9fa5·]{2,16})$/;
/**身份证号校验 */
export const REGEXP_IDCARD =
  /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;

/**国际手机号校验 */
export const REGEXP_I18N_PHONE = /^\+?(\d[\d- ]+)?(\([\d- ]+\))?[\d- ]+\d$/;
/**二级域名校验 */
export const REGEXP_KEY = /^[a-zA-Z0-9]{2,10}$/;
// 电话号码正则
export const PhoneRegexMapArr = {
  "86": "^(\\+?0?86\\-?)?1[345789]\\d{9}$", // 中国
  "886": "^(\\+?886\\-?|0)?9\\d{8}$", // 中国台湾
  "852": "^(\\+?852\\-?)?[569]\\d{3}\\-?\\d{4}$", // 中国香港
  "853": "^(853)?6d{7}$", // 中国澳门
  "1": "^(\\+?1)?[-]?\\(?(\\d{3})\\)?[-]?(\\d{3})[-]?(\\d{4})$", // 美国
  "44": "^(\\+?44)?7\\d{9}$", // 英国
  "49": "^(\\+?49[\\.\\-])?([\\(]{1}[0-9]{1,6}[\\)])?([0-9\\.\\-\\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$", // 德国
  "60": "^(\\+?6?01)?(([145]{1}(\\-|\\s)?\\d{7,8})|([236789]{1}(\\s|\\-)?\\d{7}))$", // 马来西亚
  "61": "^(\\+?61|0)?4\\d{8}$", // 澳大利亚
  "64": "^(\\+?64)?2\\d{7,9}$", // 新西兰
  "65": "^(\\+?0?65\\-?)?\\d{10}$", // 新加坡
  "66": "^(\\+?0?66\\-?)?\\d{10}$", // 泰国
  "81": "^(\\+?81)?\\d{1,4}[\\-]?\\d{1,4}[\\-]?\\d{4}$", // 日本
  "82": "^(\\+?82)?01[016789](\\d{8})$", // 韩国
  "91": "^(\\+?91)?[789]\\d{9}$", // 印度
  "855": "^(\\+?855)?\\d{6,9}$" // 柬埔寨
};
// URL 验证规则
const validateURL = (rule, value, callback) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // 协议
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // 域名
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // IP 地址
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // 端口和路径
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // 查询字符串
      "(\\#[-a-z\\d_]*)?$",
    "i" // 锚点
  );
  if (!value) {
    return callback(new Error("请填写URL地址"));
  } else if (!urlPattern.test(value)) {
    return callback(new Error("请输入有效的URL地址"));
  } else {
    callback();
  }
};
const usernamePhoneRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.inputEmailOrPhoneReg"))));
      } else if (checkPhone(value)) {
        callback(new Error(transformI18n($t("login.unsupportedCountry"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
// 检查手机号是否符合国家代码
const checkPhone = (content: string, code?: string): boolean => {
  const regexPattern = PhoneRegexMapArr[code];
  if (!regexPattern) {
    console.error("未找到对应国家代码的正则表达式");
    return false;
  }
  const regex = new RegExp(regexPattern);
  return regex.test(content);
};
const usernameRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.usernameReg"))));
      } else if (!checkPhone(value) && !isEmail(value)) {
        callback(new Error(transformI18n($t("login.emailOrPhoneReg"))));
      } else if (("" + value).includes("_")) {
        callback(new Error(transformI18n($t("login.emailOrPhoneReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const payAccountRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.enterPaymentAccount"))));
      } else if (!REGEXP_EMAIL.test(value) && !REGEXP_PHONE.test(value)) {
        callback(
          new Error(transformI18n($t("login.enterValidPaymentAccount")))
        );
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const payBankCardAccountRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.enterBankAccount"))));
      } else if (!/^\d{16,19}$/.test(value)) {
        callback(new Error(transformI18n($t("login.enterValidBankAccount"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const payBankPlaceRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.enterBankBranchLocation"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
export const digitalCertificateRule = {
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" }
  ],
  rightname: [{ required: true, message: "请输入作品名称", trigger: "blur" }],
  righttype: [{ required: true, message: "请输入作品类别", trigger: "blur" }],
  donedate: [{ required: true, message: "请输入完成日期", trigger: "blur" }],
  realname: [{ required: true, message: "请输入作者姓名", trigger: "blur" }],
  doneaddress: [
    { required: true, message: "请选择完成地点", trigger: "change" }
  ],
  identity: [
    { required: true, message: "请输入身份证号", trigger: "blur" },
    {
      pattern:
        /^[1-9]\d{5}(18|19|20)?\d{2}((0[1-9])|(1[0-2]))(([0|1|2][0-9])|10|20|30|31)\d{3}([0-9Xx])$/,
      message: "身份证号格式不正确",
      trigger: "blur"
    }
  ],
  idcardfront: [
    { required: true, message: "请上传身份证正面照", trigger: "change" }
  ],
  idcardback: [
    { required: true, message: "请上传身份证反面照", trigger: "change" }
  ],
  authordesc: [{ required: true, message: "请输入作者简介", trigger: "blur" }],
  centralContent: [
    { required: true, message: "请输入作品中心思想", trigger: "blur" }
  ],
  workFeatures: [
    { required: true, message: "请输入作品特色", trigger: "blur" }
  ],
  creativeIntention: [
    { required: true, message: "请输入创作意图", trigger: "blur" }
  ],
  creativeMode: [
    { required: true, message: "请输入创作方式", trigger: "blur" }
  ],
  creativeProcess: [
    { required: true, message: "请输入创作过程", trigger: "blur" }
  ],
  files: [{ required: true, message: "请上传作品文件", trigger: "change" }]
};
const newsDialogRule = {
  group: [
    {
      required: true,
      message: "分组不可为空"
    }
  ],
  title: [
    {
      required: true,
      message: "标题不可为空"
    }
  ],

  status: [
    {
      required: true,
      message: "是否展示不可为空"
    }
  ],
  sort: [
    {
      required: true,
      message: "是否置顶不可为空"
    }
  ],
  agentId: [
    {
      required: true,
      message: "网站名称不可为空"
    }
  ],
  type: [
    {
      required: true,
      message: "类型不可为空"
    }
  ],
  imageUrl: [
    {
      required: true,
      message: "电脑图片不可为空"
    }
  ]
};

const passwordRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.passwordReg"))));
      } else if (!REGEXP_PWD.test(value)) {
        callback(new Error(transformI18n($t("login.passwordRuleReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const realNameRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.enterChName"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];

const verifyCodeRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.verifyCodeReg"))));
      } else if (!REGEXP_SIX.test(value)) {
        callback(new Error(transformI18n($t("login.verifyCodeSixReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const verifyIDCardRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.verifyIDCardReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const verifyNameRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.verifyNameReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const verifyidCardRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.verifyIDCardReg"))));
      } else if (!REGEXP_IDCARD.test(value)) {
        callback(new Error(transformI18n($t("login.verifyIDCardCorrectReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const verifyRequestRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.pleaseRequest"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];

const websiteNameRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.verifyWebsiteNameReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const keyRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.verifyKeyReg"))));
      } else if (!REGEXP_KEY.test(value)) {
        callback(new Error(transformI18n($t("login.verifyKeyLengthReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];

// const phoneRule = [
//   {
//     validator: (rule, value, callback) => {
//       if (isAllEmpty(value)) {
//         callback(new Error(transformI18n($t("login.phoneReg"))));
//       } else if (!isPhone(value)) {
//         callback(new Error(transformI18n($t("login.phoneCorrectReg"))));
//       } else {
//         callback();
//       }
//     },
//     trigger: "blur"
//   }
// ];

const i18nPhoneRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.phoneReg"))));
      } else if (!REGEXP_I18N_PHONE.test(value)) {
        callback(new Error(transformI18n($t("login.phoneCorrectReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];
const emailRule = [
  {
    validator: (rule, value, callback) => {
      if (isAllEmpty(value)) {
        callback(new Error(transformI18n($t("login.emailReg"))));
      } else if (!isEmail(value)) {
        callback(new Error(transformI18n($t("login.emailCorrectReg"))));
      } else {
        callback();
      }
    },
    trigger: "blur"
  }
];

/** 登录校验 */
const loginRules = reactive(<FormRules>{
  // username: usernameRule,
  password: passwordRule
});

/** 注册校验 */
const registRules = reactive(<FormRules>{
  password: passwordRule,
  verifyCode: verifyCodeRule
});

/** 手机和邮箱登录校验 */
const verifyCodeLoginRules = reactive(<FormRules>{
  // username: usernameRule,
  verifyCode: verifyCodeRule
});

/** 忘记密码校验 */
const forgetRules = reactive(<FormRules>{
  // username: usernameRule,
  verifyCode: verifyCodeRule,
  password: passwordRule
});

/** 设置支付密码校验 */
const setPayPasswordRules = reactive(<FormRules>{
  realName: realNameRule,
  payAccount: payAccountRule,
  payPassword: passwordRule,
  payBankCardAccount: payBankCardAccountRule,
  payBankPlace: payBankPlaceRule,
  verifyCode: verifyCodeRule
});

/**小冰用户信息校验 */
const trainingRegisterRules = reactive(<FormRules>{
  contactName: verifyNameRule,
  contactMail: emailRule,
  adminNumber: i18nPhoneRule,
  idCard: verifyidCardRule
});
/**折扣码 申请信息 */
const discountCodeRemarkRules = reactive(<FormRules>{
  name: verifyNameRule,
  contactMail: emailRule,
  adminNumber: i18nPhoneRule,
  yourRequest: verifyRequestRule
});
/**系统配置规则 */
const systemConfigRules = reactive<FormRules>({
  appName: [
    {
      required: true,
      message: "请输入网站名称",
      trigger: "blur"
    }
  ],
  slogan: [
    {
      required: true,
      message: "请输入网站广告语",
      trigger: "blur"
    }
  ],
  textColor: [
    {
      required: true,
      message: "请选择首页文本颜色",
      trigger: "blur"
    }
  ],
  bgColor: [
    {
      required: true,
      message: "请选择首页背景颜色",
      trigger: "blur"
    }
  ],
  theme: [
    {
      required: true,
      message: "请选择主题颜色",
      trigger: "blur"
    }
  ],
  firstLoginType: [
    {
      required: true,
      message: "请选择首次默认登陆方式",
      trigger: "blur"
    }
  ],
  logoUrl: [
    {
      required: true,
      message: "请上传品牌图片",
      trigger: "blur"
    }
  ],
  loginUrl: [
    {
      required: true,
      message: "请上传登录页图片",
      trigger: "blur"
    }
  ],
  serviceQrUrl: [
    {
      required: true,
      message: "请上传客服二维码",
      trigger: "blur"
    }
  ],
  childDomain: [
    {
      required: true,
      message: "二级域名key不能为空",
      trigger: "blur"
    }
  ],
  digits: [
    {
      validator: (rule, value, callback) => {
        if (value && value.length === 0) {
          callback(new Error("至少选择一个数字人产品"));
        } else {
          callback();
        }
      },
      trigger: "blur",
      required: true
    }
  ],
  notifyEmail: [
    {
      required: true,
      message: "请填写数字人购买通知邮件",
      trigger: "blur"
    }
  ],
  homePages: [
    {
      validator: (rule, value, callback) => {
        if (value && value.length === 0) {
          callback(new Error("至少选择一个主页面菜单"));
        } else {
          callback();
        }
      },
      trigger: "blur",
      required: true
    }
  ],
  // smallBanner: [
  //   {
  //     validator: (rule, value, callback) => {
  //       if (value && value.length === 0) {
  //         callback(new Error("至少选择一个banner卡片"));
  //       } else {
  //         callback();
  //       }
  //     },
  //     trigger: "blur",
  //     required: true
  //   }
  // ],
  swiperSorting: [
    {
      validator: (rule, value, callback) => {
        if (value && value.length === 0) {
          callback(new Error("至少选择一个首页展示内容"));
        } else {
          callback();
        }
      },
      trigger: "blur",
      required: true
    }
  ],
  payChannel: [
    {
      validator: (rule, value, callback) => {
        if (value && value.length === 0) {
          callback(new Error("至少选择一个支付渠道"));
        } else {
          callback();
        }
      },
      trigger: "blur",
      required: true
    }
  ]
});
/** 首页配置规则 */
const firstPageRules = reactive<FormRules>({
  logoUrl: [
    {
      required: true,
      message: "请上传logoUrl",
      trigger: "blur"
    }
  ],
  loginUrl: [
    {
      required: true,
      message: "请上传登录图片",
      trigger: "blur"
    }
  ],
  serviceQrUrl: [
    {
      required: true,
      message: "请上传商务客服二维码",
      trigger: "blur"
    }
  ],
  digitalServiceQrUrl: [
    {
      required: true,
      message: "请上传数字人客户二维码",
      trigger: "blur"
    }
  ],
  gzQrUrl: [
    {
      required: true,
      message: "请上传微信公众号二维码",
      trigger: "blur"
    }
  ]
});

export {
  validateURL,
  passwordRule,
  loginRules,
  registRules,
  verifyCodeLoginRules,
  forgetRules,
  setPayPasswordRules,
  trainingRegisterRules,
  discountCodeRemarkRules,
  systemConfigRules,
  firstPageRules,
  emailRule,
  newsDialogRule
};
